import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import { mdiRefresh } from '@mdi/js';

// Components
import { NothingHere } from '../common/NothingHere';
import { MeetingListItem } from './MeetingListItem';

// Lib
import { Meeting } from '../../lib/types';
import { useMeetingActions } from './useMeetingActions';
import { useAppSelector } from '../../store';
import { selectMeetings } from '../../store/meetings';
import { ToolbarCaption } from '../common/ToolbarCaption';
import { Button } from '@mui/material';

export const MeetingList = () => {
	const history = useAppSelector(selectMeetings);

	const {
		selectMeeting,
		selectedMeetingId,
		toggleMeetingFavorite,
		refreshMeetings,
		loadMoreMeetings,
	} = useMeetingActions();

	const handleMeetingClick = (meeting: Meeting) => {
		selectMeeting(meeting);
	};

	const handleMeetingFavoriteClick = (meeting: Meeting) => {
		toggleMeetingFavorite(meeting);
	};

	// useEffect(() => {
	// 	const interval = setInterval(refreshMeetings, 1000 * 60);
	// 	return () => clearInterval(interval);
	// }, [refreshMeetings]);

	return (
		<Box
			sx={{
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: '100%',
				overflowY: 'auto',
				backgroundColor: '#ffffff',
			}}
		>
			{history.length === 0 && (
				<Toolbar
					variant="dense"
					style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
				>
					<ToolbarCaption>Meeting History</ToolbarCaption>
				</Toolbar>
			)}

			{history.length > 0 && (
				<>
					<Toolbar
						variant="dense"
						style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
					>
						<ToolbarCaption>Meeting History</ToolbarCaption>

						<Tooltip title="Refresh Meeting List">
							<IconButton
								size="small"
								aria-label="Refresh Meeting List"
								onClick={refreshMeetings}
							>
								<Icon path={mdiRefresh} size={1} />
							</IconButton>
						</Tooltip>
					</Toolbar>
					<List dense>
						{history.map((item) => (
							<MeetingListItem
								key={`item_${item.id}`}
								meeting={item}
								selected={selectedMeetingId === item.id}
								onClick={handleMeetingClick}
								onFavoriteClick={handleMeetingFavoriteClick}
							/>
						))}
					</List>
				</>
			)}
			{history.length > 0 && (
				<Box sx={{ textAlign: 'center', mb: 4 }}>
					<Button onClick={loadMoreMeetings}>Load More</Button>
				</Box>
			)}
			{history.length === 0 && (
				<NothingHere text="You have no meeting history" />
			)}
		</Box>
	);
};
