import { ApiClient, ApiRetryClient, ApiClientResponseError } from './client';

import CommonApi from './common';
import UserApi from './user';

const client = new ApiRetryClient(new ApiClient('api', 'api'));

export const api = {
	ApiClientResponseError,
	common: new CommonApi(client),
	user: new UserApi(client),
};
