import * as React from 'react';

// Lib
import { AppStateConfig, ConfigSetting, defaultAppConfig } from '../lib/types';
import { parseJitsiUrl } from '../lib/utils';

export interface ApplicationConfigurationContextValue {
	appConfig: AppStateConfig;
	isMeetingLink: (url: string | null | undefined) => boolean;
}

const initialValue: ApplicationConfigurationContextValue = {
	appConfig: defaultAppConfig,
	isMeetingLink: () => false,
};

export const ApplicationConfigurationContext =
	React.createContext<ApplicationConfigurationContextValue>(initialValue);

export interface Props {
	children: React.ReactNode;
}

export const ApplicationConfigurationContextProvider = ({
	children,
}: Props) => {
	const isMeetingLink = React.useCallback(
		(url: string | null | undefined) => {
			if (!url || !url.includes('://')) {
				return false;
			}
			const hasMeetingServer = defaultAppConfig.meetingServers.some(
				(x: ConfigSetting) => url.includes(x.value)
			);
			if (hasMeetingServer) {
				try {
					const info = parseJitsiUrl(url);
					return !!(info.server && info.room);
				} catch (error) {
					return false;
				}
			}
			return hasMeetingServer;
		},
		[]
	);

	const value: ApplicationConfigurationContextValue = {
		appConfig: defaultAppConfig,
		isMeetingLink,
	};

	return (
		<ApplicationConfigurationContext.Provider value={value}>
			{children}
		</ApplicationConfigurationContext.Provider>
	);
};

export const useApplicationConfigurationContext =
	(): ApplicationConfigurationContextValue => {
		return React.useContext(ApplicationConfigurationContext);
	};
