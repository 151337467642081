import * as React from 'react';

import { AgendaListView } from './AgendaListView';
import { AgendaGridView } from './AgendaGridView';
import {
	selectCalendarView,
	selectPersonalMeeting,
	useAppSelector,
} from '../../store';
import { useCalendarContext } from '../../contexts/CalendarContext';

export const DayView = () => {
	const [selectedTime, setSelectedTime] = React.useState<string>();
	const personalMeeting = useAppSelector(selectPersonalMeeting);
	const calendarView = useAppSelector(selectCalendarView);
	const { isGoogleConnected, isMicrosoftConnected } = useCalendarContext();
	const hasCalendarsConnected = isGoogleConnected || isMicrosoftConnected;

	if (!hasCalendarsConnected || !personalMeeting) {
		return null;
	}

	const handleNewEvent = (time: string) => {
		if (time === selectedTime) {
			setSelectedTime(undefined);
		} else {
			setSelectedTime(time);
			// setOpen(true);
		}
	};

	return (
		<>
			<AgendaGridView
				show={calendarView === 'Grid'}
				onNewEvent={handleNewEvent}
				selectedTime={selectedTime}
			/>
			<AgendaListView
				show={calendarView === 'List'}
				onNewEvent={() => {}}
			/>
		</>
	);
};
