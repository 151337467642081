import { Meeting } from './types';
import { parseJitsiUrl, toDateTimeString, getUTCDateNow } from './utils';
import { api } from '../api';

export const createMeetingInfo = async (
	authId: string,
	videoServer: string,
	urlOrRoom: string
): Promise<Meeting> => {
	const { server, room } = parseJitsiUrl(urlOrRoom);
	const pin = await api.user.getMeetingRoomPin(authId, room);
	const meeting: Meeting = {
		id: -1,
		topic: '',
		type: 'Personal',
		dt: toDateTimeString(getUTCDateNow()) || '',
		room,
		pin: pin || '',
		favorite: false,
		url: `https://${server || videoServer}/${room}`,
		server: server || videoServer,
	};
	return meeting;
};
