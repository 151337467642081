/* eslint-disable no-console */

// Lib
import { DateTime } from '../DateTime';
import { CalendarEvent, CalendarClass, EventMapper } from '../types';
import { MicrosoftAuthProvider } from './MicrosoftAuthProvider';

const GRAPH_ENDPOINT_HOST = 'https://graph.microsoft.com/';
const GRAPH_CALENDARVIEW_ENDPOINT = 'v1.0/me/calendar/calendarView';

async function callEndpointWithToken(endpoint: string, accessToken: string) {
	const options = {
		useElectronNet: false,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	};
	const response = await fetch(endpoint, options);
	const data = await response.json();
	return data;
}

// const getToday = () => {
// 	const now = new DateTime().toDate();
// 	const startOfDay = now.startOf('day');
// 	const endOfDay = now.endOf('day');
// 	return [startOfDay.toISO(), endOfDay.toISO()];
// };

export default class MicrosoftCalendar implements CalendarClass {
	private authProvider: MicrosoftAuthProvider;

	private mapper: EventMapper;

	constructor(name: string, mapper: EventMapper) {
		this.authProvider = new MicrosoftAuthProvider(name);
		this.mapper = mapper;

		this.isConnected = this.isConnected.bind(this);
		this.connect = this.connect.bind(this);
		this.disconnect = this.disconnect.bind(this);
		this.getEvents = this.getEvents.bind(this);
	}

	async connect(): Promise<boolean> {
		try {
			return !!this.authProvider.login();
		} catch (error) {
			return false;
		}
	}

	async disconnect(): Promise<boolean> {
		this.authProvider.logout();
		return true;
	}

	async isConnected(): Promise<boolean> {
		const account = this.authProvider.getAccount();
		return !!account;
	}

	async getEvents(start: Date, end: Date): Promise<CalendarEvent[]> {
		try {
			const connected = await this.isConnected();
			if (connected) {
				const timeMin = new DateTime(start)
					.toStartOfDay()
					.toISOString();
				const timeMax = new DateTime(end).toEndOfDay().toISOString();

				// console.log('microsoft.getEvents', timeMin, timeMax);
				// /me/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
				// 2020-01-01T19:00:00-08:00
				const filter = `?startDateTime=${timeMin}&endDateTime=${timeMax}&$orderby=start/dateTime&$top=250`;

				const token = await this.authProvider.getToken();
				if (token) {
					const graphResponse = await callEndpointWithToken(
						`${GRAPH_ENDPOINT_HOST}${GRAPH_CALENDARVIEW_ENDPOINT}${filter}`,
						token
					);
					const events =
						graphResponse.value && graphResponse.value.length > 0
							? graphResponse.value.map(this.mapper.map)
							: [];
					return events;
				}
			}
			return [];
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	// async getTodaysEvents(): Promise<CalendarEvent[]> {
	// 	try {
	// 		const connected = await this.isConnected();
	// 		if (connected) {
	// 			const [timeMin, timeMax] = getToday();

	// 			// /me/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
	// 			// 2020-01-01T19:00:00-08:00
	// 			const filter = `?startDateTime=${timeMin}&endDateTime=${timeMax}&$orderby=start/dateTime`;

	// 			const token = await this.authProvider.getToken();
	// 			if (token) {
	// 				const graphResponse = await callEndpointWithToken(
	// 					`${GRAPH_ENDPOINT_HOST}${GRAPH_CALENDARVIEW_ENDPOINT}${filter}`,
	// 					token
	// 				);
	// 				const events =
	// 					graphResponse.value && graphResponse.value.length > 0
	// 						? graphResponse.value.map(this.mapper.map)
	// 						: [];
	// 				return events;
	// 			}
	// 		}
	// 		return [];
	// 	} catch (error) {
	// 		console.error(error);
	// 		return [];
	// 	}
	// }
}
