import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';

export const Time = () => {
	const theme = useTheme();
	const [time, setTime] = useState('');

	useEffect(() => {
		const interval = setInterval(() => {
			const dt = DateTime.now();
			setTime(dt.toLocaleString(DateTime.TIME_SIMPLE));
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box
			sx={{
				position: 'relative',
				textAlign: 'center',
				marginRight: theme.spacing(0),
				fontWeight: 400,
				fontSize: theme.typography.body2.fontSize,
				width: '70px',
			}}
		>
			{time}
		</Box>
	);
};
