import retry, { AbortError } from 'p-retry';
import { ApiClientInterface, ApiClientResponse } from './types';

// https://github.com/tim-kos/node-retry#retrytimeoutsoptions

const abortStatus = [400, 401, 403, 404];

export class ApiRetryClient implements ApiClientInterface {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.failedAttempt = this.failedAttempt.bind(this);
		this.get = this.get.bind(this);
		this.put = this.put.bind(this);
		this.patch = this.patch.bind(this);
		this.post = this.post.bind(this);
		this.del = this.del.bind(this);
	}

	// eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
	private failedAttempt(error: any) {
		// If we throw here; all retries will be aborted and the original promise will reject with the thrown error.
		if (error && error.status && abortStatus.includes(error.status)) {
			throw new AbortError(error);
		}

		if (!error || !error.attemptNumber || !error.retriesLeft) {
			console.warn(error);
		} else {
			console.warn(
				`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
				error
			);
		}
	}

	public async get<TResult>(
		url: string
	): Promise<ApiClientResponse<TResult>> {
		return retry(() => this.client.get(url), {
			retries: 3,
			maxRetryTime: 5000,
			onFailedAttempt: this.failedAttempt,
		});
	}

	public async post<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		return retry(() => this.client.post(url, body), {
			retries: 3,
			maxRetryTime: 10000,
			onFailedAttempt: this.failedAttempt,
		});
	}

	public async put<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		return retry(() => this.client.put(url, body), {
			retries: 3,
			maxRetryTime: 10000,
			onFailedAttempt: this.failedAttempt,
		});
	}

	public async patch<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		return retry(() => this.client.patch(url, body), {
			retries: 3,
			maxRetryTime: 10000,
			onFailedAttempt: this.failedAttempt,
		});
	}

	public async del<TResult, TBody>(
		url: string,
		body?: TBody
	): Promise<ApiClientResponse<TResult>> {
		return retry(() => this.client.del(url, body), {
			retries: 3,
			maxRetryTime: 5000,
			onFailedAttempt: this.failedAttempt,
		});
	}
}
