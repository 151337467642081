import * as React from 'react';
import { useTheme } from '@mui/material';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

type Props = {
	value: string;
	onChange: (value: string) => void;
};

export const Editor = ({ value, onChange }: Props) => {
	const [content, setContent] = React.useState<string>();
	const theme = useTheme();

	React.useEffect(() => {
		if (value && !content) {
			setContent(value);
		}
	}, [value, content]);

	if (!content) return null;

	return (
		<AceEditor
			name="jwt_payload"
			defaultValue={content || ''}
			mode="json"
			theme="tomorrow"
			onChange={onChange}
			fontSize={16}
			showPrintMargin={true}
			showGutter={true}
			highlightActiveLine={true}
			// debounceChangePeriod={700}
			width="100%"
			height="100%"
			style={{
				borderRadius: theme.shape.borderRadius,
				border: `1px solid rgba(0,0,0,0.25)`,
			}}
			setOptions={{
				enableBasicAutocompletion: true,
				enableLiveAutocompletion: true,
				enableSnippets: false,
				showLineNumbers: true,
				tabSize: 4,
			}}
		/>
	);
};
