import { Hub } from 'aws-amplify';

const invalidChannels = [
	'core',
	'auth',
	'api',
	'analytics',
	'interactions',
	'pubsub',
	'storage',
	'xr',
	'datastore',
];
export declare type EventEnvelope<T> = {
	channel: string;
	payload: EventPayload<T>;
	source: string;
	patternInfo?: string[];
};

export declare type EventPayload<T> = {
	event: string;
	data?: T;
	message?: string;
};

export type Listener<T> = (data: EventEnvelope<T>) => void;

export class ApplicationEvent {
	private static validateChannel(event: string): void {
		if (invalidChannels.includes(event)) {
			throw new Error(`The channel '${event}' is reserved`);
		}
	}

	public static Send<T>(event: string, data: T) {
		ApplicationEvent.validateChannel(event);
		Hub.dispatch(event, { event, data });
	}

	public static Receive<T>(channel: string, listener: Listener<T>) {
		Hub.listen(channel, listener);
	}
}
