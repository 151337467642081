import * as React from 'react';

import { Colors } from '../../lib/Colors';
import { CalendarEvent } from '../../lib/types';
import { setSelectedEvent, useAppDispatch } from '../../store';

type Props = {
	event: CalendarEvent;
	positionStyle: React.CSSProperties;
};

export const AgendaGridViewItem = ({ event, positionStyle }: Props) => {
	const dispatch = useAppDispatch();
	const handleClick = () => {
		dispatch(setSelectedEvent(event.id));
	};

	const style: React.CSSProperties = {
		position: 'absolute',
		backgroundColor: Colors.blue200,
		borderWidth: 1,
		borderLeftWidth: 5,
		borderLeftColor: Colors.blue800,
		borderRadius: 4,
		cursor: 'pointer',
		...positionStyle,
	};

	return (
		<div
			data-component="grid-view-item"
			style={style}
			onKeyDown={handleClick}
			onClick={handleClick}
			role="button"
			tabIndex={0}
		>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					flexFlow: 'row nowrap',
					width: '100%',
				}}
			>
				<span
					style={{
						color: Colors.black,
						fontSize: 13,
						position: 'relative',
						top: 8,
						left: 8,
					}}
				>
					{event.name}
				</span>
			</div>
		</div>
	);
};
