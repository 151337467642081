import React from 'react';
import { groupBy } from 'lodash';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useUserProfileContext } from '../../contexts/UserProfileContext';

type Link = {
	id: number;
	url: string;
	scheme: string;
	action: string;
};

const prodServers = ['app.accentservices.com', 'portal.voiceonecloud.com'];

const getVideoServer = () => {
	console.log('location', window.location);
	const href = window.location.href;
	const prod = prodServers.find((x) => href.includes(x));
	return prod ? 'meet.accentvoice.com' : 'dev1-meet.accentvoice.com';
};

const createLinks = (room: string): Link[] => {
	const videoServer = getVideoServer();
	// const host = window.location.host;
	return [
		{
			id: 1,
			url: `https://${videoServer}/${room}`,
			scheme: 'https',
			action: 'Join Meeting URL',
		},
		// {
		// 	id: 2,
		// 	url: `https://${videoServer}/${room}`,
		// 	scheme: 'https',
		// 	action: 'Join Meeting (dev1)',
		// },
		// {
		// 	id: 3,
		// 	url: `https://${host}/${room}`,
		// 	scheme: 'https',
		// 	action: 'Join Meeting (beta)',
		// },
		{
			id: 4,
			url: `accentvoice://meetings/start/mymeeting`,
			scheme: 'accentvoice',
			action: 'Start My Meeting',
		},
		{
			id: 5,
			url: `accentvoice://meetings/join/${room}`,
			scheme: 'accentvoice',
			action: 'Join Meeting',
		},
		{
			id: 6,
			url: `accentvoice://${videoServer}/${room}`,
			scheme: 'accentvoice',
			action: 'Join Meeting (Passing Server)',
		},
		// {
		// 	id: 7,
		// 	url: `accentvoice://${videoServer}/${room}`,
		// 	scheme: 'accentvoice',
		// 	action: 'Join Meeting (dev1)',
		// },

		{
			id: 8,
			url: 'com.accentvoice.meetings://meetings/start/mymeeting',
			scheme: 'com.accentvoice.meetings',
			action: 'Start My Meeting',
		},
		{
			id: 9,
			url: `com.accentvoice.meetings://meetings/join/${room}`,
			scheme: 'com.accentvoice.meetings',
			action: 'Join Meeting',
		},
		{
			id: 10,
			url: `com.accentvoice.meetings://${videoServer}/${room}`,
			scheme: 'com.accentvoice.meetings',
			action: 'Join Meeting (Passing Server)',
		},

		{
			id: 11,
			url: 'com.accentvoice.desktopbeta://meetings/start/mymeeting',
			scheme: 'com.accentvoice.desktopbeta',
			action: 'Start My Meeting',
		},
		{
			id: 12,
			url: `com.accentvoice.desktopbeta://meetings/join/${room}`,
			scheme: 'com.accentvoice.desktopbeta',
			action: 'Join Meeting',
		},
		{
			id: 13,
			url: `com.accentvoice.desktopbeta://${videoServer}/${room}`,
			scheme: 'com.accentvoice.desktopbeta',
			action: 'Join Meeting (Passing Server)',
		},
		{
			id: 14,
			url: 'com.accentvoice.desktop://meetings/start/mymeeting',
			scheme: 'com.accentvoice.desktop',
			action: 'Start My Meeting',
		},
		{
			id: 15,
			url: `com.accentvoice.desktop://meetings/join/${room}`,
			scheme: 'com.accentvoice.desktop',
			action: 'Join Meeting',
		},
		{
			id: 16,
			url: `com.accentvoice.desktop://${videoServer}/${room}`,
			scheme: 'com.accentvoice.desktop',
			action: 'Join Meeting (Passing Server)',
		},
	];
};

export const Links = () => {
	const handleOpenItem = (item: any) => window.open(item.url, 'noopener');
	const { userConfiguration } = useUserProfileContext();
	const [links, setLinks] = React.useState<Link[]>([]);
	const data = groupBy(links, 'scheme');

	React.useEffect(() => {
		if (userConfiguration?.room) {
			setLinks(createLinks(userConfiguration.room));
		} else {
			setLinks(createLinks('fvcsolutions'));
		}
	}, [userConfiguration?.room]);

	return (
		<Box sx={{ px: 2, height: '100%', overflow: 'auto' }}>
			{Object.keys(data).map((scheme) => (
				<List key={scheme}>
					<h4>{scheme}</h4>
					{data[scheme].map((item) => (
						<ListItem key={item.id}>
							<ListItemButton
								onClick={() => handleOpenItem(item)}
							>
								<ListItemText
									primary={item.action}
									secondary={item.url}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			))}
		</Box>
	);
};
