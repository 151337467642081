import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DayPicker } from 'react-day-picker';
import { useTheme } from '@mui/material';

// Icons
import Icon from '@mdi/react';
import {
	mdiCalendarToday,
	mdiChevronLeft,
	mdiChevronRight,
	mdiViewComfyOutline,
	mdiViewList,
} from '@mdi/js';

import { DateTime } from '../../lib/DateTime';
import { Colors } from '../../lib/Colors';
import {
	selectSelectedCalendarDate,
	useAppSelector,
	useAppDispatch,
	setSelectedDate,
	selectCalendarView,
	setCalendarView,
	selectPersonalMeeting,
} from '../../store';
import { AddEventMenu } from './AddEventMenu';
import { useMeetingActions } from '../meetings/useMeetingActions';

const getTimeFormat = (dt: Date): string => {
	const ord = DateTime.getDayOrdinal(dt);
	const month = DateTime.getMonthShort(dt);
	const weekday = DateTime.getWeekday(dt);
	const title = `${weekday} ${month} ${ord}`;
	return title;
};

export const CalendarHeader = () => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const dispatch = useAppDispatch();
	const selectedDate = useAppSelector(selectSelectedCalendarDate);
	const calendarView = useAppSelector(selectCalendarView);
	const personalMeeting = useAppSelector(selectPersonalMeeting);

	const actions = useMeetingActions();
	const [addMenuOpen, setAddMenuOpen] = React.useState(false);

	const today = new DateTime();
	const dt = new Date(selectedDate);
	const isToday =
		today.toFormattedShortDateYearFirst() ===
		new DateTime(dt).toFormattedShortDateYearFirst();

	const prevTitle = getTimeFormat(new DateTime(dt).addDays(-1).toDate());
	const nextTitle = getTimeFormat(new DateTime(dt).addDays(1).toDate());
	const title = getTimeFormat(dt);

	const todayDisabled = today.toStartOfDay().toISOString() === selectedDate;

	const handleLeftClick = () => {
		const d = new DateTime(selectedDate).addDays(-1).toISOString();
		dispatch(setSelectedDate(d));
		setOpen(false);
	};

	const handleRightClick = () => {
		const d = new DateTime(selectedDate).addDays(1).toISOString();
		dispatch(setSelectedDate(d));
		setOpen(false);
	};

	const handleTodayClick = () => {
		const d = new DateTime().toStartOfDay().toISOString();
		dispatch(setSelectedDate(d));
		setOpen(false);
	};

	const handleProviderClick = (provider: string) => {
		const start = new Date(selectedDate);
		const end = new Date(selectedDate);
		end.setMinutes(end.getMinutes() + 30);

		if (provider === 'google') {
			actions.openGoogleCalendarEvent(personalMeeting, start, end);
		}
		if (provider === 'microsoft') {
			actions.openMicrosoftCalendarEvent(personalMeeting, start, end);
		}
		setAddMenuOpen(false);
		setOpen(false);
	};

	return (
		<>
			<Toolbar
				variant="dense"
				style={{
					borderBottom: '1px solid rgba(0,0,0,0.1)',
					paddingLeft: theme.spacing(1),
				}}
			>
				<AddEventMenu
					open={addMenuOpen}
					onOpenChange={(value) => {
						setAddMenuOpen(value);
					}}
					onClick={handleProviderClick}
				/>
				<span style={{ flexGrow: 1 }} />
				<Tooltip title={`Go to ${prevTitle}`}>
					<IconButton size="small" onClick={handleLeftClick}>
						<Icon
							path={mdiChevronLeft}
							size={1.25}
							color={Colors.blue800}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Choose a different date">
					<Button
						variant="text"
						style={{
							minWidth: 130,
							borderRadius: 100,
							borderWidth: isToday ? 2 : 0,
							borderStyle: 'solid',
							borderColor: isToday
								? Colors.orange800
								: 'transparent',
							justifyContent: 'center',
							fontWeight: 'bold',
							paddingTop: 1,
							paddingBottom: 1,
							alignItems: 'center',
							marginLeft: 'auto',
							marginRight: 'auto',
							fontSize: 15,
							color: Colors.grey800,
							textTransform: 'uppercase',
						}}
						onClick={() => setOpen(!open)}
					>
						{title}
					</Button>
				</Tooltip>
				<Tooltip title={`Go to ${nextTitle}`}>
					<IconButton size="small" onClick={handleRightClick}>
						<Icon
							path={mdiChevronRight}
							size={1.25}
							color={Colors.blue800}
						/>
					</IconButton>
				</Tooltip>
				<span style={{ flexGrow: 1 }} />
				<Tooltip title="Go to Today">
					<IconButton
						size="small"
						onClick={handleTodayClick}
						disabled={todayDisabled}
						style={{
							marginRight: theme.spacing(1),
						}}
					>
						<Icon
							path={mdiCalendarToday}
							size={1}
							color={todayDisabled ? undefined : Colors.blue800}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip
					title={
						calendarView === 'List'
							? 'Switch to Timeline'
							: 'Switch to List View'
					}
				>
					<IconButton
						size="small"
						onClick={() => {
							dispatch(
								setCalendarView(
									calendarView === 'Grid' ? 'List' : 'Grid'
								)
							);
						}}
					>
						<Icon
							path={
								calendarView === 'List'
									? mdiViewList
									: mdiViewComfyOutline
							}
							size={1.25}
							color={Colors.blue800}
						/>
					</IconButton>
				</Tooltip>
			</Toolbar>
			{open && (
				<DayPicker
					mode="single"
					selected={new Date(selectedDate)}
					onSelect={(e) => {
						const d = new DateTime(e).toStartOfDay().toISOString();
						dispatch(setSelectedDate(d));
						setOpen(false);
					}}
				/>
			)}
		</>
	);
};
