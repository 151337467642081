import { Auth, API } from 'aws-amplify';

// Lib
import { ApiClientResponse, ApiClientInterface } from './types';
import { ApiClientResponseError, getResponseResult } from './apiResponseUtils';

export class ApiClient implements ApiClientInterface {
	private apiName: string;

	private prefix: string;

	constructor(apiName: string, prefix: string) {
		this.apiName = apiName;
		this.prefix = prefix;
	}

	private async getHeaders() {
		const token = `Bearer ${(await Auth.currentSession())
			.getIdToken()
			.getJwtToken()}`;
		// console.log('Authorization: ', token);
		return {
			Authorization: token,
		};
	}

	public async get<TResult>(
		url: string
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.get(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			console.log('API:ERROR', error);
			throw new ApiClientResponseError((error as any)?.response);
		}
	}

	public async post<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.post(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			console.log('API:ERROR', error);
			throw new ApiClientResponseError((error as any)?.response);
		}
	}

	public async put<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.put(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			console.log('API:ERROR', error);
			throw new ApiClientResponseError((error as any)?.response);
		}
	}

	public async patch<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.patch(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			console.log('API:ERROR', error);
			throw new ApiClientResponseError((error as any)?.response);
		}
	}

	public async del<TResult, TBody>(
		url: string,
		body?: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.del(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			console.log('API:ERROR', error);
			throw new ApiClientResponseError((error as any)?.response);
		}
	}
}

export const ApplicationApiClient = new ApiClient('api', 'api');
