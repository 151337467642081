/* eslint-disable react/require-default-props */
import { take } from 'lodash';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box, useTheme } from '@mui/material';

// Icons
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

// Components
import { AllDayEventItem } from './AllDayEventItem';

// Lib
import { useAppDispatch, useAppSelector } from '../../store';
import {
	selectSelectedCalendarDate,
	setSelectedEvent,
} from '../../store/calendar';
import { Colors } from '../../lib/Colors';
import useAllDayEvents from './useAllDayEvents';
import { useAppTheme } from '../../contexts/ThemeContext';

type Props = {
	date?: string;
};

const MinItemCount = 2;

export const AllDayEvents = ({ date }: Props) => {
	const dispatch = useAppDispatch();
	const { isDarkMode } = useAppTheme();
	const theme = useTheme();
	const [expanded, setExpanded] = React.useState(false);
	const selectedDate = useAppSelector(selectSelectedCalendarDate);
	const allDayEvents = useAllDayEvents(date || selectedDate);

	const handleSelectEvent = (id: string) => {
		dispatch(setSelectedEvent(id));
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				paddingLeft: 4,
				backgroundColor: isDarkMode ? Colors.grey900 : Colors.white,
				borderBottom: `8px solid #f6f6f6`,
			}}
		>
			<div
				style={{
					position: 'relative',
					height: '100%',
					maxHeight: 400,
					overflow: 'hidden',
					overflowY: 'auto',
				}}
			>
				{take(
					allDayEvents,
					expanded ? allDayEvents.length : MinItemCount
				).map((event) => (
					<AllDayEventItem
						key={`${event.provider}_${event.id}`}
						item={event}
						onSelectEvent={() => handleSelectEvent(event.id)}
					/>
				))}
			</div>
			{allDayEvents.length > MinItemCount && (
				<Box
					onClick={() => setExpanded(!expanded)}
					onKeyDown={() => setExpanded(!expanded)}
					role="button"
					tabIndex={0}
					sx={{
						'&:hover': {
							backgroundColor: theme.palette.action.hover,
							cursor: 'pointer',
						},
					}}
				>
					<div
						style={{
							paddingLeft: 14,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							borderBottomWidth: 1,
							borderBottomColor: isDarkMode
								? Colors.grey800
								: Colors.grey400,
						}}
					>
						<span
							style={{
								color: isDarkMode
									? Colors.grey300
									: Colors.grey900,
								textAlign: 'right',
							}}
						>
							{expanded
								? 'show less'
								: `${allDayEvents.length - MinItemCount} more`}
						</span>
						<IconButton
							size="small"
							onClick={() => setExpanded(!expanded)}
							style={{
								color: isDarkMode
									? Colors.grey300
									: Colors.grey900,
							}}
						>
							<Icon
								path={expanded ? mdiChevronUp : mdiChevronDown}
								size={1}
							/>
						</IconButton>
					</div>
				</Box>
			)}
		</div>
	);
};
