import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import { Loading } from '../common/Loading';
import { LoginForm } from './LoginForm';

// Lib
import { useAppContext } from '../../contexts/AppContext';

const widthStyle: React.CSSProperties = {
	minWidth: '320px',
	width: '30%',
	maxWidth: '420px',
};

type State = {
	loading: boolean;
	error?: unknown;
};

export const LoginSplash = () => {
	const { signin } = useAppContext();
	const [state, setState] = React.useState<State>({ loading: false });

	const handleSignin = async (username: string, password: string) => {
		setState({ loading: true, error: undefined });
		try {
			await signin(username, password);
			setState({ loading: false, error: undefined });
		} catch (error) {
			setState({ loading: false, error });
		}
	};

	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					width: '100%',
					height: '100%',
					backgroundRepeat: 'no-repeat !important',
					backgroundPosition: 'left !important',
					backgroundSize: 'cover !important',
					backgroundBlendMode: 'overlay !important',
					overflow: 'hidden',
				}}
				style={{
					backgroundImage: `url(https://meet-cdn.voiceonecloud.com/AccentDesktopBackdrop.png)`,
				}}
			/>
			<Box
				sx={{
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					backgroundColor: '#f6f6f6',
					opacity: 0.8,
				}}
				style={{ ...widthStyle }}
			/>
			<Grid
				container
				spacing={1}
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				alignContent="center"
				sx={{
					display: 'flex',
					height: '100%',
					py: 2,
					px: 6,
					mt: '15%',
				}}
				style={{ ...widthStyle }}
			>
				<Grid
					item
					sx={{
						width: '100%',
						textAlign: 'center',
						mb: 6,
					}}
				>
					<img
						style={{ width: '80%', position: 'relative' }}
						src="https://meet-cdn.voiceonecloud.com/accent.png"
						alt="Logo"
					/>
				</Grid>
				{!state.loading && state.error && (
					<Grid item sx={{ width: '100%', textAlign: 'center' }}>
						<Typography
							sx={{
								position: 'relative',
								color: 'palette.error.main',
							}}
						>{`${state.error}`}</Typography>
					</Grid>
				)}
				{!state.loading && (
					<Grid item sx={{ width: '100%', textAlign: 'center' }}>
						<LoginForm signin={handleSignin} />
					</Grid>
				)}
				{state.loading && (
					<div>
						<Loading />
					</div>
				)}
			</Grid>
		</>
	);
};
