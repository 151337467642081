/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import type { RootState } from '.';
import { WebUser } from '../lib';

export interface UserState {
	privateDevice?: boolean;
	lastUsername: string;
	user: WebUser | null;
}

const initialState: UserState = {
	privateDevice: false,
	lastUsername: '',
	user: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserLoggedIn: (state, action: PayloadAction<WebUser>) => {
			state.user = action.payload;
			if (state.privateDevice === true) {
				state.lastUsername = action.payload.username;
			}
		},
		setLastUsername: (state, action: PayloadAction<string>) => {
			state.lastUsername = action.payload;
		},
		setRememberMe: (state, action: PayloadAction<boolean>) => {
			state.privateDevice = action.payload === true;
			if (action.payload !== true) {
				state.lastUsername = '';
			}
		},
		setUserLoggedOut: (state) => {
			state.user = null;
		},
	},
});

export const {
	setUserLoggedIn,
	setUserLoggedOut,
	setLastUsername,
	setRememberMe,
} = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.user;

export const selectLastLoginUsername = (state: RootState) =>
	state.user.lastUsername;

export const selectPrivateDevice = (state: RootState) =>
	state.user.privateDevice || false;

export default userSlice.reducer;
