/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { orderBy, take } from 'lodash';

import { FlexRow } from '../common/FlexRow';
import { AgendaListItemAvatar } from './AgendaListItemAvatar';
import { EventParticipant } from '../../lib/types';

type Props = {
	items: EventParticipant[];
};

export const AgendaListItemAvatarList = ({ items }: Props) => {
	const accepted = orderBy(
		items.filter((x) => x.status === 'accepted'),
		['name']
	);
	const tentative = items.filter((x) => x.status === 'tentative');
	const declined = items.filter((x) => x.status === 'declined');
	const none = items.filter(
		(x) =>
			x.status &&
			!['accepted', 'tentative', 'declined'].includes(x.status)
	);
	const orderedItems = take(
		[...accepted, ...tentative, ...none, ...declined],
		4
	);

	return (
		<FlexRow
			data-component="avatar-list"
			sx={{
				flex: 1,
				alignItems: 'center',
				justifyContent: 'flex-end',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				height: '28px',
			}}
		>
			{orderedItems.map((item, index) => (
				<AgendaListItemAvatar key={`${item.id}_${index}`} item={item} />
			))}
		</FlexRow>
	);
};
