/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-plusplus */
import { orderBy } from 'lodash';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMeasure } from 'react-use';

// Icons
import Icon from '@mdi/react';
import { mdiGoogle, mdiMicrosoft } from '@mdi/js';

import { useAppTheme } from '../../contexts/ThemeContext';
import { DateTime } from '../../lib/DateTime';
import { CalendarEvent } from '../../lib/types';
import { selectPersonalMeeting, useAppSelector } from '../../store';
import { selectSelectedCalendarDate } from '../../store/calendar';
import {
	Colors,
	LightModeGutterText,
	LightModeGridHour,
	LightModeGridHalfHour,
	LightModeGridBackground,
	DarkModeGutter,
	DarkModeGutterText,
	DarkModeGridHour,
	DarkModeGridHalfHour,
	DarkModeGridBackground,
} from '../../lib/Colors';

import { AgendaGridViewItem } from './AgendaGridViewItem';
import {
	AgendaGridRowHeight,
	AgendaGridTotalRowHeight,
	AgendaGridGutterWidth,
	getMultiDay,
} from './dateutils';
import { renderTimelineData } from './renderTimelineData';
import useEvents from './useEvents';
import { FlexRow } from '../common/FlexRow';
import { useCalendarContext } from '../../contexts/CalendarContext';
import { useMeetingActions } from '../meetings/useMeetingActions';

const getRows = () => {
	const slots = [];
	const dt = new Date();
	dt.setHours(0, 0, 0, 0);

	for (let index = 0; index < 48; index++) {
		slots.push({
			time: dt.toISOString(),
		});
		dt.setMinutes(dt.getMinutes() + 30);
	}

	return slots;
};
const rows = getRows();

type IndicatorProps = {
	offset: number;
	hidden: boolean;
};

const Indicator = ({ offset, hidden }: IndicatorProps) => {
	const viewRef = React.useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const t = setTimeout(() => {
			if (viewRef.current) {
				viewRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}, 1000);
		return () => clearTimeout(t);
	}, []);

	return (
		<div
			data-component="indicator"
			ref={viewRef}
			style={{
				top: `${offset}px`,
				backgroundColor: hidden ? 'transparent' : Colors.blue800,
				position: 'absolute',
				left: '44px',
				width: 'calc(100% - 54px)',
				height: '1px',
				zIndex: 2,
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: '-5.5px',
					width: '12px',
					height: '12px',
					borderRadius: '24px',
					backgroundColor: hidden ? 'transparent' : Colors.blue800,
				}}
			/>
		</div>
	);
};

type TimeSlotProps = {
	onClick: () => void;
	title: string;
	hour: boolean;
	isSelected: boolean;
	googleConnected: boolean;
	microsoftConnected: boolean;
	onAddEvent: (provider: string) => void;
};

const TimeSlot = ({
	title,
	isSelected,
	hour,
	onClick,
	googleConnected,
	microsoftConnected,
	onAddEvent,
}: TimeSlotProps) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const { isDarkMode } = useAppTheme();

	const handleOpen = (el: HTMLDivElement) => {
		setAnchorEl(el);
		onClick();
	};

	return (
		<div
			data-component="time-slot"
			style={{
				flexGrow: 1,
				height: `${AgendaGridRowHeight}px`,
				minHeight: `${AgendaGridRowHeight}px`,
				maxHeight: `${AgendaGridRowHeight}px`,
			}}
			onKeyDown={(e) => handleOpen(e.currentTarget)}
			onClick={(e) => handleOpen(e.currentTarget)}
			role="button"
			tabIndex={0}
			aria-label="Add"
			aria-controls="fab-menu"
			aria-haspopup="true"
		>
			<FlexRow
				sx={{
					flexGrow: 1,
					height: `${AgendaGridRowHeight}px`,
					minHeight: `${AgendaGridRowHeight}px`,
					maxHeight: `${AgendaGridRowHeight}px`,
				}}
				nowrap
			>
				<div
					style={{
						backgroundColor: isDarkMode
							? DarkModeGutter
							: '#f6f6f6',
						height: `${AgendaGridRowHeight}px`,
						minHeight: `${AgendaGridRowHeight}px`,
						maxHeight: `${AgendaGridRowHeight}px`,
						width: `${AgendaGridGutterWidth}px`,
						display: 'flex',
						flexFlow: 'row nowrap',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
					}}
				>
					<span
						style={{
							color: isDarkMode
								? DarkModeGutterText
								: LightModeGutterText,
							position: 'relative',
							fontSize: '11px',
							top: `${-(AgendaGridRowHeight / 1.3)}px`,
							marginRight: '12px',
						}}
					>
						{hour === true && title}
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						flexFlow: 'row nowrap',
						alignItems: 'center',
						flexGrow: 1,
						borderTopWidth: 1,
						borderLeftWidth: 0,
						borderRightWidth: 0,
						borderBottomWidth: 0,
						borderStyle: 'solid',
						height: `${AgendaGridRowHeight}px`,
						minHeight: `${AgendaGridRowHeight}px`,
						maxHeight: `${AgendaGridRowHeight}px`,
						backgroundColor:
							hour === true
								? !isSelected
									? isDarkMode
										? DarkModeGridBackground
										: LightModeGridBackground
									: Colors.orange800
								: !isSelected
								? isDarkMode
									? DarkModeGridBackground
									: LightModeGridBackground
								: Colors.orange800,
						borderTopColor:
							hour === true
								? isDarkMode
									? DarkModeGridHour
									: LightModeGridHour
								: isDarkMode
								? DarkModeGridHalfHour
								: LightModeGridHalfHour,
					}}
				>
					&nbsp;
				</div>
			</FlexRow>
			<Menu
				id="fab-menu"
				open={anchorEl !== null && isSelected}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
				keepMounted
				elevation={3}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{!!googleConnected && (
					<MenuItem onClick={() => onAddEvent('google')}>
						<ListItemIcon>
							<Icon path={mdiGoogle} size={1} />
						</ListItemIcon>
						<ListItemText>Add Google Event</ListItemText>
					</MenuItem>
				)}
				{!!microsoftConnected && (
					<MenuItem onClick={() => onAddEvent('microsoft')}>
						<ListItemIcon>
							<Icon path={mdiMicrosoft} size={1} />
						</ListItemIcon>
						<ListItemText>Add Microsoft Event</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};

type Props = {
	show: boolean;
	selectedTime?: string;
	onNewEvent: (time: string) => void;
};

export const AgendaGridView = ({ show, onNewEvent, selectedTime }: Props) => {
	const selectedDate = useAppSelector(selectSelectedCalendarDate);
	const events = useEvents(selectedDate);
	const [offset, setOffset] = React.useState(0);
	const [ref, { width }] = useMeasure<HTMLDivElement>();
	const { isGoogleConnected, isMicrosoftConnected } = useCalendarContext();
	const personalMeeting = useAppSelector(selectPersonalMeeting);
	const actions = useMeetingActions();

	const today = new DateTime();
	const dt = new Date(selectedDate);
	const isToday =
		today.toFormattedShortDateYearFirst() ===
		new DateTime(dt).toFormattedShortDateYearFirst();

	const calculateOffset = React.useCallback(() => {
		const now = DateTime.getTotalMinutesFromMidnight(new Date());
		const result = Math.floor(
			(AgendaGridTotalRowHeight / DateTime.TotalMinutesInOneDay) * now
		);
		setOffset(result);
	}, []);

	const handleProviderClick = (provider: string, slot: string) => {
		const start = DateTime.setTime(selectedDate, slot);
		const end = DateTime.setTime(selectedDate, slot);
		end.setMinutes(end.getMinutes() + 30);

		if (provider === 'google') {
			actions.openGoogleCalendarEvent(personalMeeting, start, end);
		}
		if (provider === 'microsoft') {
			actions.openMicrosoftCalendarEvent(personalMeeting, start, end);
		}
	};

	React.useEffect(() => {
		calculateOffset();
		const interval = setInterval(calculateOffset, 60000);
		return () => clearInterval(interval);
	}, [calculateOffset]);

	const loadTimeline = React.useCallback(() => {
		// console.log('');
		// console.log(`Rendering Timeline ${new Date(selectedDate).toLocaleString()}`);
		const timeline = renderTimelineData<CalendarEvent>(
			orderBy(events, ['duration'], ['desc']),
			(event) => {
				let start = event.startTime;
				let end = event.endTime;
				let { duration } = event;
				const [isMultiDay, startsToday, endsToday] = getMultiDay(
					event,
					selectedDate
				);

				if (isMultiDay) {
					const s = new Date(start);
					const e = new Date(end);

					const dts = new Date(selectedDate);
					dts.setHours(s.getHours());
					dts.setMinutes(s.getMinutes());

					const dte = new Date(selectedDate);
					dte.setHours(e.getHours());
					dte.setMinutes(e.getMinutes());

					if (startsToday) {
						end = new DateTime(selectedDate)
							.toEndOfDay()
							.toDate()
							.getTime();
						duration = DateTime.getMinutesBetweenDates(
							dts,
							new Date(end)
						);
						// console.log('startsToday', new Date(start).toLocaleString(), new Date(end).toLocaleString());
					}

					if (endsToday) {
						start = new DateTime(selectedDate)
							.toStartOfDay()
							.toDate()
							.getTime();
						duration = DateTime.getMinutesBetweenDates(
							new Date(start),
							dte
						);
						// console.log('endsToday', new Date(start).toLocaleString(), new Date(end).toLocaleString());
					}
				}

				return {
					id: event.id,
					start,
					end,
					duration,
					event,
				};
			},
			{
				containerWidth: width - AgendaGridGutterWidth - 2,
				intervalInMinutes: 30,
				slotHeight: AgendaGridRowHeight,
				overlap: false,
			}
		);
		return timeline;
	}, [events, width, selectedDate]);

	// React.useEffect(() => {
	//     const data = loadTimeline();
	//     setTimelineData(data);
	// }, [loadTimeline]);

	if (!show) {
		return null;
	}

	const timelineData = loadTimeline();

	return (
		<div
			data-component="slots"
			ref={ref}
			style={{
				position: 'relative',
				flexGrow: 1,
				width: '100%',
				paddingBottom: '20px',
				paddingTop: '5px',
			}}
		>
			{rows.map((row, index) => (
				<TimeSlot
					key={`${index}_${row.time}`}
					isSelected={row.time === selectedTime}
					title={new DateTime(
						row.time
					).toFormattedShortTimeHourOnly()}
					hour={index % 2 === 0}
					onClick={() => {
						onNewEvent(row.time);
					}}
					googleConnected={isGoogleConnected}
					microsoftConnected={isMicrosoftConnected}
					onAddEvent={(provider) =>
						handleProviderClick(provider, row.time)
					}
				/>
			))}
			{timelineData.map((item) => {
				const event = events.find((x) => x.id === item.id);
				if (!event) {
					return null;
				}
				return (
					<AgendaGridViewItem
						key={`${event.id}`}
						event={event}
						positionStyle={{
							top: `${item.top}px`,
							bottom: `${item.height}px`,
							left: `${item.left + AgendaGridGutterWidth}px`,
							width: `${item.width}px`,
							height: `${item.height - 2}px`,
							minHeight: `${item.height - 2}px`,
							maxHeight: `${item.height - 2}px`,
						}}
					/>
				);
			})}
			<Indicator
				key={`${selectedDate}`}
				offset={offset}
				hidden={!isToday}
			/>
		</div>
	);
};
