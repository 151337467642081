import * as React from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import Icon from '@mdi/react';
import {
	mdiTabPlus,
	mdiEye,
	mdiLockOutline,
	mdiEyeOff,
	mdiHelpCircle,
} from '@mdi/js';

// import {
// 	AllJitsiConfigUrlOptions,
// 	TokenPayload,
// 	getJwtExpiration,
// 	getAvatarImagePath,
// 	getJwtNotBefore,
// } from '@accentcommunications/common';

import { FlexColumn } from '../common/FlexColumn';
import { FlexRow } from '../common/FlexRow';
import { TextFieldMenu, TextFieldMenuItem } from '../common/TextFieldMenu';
import { useUserProfileContext } from '../../contexts/UserProfileContext';
import { defaultJitsiServer } from '../../lib/types';
import { Editor } from './Editor';
import { api } from '../../api';
import { AllJitsiConfigUrlOptions, TokenPayload } from '../../lib/common';
import { getAvatarImagePath, getJwtExpiration, getJwtNotBefore } from '../../lib/utils';

const signTokenApi = async (seed: string, payload: any) => {
	try {
		const response = await api.common.signJwt(seed, payload);
		return response;
	} catch (error) {
		return { token: `ERROR: ${error}` };
	}
};

const allowed = ['accent-5001', 'accent-5002', 'accent-3557', 'accent-3533'];

const servers = [
	{ title: 'meet.accentvoice.com' },
	{ title: 'dev1-meet.accentvoice.com' },
	{ title: 'meet.jit.si' },
];

interface FeatureFlag {
	flag: string;
	description: string;
	value?: boolean;
}

interface ConfigOption {
	flag: string;
	description: string;
	value?: boolean | string | number;
}

type State = {
	server: string;
	room: string;
	flags: FeatureFlag[];
	params: ConfigOption[];
	payload?: TokenPayload;
};

export const JitsiTest = () => {
	const [state, setState] = React.useState<State>({
		server: defaultJitsiServer,
		room: '',
		flags: [],
		params: [
			...AllJitsiConfigUrlOptions.filter(
				(x: any) => typeof x.value === 'boolean'
			).map((x: any) => ({ ...x, value: false })),
		],
	});
	const [url, setUrl] = React.useState('');
	const [token, setToken] = React.useState('');
	const [payload, setPayload] = React.useState('');
	const [payloadOutput, setPayloadOutput] = React.useState('');
	const [seed, setSeed] = React.useState('');
	const [seedVisible, setSeedVisible] = React.useState(false);
	const [parseError, setParseError] = React.useState<any>();
	const { userConfiguration } = useUserProfileContext();

	const handleSignJwtClick = async (content: any) => {
		console.info('Signing', content);
		const result = await signTokenApi(seed, content);
		setToken(result?.token || '');
	};

	const updateJwtPayload = React.useCallback(
		(room: string) => {
			// https://developer.8x8.com/jaas/docs/api-keys-jwt
			if (userConfiguration) {
				const data = {
					aud: 'accentmeet',
					context: {
						user: {
							id: userConfiguration.authId,
							name: `${userConfiguration.firstName} ${userConfiguration.lastName}`,
							avatar: getAvatarImagePath(
								userConfiguration.ucpAdminUri,
								userConfiguration.imagePath
							),
							email: userConfiguration.email,
						},
						// features: {
						// 	livestreaming: 'false',
						// 	recording: 'false',
						// 	transcription: 'false',
						// 	// 'sip-inbound-call': 'false',
						// 	'sip-outbound-call': 'false',
						// 	// 'inbound-call': 'false',
						// 	'outbound-call': 'false',
						// },
						// room: {
						// 	regex: false,
						// },
					},
					moderator: true,
					iss: 'meet',
					sub: '*',
					room: userConfiguration.room,
					exp: getJwtExpiration(470),
					nbf: getJwtNotBefore(),
				};
				return data;
			}
		},
		[userConfiguration]
	);

	const handlePayloadChange = React.useCallback(
		(value: string) => {
			console.log('handlePayloadChange');
			try {
				const json = JSON.parse(value);
				const room = json.room;
				setState((s) => ({
					...s,
					room:
						room === '*'
							? userConfiguration?.room || 'accent-meeting'
							: room,
				}));
				setPayloadOutput(JSON.stringify(json));
				setParseError(undefined);
			} catch (error) {
				setParseError(error);
			}
		},
		[userConfiguration?.room]
	);

	const updateUrl = React.useCallback(() => {
		let uri = `https://${state.server}/${state.room}`;

		if (token) {
			uri = `${uri}?jwt=${token}`;
		}

		const opts = state.params
			.filter((x) => x.value === true)
			.map((x) => `${x.flag}=true`);

		if (opts.length > 0) {
			setUrl(`${uri}#${opts.join('&')}`);
		} else {
			setUrl(uri);
		}
	}, [state, token]);

	const onServerChange = (value: string) => {
		setState((s) => ({ ...s, server: value }));
	};

	const onServerSelect = (item: TextFieldMenuItem) => {
		setState((s) => ({ ...s, server: item.title }));
	};

	const handleToggleAllOptions = () => {
		setState((s) => {
			const count = state.params.filter((x) => x.value === true).length;
			const value = count === 0 ? true : false;
			const opts = [...s.params];
			for (let index = 0; index < s.params.length; index++) {
				opts[index] = {
					flag: opts[index].flag,
					description: opts[index].description,
					value,
				};
			}
			return { ...s, params: [...opts] };
		});
	};

	const onConfigOptionsToggle = (index: number) => {
		setState((s) => {
			const opts = [...s.params];
			opts[index] = {
				flag: opts[index].flag,
				description: opts[index].description,
				value: opts[index].value === true ? false : true,
			};
			return { ...s, params: [...opts] };
		});
	};

	React.useEffect(() => {
		if (userConfiguration) {
			setState((s) => ({
				...s,
				server:
					userConfiguration.videoServer ||
					s.server ||
					defaultJitsiServer,
				room: userConfiguration.room,
			}));
			const result = JSON.stringify(
				updateJwtPayload(userConfiguration.room),
				null,
				'\t'
			);
			console.log('SETTING PAYLOAD');
			setPayload(result);
			handlePayloadChange(result);
		}
	}, [userConfiguration, updateJwtPayload, handlePayloadChange]);

	React.useEffect(() => {
		if (state.server && state.room) {
			updateUrl();
		}
	}, [state, updateUrl]);

	if (
		!userConfiguration ||
		!userConfiguration?.room ||
		!allowed.includes(userConfiguration.room)
	) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				height: '100%',
				position: 'relative',
				backgroundColor: '#fff',
			}}
		>
			<FlexRow sx={{ py: 1, pr: 2, pl: 1, mt: 2 }}>
				<Typography variant="caption" sx={{ pl: 2 }}>
					Documentation as of 10-07-2022
				</Typography>
				<span style={{ flex: 1 }} />
				{/* <Tooltip title="Save this test">
					<IconButton onClick={() => {}}>
						<Icon path={mdiDownload} size={1} />
					</IconButton>
				</Tooltip>
				<Tooltip title="Load a saved test">
					<IconButton onClick={() => {}}>
						<Icon path={mdiUpload} size={1} />
					</IconButton>
				</Tooltip> */}
			</FlexRow>
			<FlexRow fill nowrap>
				<FlexColumn fill sx={{ p: 1 }}>
					<FlexRow nowrap>
						<TextFieldMenu
							id="server-selection"
							sx={{ m: 1 }}
							label="Server"
							value={state.server}
							items={servers}
							onChange={onServerChange}
							onSelect={onServerSelect}
						/>
					</FlexRow>
					<FlexColumn fill sx={{ p: 1 }}>
						<Editor
							value={payload}
							onChange={handlePayloadChange}
						/>
					</FlexColumn>
					<FlexRow
						sx={{
							py: 1,
							px: 1,
							alignItems: 'center',
							// justifyContent: 'flex-end',
						}}
					>
						{parseError && (
							<Typography
								variant="body1"
								fontWeight="bold"
								color="error"
							>
								{`${parseError}`}
							</Typography>
						)}
						{!parseError && (
							<Typography variant="body1">
								JSON looks good
							</Typography>
						)}
					</FlexRow>
					<FlexColumn sx={{ py: 1, px: 1 }}>
						<FlexRow nowrap sx={{ alignItems: 'center', mb: 2 }}>
							<TextField
								sx={{ mr: 1 }}
								label="JWT Seed"
								variant="outlined"
								type={seedVisible ? 'text' : 'password'}
								size="small"
								value={seed}
								onChange={(e) => setSeed(e.target.value || '')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												size="small"
												edge="end"
												aria-label="toggle password visibility"
												onClick={() =>
													setSeedVisible(!seedVisible)
												}
												onMouseDown={(e) =>
													e.preventDefault()
												}
											>
												<Icon
													path={
														seedVisible
															? mdiEye
															: mdiEyeOff
													}
													size={1}
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									handleSignJwtClick(payloadOutput)
								}
								endIcon={
									<Icon path={mdiLockOutline} size={1} />
								}
								disabled={!!parseError}
							>
								Sign
							</Button>
							<Tooltip title="8x8 JWT Documentation">
								<IconButton
									color="primary"
									onClick={() =>
										window.open(
											'https://developer.8x8.com/jaas/docs/api-keys-jwt',
											'noopener'
										)
									}
								>
									<Icon path={mdiHelpCircle} size={1} />
								</IconButton>
							</Tooltip>
						</FlexRow>
						<TextField
							multiline
							minRows={5}
							maxRows={5}
							value={token}
							label="JWT Token"
						/>
					</FlexColumn>
					<FlexColumn sx={{ py: 1, px: 1 }}>
						<TextField
							multiline
							minRows={5}
							maxRows={5}
							value={url}
							label="URL"
							onChange={(e) => setUrl(e.target.value)}
						/>
					</FlexColumn>
				</FlexColumn>
				<FlexColumn
					fill
					sx={{
						minWidth: '350px',
						maxWidth: '350px',
						width: '350px',
						mx: 2,
					}}
				>
					<FlexRow sx={{ p: 2, alignItems: 'center' }}>
						<Typography
							variant="body1"
							fontWeight="bold"
							sx={{ py: 1 }}
						>
							URL Parameters
						</Typography>
						<Tooltip title="Jitsi Advanced Options Documentation">
							<IconButton
								color="primary"
								onClick={() =>
									window.open(
										'https://jitsi.github.io/handbook/docs/user-guide/user-guide-advanced',
										'noopener'
									)
								}
							>
								<Icon path={mdiHelpCircle} size={1} />
							</IconButton>
						</Tooltip>
						<span style={{ flex: 1 }} />
						<Switch
							edge="end"
							onChange={handleToggleAllOptions}
							checked={
								state.params.filter((x) => x.value === true)
									.length >= 1
							}
						/>
					</FlexRow>
					<Divider />
					<FlexColumn scroll>
						<List dense disablePadding>
							{state.params.map((item, index) => (
								<ListItem disableGutters key={item.flag}>
									<ListItemButton
										onClick={() =>
											onConfigOptionsToggle(index)
										}
										dense
									>
										<ListItemText
											primary={item.flag}
											secondary={item.description}
											secondaryTypographyProps={{
												paddingRight: '40px',
											}}
										/>
										<ListItemSecondaryAction>
											<Switch
												edge="end"
												checked={item.value === true}
											/>
										</ListItemSecondaryAction>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</FlexColumn>
				</FlexColumn>
			</FlexRow>
			<FlexRow
				sx={{
					alignItems: 'center',
					justifyContent: 'flex-end',
					py: 2,
					px: 2,
				}}
			>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => window.open(encodeURI(url), 'noopener')}
					endIcon={<Icon path={mdiTabPlus} size={1} />}
					disabled={!url}
				>
					Launch Meeting
				</Button>
			</FlexRow>
		</Box>
	);
};
