/* eslint-disable react/require-default-props */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

// Icons
import Icon from '@mdi/react';

export type ToolbarButtonProps = {
	variant?: 'standard' | 'none' | 'solid' | 'secondary' | 'red';
	selected?: boolean;
	tooltip?: string;
	text?: string;
	icon?: string;
	disabled?: boolean;
	count?: number;
	style?: React.CSSProperties;
	onClick: () => void;
	iconColor?: string;
	avatar?: string;
};

type StartIconProps = {
	icon?: string;
	iconColor?: string;
	avatar?: string;
};

const StartIcon = (props: StartIconProps) => {
	const { avatar, icon, iconColor } = props;
	if (avatar) {
		return (
			<Avatar
				variant="circular"
				alt="Avatar"
				src={avatar}
				sx={{ width: 18, height: 18 }}
			/>
		);
	}
	if (icon) {
		return <Icon path={icon} size={0.75} color={iconColor} />;
	}
	return null;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
	const {
		variant = 'standard',
		selected = false,
		text,
		tooltip = '',
		icon,
		disabled = false,
		count = 0,
		iconColor,
		style = {},
		avatar,
		onClick,
	} = props;

	return (
		<Tooltip title={tooltip} placement="bottom">
			<Button
				disableRipple={variant === 'none'}
				variant={variant === 'solid' ? 'contained' : 'outlined'}
				color={variant === 'secondary' ? 'secondary' : 'inherit'}
				sx={(theme) => ({
					position: 'relative',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					backgroundColor: '#f0f0f0',
					alignSelf: 'center',
					borderRadius: '4px',
					marginRight: 1,
					color: '#555555',
					fontSize: theme.typography.caption.fontSize,
					cursor: 'pointer',
					textTransform: 'none',
					whiteSpace: 'nowrap',
					fontWeight: 'normal',
					minWidth: !text ? 'unset' : undefined,
					padding: !text ? theme.spacing(0.7, 1) : undefined,
					border: selected
						? '1px solid #FFAE5C !important'
						: '1px solid transparent !important',
					'&:hover': {
						backgroundColor: '#ffffff',
						border: '1px solid rgba(0, 0, 0, 0.23) !important',
					},
					'& .MuiButton-startIcon': {
						color: '#eb9034',
						marginRight: !text ? -0.5 : 'inherit',
					},
				})}
				style={style}
				onClick={onClick}
				disabled={disabled}
				startIcon={
					<StartIcon
						avatar={avatar}
						icon={icon}
						iconColor={iconColor}
					/>
				}
			>
				{text}
				<Badge
					badgeContent={count}
					color="secondary"
					sx={{ position: 'relative', top: '-10px', right: '-10px' }}
				/>
			</Button>
		</Tooltip>
	);
};
