import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../store';

// Lib
import {
	CalendarClass,
	ConferenceProviderSetting,
} from '../lib/types';
import {
	selectIsCalendarReady,
	selectIsGoogleConnected,
	selectIsMicrosoftConnected,
	selectSelectedCalendarDate,
	setGoogleConnected,
	setIsCalendarReady,
	setMicrosoftConnected,
	updateCalendarEvents,
} from '../store/calendar';
import { useAppContext } from './AppContext';
import { useApplicationConfigurationContext } from './ApplicationConfiguration';

import { ApplicationEvent } from '../lib/ApplicationEvent';
import { DateTime } from '../lib/DateTime';
import GoogleCalendar from '../lib/google/GoogleCalendar';
import GoogleCalendarEventMapper from '../lib/google/GoogleCalendarEventMapper';
import MicrosoftCalendar from '../lib/microsoft/MicrosoftCalendar';
import MicrosoftCalendarEventMapper from '../lib/microsoft/MicrosoftCalendarEventMapper';

export interface CalendarContextValue {
	onRefresh: () => void;
	isCalendarReady: boolean;
	refreshing: boolean;
	isGoogleConnected: boolean;
	isMicrosoftConnected: boolean;
	connectGoogle: () => Promise<boolean>;
	connectMicrosoft: () => Promise<boolean>;
	disconnectGoogle: () => Promise<boolean>;
	disconnectMicrosoft: () => Promise<boolean>;
}

const initialPreferences: CalendarContextValue = {
	onRefresh: () => { },
	isCalendarReady: false,
	refreshing: false,
	isGoogleConnected: false,
	isMicrosoftConnected: false,
	connectGoogle: () => Promise.resolve(false),
	connectMicrosoft: () => Promise.resolve(false),
	disconnectGoogle: () => Promise.resolve(false),
	disconnectMicrosoft: () => Promise.resolve(false),
};

export const CalendarContext =
	React.createContext<CalendarContextValue>(initialPreferences);

export interface Props {
	children: React.ReactNode;
}

let google: CalendarClass | null = null;
let microsoft: CalendarClass | null = null;

const createMicrosoft = (
	authId: string,
	providers: ConferenceProviderSetting[]
): CalendarClass => {
	microsoft = new MicrosoftCalendar(
		authId,
		new MicrosoftCalendarEventMapper(providers)
	);
	return microsoft;
};

const createGoogle = (
	authId: string,
	providers: ConferenceProviderSetting[]
): CalendarClass => {
	google = new GoogleCalendar(
		authId,
		new GoogleCalendarEventMapper(providers)
	);
	return google;
};

export const CalendarContextProvider = ({ children }: Props) => {
	const dispatch = useAppDispatch();
	const selectedDate = useAppSelector(selectSelectedCalendarDate);
	const { user, isOnline } = useAppContext();
	const config = useApplicationConfigurationContext();
	const [refreshing, setRefreshing] = React.useState(false);

	const isGoogleConnected = useAppSelector(selectIsGoogleConnected);
	const isMicrosoftConnected = useAppSelector(selectIsMicrosoftConnected);
	const isCalendarReady = useAppSelector(selectIsCalendarReady);

	const userSub = user?.sub || '';
	const providers = config.appConfig.conferenceProviders;

	const disconnectGoogle = React.useCallback(async () => {
		if (!google) createGoogle(userSub, providers);
		try {
			if (await google?.disconnect()) {
				dispatch(setGoogleConnected(false));
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	}, [dispatch, providers, userSub]);

	const disconnectMicrosoft = React.useCallback(async () => {
		if (!microsoft) createMicrosoft(userSub, providers);
		try {
			if (await microsoft?.disconnect()) {
				dispatch(setMicrosoftConnected(false));
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	}, [dispatch, providers, userSub]);

	const connectGoogle = React.useCallback(async () => {
		if (!google) createGoogle(userSub, providers);
		try {
			if (await google?.connect()) {
				dispatch(setGoogleConnected(true));
				return true;
			} else {
				dispatch(setGoogleConnected(false));
				return false;
			}
		} catch (error) {
			console.error(error);
			dispatch(setGoogleConnected(false));
			return false;
		}
	}, [dispatch, providers, userSub]);

	const connectMicrosoft = React.useCallback(async () => {
		if (!microsoft) createMicrosoft(userSub, providers);
		try {
			if (await microsoft?.connect()) {
				dispatch(setMicrosoftConnected(true));
				return true;
			} else {
				dispatch(setMicrosoftConnected(false));
				return false;
			}
		} catch (error) {
			console.error(error);
			dispatch(setMicrosoftConnected(false));
			return false;
		}
	}, [dispatch, providers, userSub]);

	const refresh = React.useCallback(async () => {
		if (isCalendarReady && !!microsoft && !!google && !!userSub) {
			try {
				const start = new DateTime(selectedDate)
					.toStartOfDay()
					.toDate();
				const end = new DateTime(selectedDate).toEndOfDay().toDate();
				const googleEvents = await google.getEvents(start, end);
				const microsoftEvents = await microsoft.getEvents(start, end);
				dispatch(
					updateCalendarEvents({
						googleEvents,
						microsoftEvents,
					})
				);
			} catch (error) {
				console.error(error);
			}
		}
	}, [dispatch, isCalendarReady, userSub, selectedDate]);

	const checkConnections = React.useCallback(async () => {
		if (!!microsoft && !!google) {
			// console.log('await google?.isConnected()');
			const googleConnected = (await google.isConnected()) === true;

			// console.log('await microsoft?.isConnected()');
			const msConnected = (await microsoft.isConnected()) === true;

			if (!isGoogleConnected && googleConnected) {
				dispatch(setGoogleConnected(true));
			} else if (isGoogleConnected && !googleConnected) {
				dispatch(setGoogleConnected(false));
			}

			if (!isMicrosoftConnected && msConnected) {
				dispatch(setMicrosoftConnected(true));
			} else if (isMicrosoftConnected && !msConnected) {
				dispatch(setMicrosoftConnected(false));
			}

			if (!isCalendarReady) {
				// console.log('dispatching isCalendarReady');
				setTimeout(() => {
					dispatch(setIsCalendarReady(true));
				}, 1000);
			}
		}
	}, [isGoogleConnected, isMicrosoftConnected, isCalendarReady, dispatch]);

	// For manual refreshing
	const onRefresh = React.useCallback(async () => {
		setRefreshing(true);
		try {
			await refresh();
		} catch (error) {
			console.error(error);
		} finally {
			setRefreshing(false);
		}
	}, [refresh]);

	React.useEffect(() => {
		if (userSub) {
			createGoogle(userSub, providers);
			createMicrosoft(userSub, providers);
		} else {
			google = null;
			microsoft = null;
		}
	}, [userSub, providers]);

	React.useEffect(() => {
		// Refresh events every 5 minutes
		if (userSub && isOnline && isCalendarReady && !!microsoft && !!google) {
			refresh();
			const interval = setInterval(() => {
				refresh();
			}, 60000);
			return () => clearInterval(interval);
		}
	}, [dispatch, isCalendarReady, isOnline, refresh, userSub]);

	React.useEffect(() => {
		// refresh connection status every 5 seconds
		if (userSub && isOnline && !!microsoft && !!google) {
			checkConnections();
			const interval = setInterval(() => {
				checkConnections();
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [checkConnections, isOnline, userSub]);

	React.useEffect(() => {
		ApplicationEvent.Receive('disconnect-calendars', () => {
			console.warn('event:received:disconnect-calendars');
			google?.disconnect();
			microsoft?.disconnect();
		});
	}, []);

	const value: CalendarContextValue = {
		onRefresh,
		isCalendarReady,
		refreshing,
		isGoogleConnected,
		isMicrosoftConnected,
		connectGoogle,
		connectMicrosoft,
		disconnectGoogle,
		disconnectMicrosoft,
	};

	return (
		<CalendarContext.Provider value={value}>
			{children}
		</CalendarContext.Provider>
	);
};

export const useCalendarContext = (): CalendarContextValue => {
	return React.useContext(CalendarContext);
};
