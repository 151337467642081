export const logoutIFrame = () => {
	const iframe = document.getElementById('ucp') as HTMLIFrameElement;
	if (iframe) {
		const str = iframe.src;
		const parts = str.split('/app');
		const url = `${parts[0]}/app/logout`;
		console.log('Logging out of connect', url);
		iframe.src = url;
	}
};
