import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Icons
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

export type TextFieldMenuItem = {
	id?: string;
	title: string;
	icon?: string;
};

type Props = {
	id: string;
	label?: string;
	placeholder?: string;
	error?: boolean;
	value: string;
	items: TextFieldMenuItem[];
	onChange: (value: string) => void;
	onSelect: (item: TextFieldMenuItem) => void;
	sx?: SxProps<Theme>;
};

export const TextFieldMenu = ({
	id,
	label,
	placeholder,
	error,
	value,
	items,
	onChange,
	onSelect,
	sx = {},
}: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<TextField
				sx={sx}
				label={label}
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChange(e.target.value || '')}
				error={error}
				variant="outlined"
				size="small"
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								size="small"
								edge="end"
								aria-label="toggle password visibility"
								onClick={handleClick}
								onMouseDown={(e) => e.preventDefault()}
								aria-controls={open ? id : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
							>
								<Icon
									path={open ? mdiChevronUp : mdiChevronDown}
									size={1}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<Menu
				id={id}
				MenuListProps={{
					'aria-labelledby': id,
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={handleClose}
			>
				{items.map((item) => (
					<MenuItem
						key={item.id || item.title}
						onClick={() => {
							onSelect(item);
							handleClose();
						}}
						disableRipple
					>
						{item.icon && <Icon path={item.icon} size={1} />}
						{item.title}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
