/* eslint-disable class-methods-use-this */
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from '../DateTime';

// Lib
import {
	CalendarEvent,
	EventParticipant,
	ConferenceProviderSetting,
	EventMapper,
} from '../types';
import { cleanHtml, parseAllUrls, matchProvider } from '../utils';

export default class GoogleCalendarEventMapper implements EventMapper {
	providers: ConferenceProviderSetting[];

	constructor(providers: ConferenceProviderSetting[]) {
		this.providers = providers;
		this.map = this.map.bind(this);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	map(event: any): CalendarEvent {
		const participants: EventParticipant[] =
			event.attendees && event.attendees.length > 0
				? event.attendees
						.filter((x: any) => !!x.email || !!x.displayName)
						.map((x: any) => ({
							id: uuidv4(),
							name: x.displayName,
							email: x.email,
							status: x.responseStatus,
						}))
				: [];

		const isAllDay = !event.start?.dateTime;

		// if (isAllDay) {
		//     console.log(`GL: ${event.summary}`);
		//     console.log(JSON.stringify(event, null, '\t'));
		//     console.log(`DT: ${new Date(event.start.date).toLocaleString()}`);
		// }

		const allDayDate = isAllDay
			? `${event.start?.date}T${
					new DateTime().toStartOfDay().toISOString().split('T')[1]
			  }`
			: new DateTime(event.start?.dateTime).toStartOfDay().toISOString();

		const allDayEndDate = isAllDay
			? `${event.end?.date}T${
					new DateTime().toEndOfDay().toISOString().split('T')[1]
			  }`
			: new DateTime(event.end?.dateTime).toEndOfDay().toISOString();

		// console.log(`GL: ${allDayDate} - ${allDayEndDate}`);

		const start = event.start?.dateTime
			? new Date(event.start?.dateTime)
			: new Date(allDayDate);
		const end = event.end?.dateTime
			? new Date(event.end?.dateTime)
			: new Date(allDayEndDate);
		// const start = event.start?.dateTime ? new Date(event.start?.dateTime) : new Date(allDayDate);
		// const end = event.end?.dateTime ? new Date(event.end?.dateTime) : new DateTime(allDayDate).toEndOfDay().toDate();

		// TODO: Deprecated
		const date = isAllDay
			? allDayDate
			: new DateTime(start).toStartOfDay().toISOString();

		const startTime = start ? start.getTime() : 0;
		const endTime = end ? end.getTime() : 0;

		const result: CalendarEvent = {
			id: `google_${event.id}_${
				isAllDay === true
			}_${startTime}_${endTime}`,
			name: event.summary,
			description: cleanHtml(event.description),
			location: event.location,
			date,
			startTime,
			endTime,
			duration:
				start && end
					? DateTime.getMinutesBetweenDates(start, end)
					: 1440,
			calendarUrl: event.htmlLink,
			isAllDay: !event.start?.dateTime,
			participants,
			provider: 'google',
			conferenceUrl: event.hangoutLink || '',
			conferenceName: event.hangoutLink ? 'Google' : 'Other',
			image: '',
			urls: [],
		};

		const urls = parseAllUrls([
			result.name,
			result.location,
			result.description,
			event.hangoutLink,
			...(event.conferenceData?.entryPoints?.map((p: any) => p.uri) ||
				[]),
		]);

		const provider = matchProvider(this.providers, urls);
		if (provider && provider.conferenceName) {
			result.conferenceName = provider.conferenceName;
			result.conferenceUrl = provider.conferenceUrl;
			result.urls = provider.urls;
			result.image = provider.image;
		}

		return result as CalendarEvent;
	}
}
