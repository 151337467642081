import { styled } from '@mui/material/styles';

export const ToolbarCaption = styled('div')(({ theme }) => ({
	position: 'relative',
	fontSize: theme.typography.body2.fontSize,
	fontWeight: 500,
	color: '#eb9034',
	ml: 1,
	flexGrow: 1,
}));
