import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

// Icons
import Icon from '@mdi/react';
import { mdiCalendarPlus, mdiChevronDown } from '@mdi/js';

// Components
import { useMeetingActions } from './useMeetingActions';

// Lib
import {
	useAppSelector,
	selectScheduleMeetingButtonIndex,
	setScheduledMeetingButtonIndex,
	useAppDispatch,
} from '../../store';
import { Meeting } from '../../lib/types';
import { useCalendarContext } from '../../contexts/CalendarContext';

const options = ['Schedule via Outlook.com', 'Schedule via Google'];

export type ScheduleMeetingButtonProps = {
	meeting?: Meeting;
};

export const ScheduleMeetingButton = (props: ScheduleMeetingButtonProps) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const actions = useMeetingActions();
	const { isGoogleConnected, isMicrosoftConnected } = useCalendarContext();

	const selectedIndex = useAppSelector(selectScheduleMeetingButtonIndex);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const { meeting } = props;

	const handleAction = (index: number | undefined) => {
		if (meeting && index === 0) {
			actions.openMicrosoftCalendarEvent(meeting);
		}
		if (meeting && index === 1) {
			actions.openGoogleCalendarEvent(meeting);
		}
	};

	const handleButtonClick = () => {
		if (selectedIndex) {
			setOpen(true);
		} else {
			handleAction(selectedIndex);
		}
	};

	const handleMenuItemClick = (_event: unknown, index: number) => {
		dispatch(setScheduledMeetingButtonIndex(index));
		setOpen(false);
		handleAction(index);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	let description = 'Schedule Meeting';
	if (!selectedIndex) {
		if (!isGoogleConnected && isMicrosoftConnected) {
			description = 'Schedule via Outlook.com';
		}
		if (isGoogleConnected && !isMicrosoftConnected) {
			description = 'Schedule via Google';
		}
	} else {
		description = options[selectedIndex];
	}

	return (
		<>
			<ButtonGroup
				variant="outlined"
				orientation="horizontal"
				color="secondary"
				ref={anchorRef}
				aria-label="Schedule Meeting"
				sx={{ mr: 1 }}
			>
				<Button
					variant="outlined"
					color="secondary"
					startIcon={<Icon path={mdiCalendarPlus} size={0.75} />}
					onClick={handleButtonClick}
					style={{ textTransform: 'none', minWidth: '190px' }}
				>
					{description}
				</Button>
				<Button
					color="secondary"
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<Icon path={mdiChevronDown} size={1} />
				</Button>
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				transition
				// onResize={undefined}
				// onResizeCapture={undefined}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}
					>
						<Paper
							elevation={0}
							sx={{
								border: `1px solid ${theme.palette.secondary.main}`,
								borderTop: 'none',
								borderTopRightRadius: 0,
								borderTopLeftRadius: 0,
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									id="split-button-menu"
									color="default"
								>
									{options.map((option, index) => (
										<MenuItem
											key={option}
											selected={index === selectedIndex}
											onClick={(event) =>
												handleMenuItemClick(
													event,
													index
												)
											}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
