import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { flatten, orderBy, uniqBy } from 'lodash';

// Lib
import { DateTime } from '../lib/DateTime';
import { CalendarEvent } from '../lib/types';
import type { RootState } from './index';

export type CalendarView = 'Grid' | 'List';

export interface UpdateCalendarEventsPayload {
	googleEvents?: CalendarEvent[];
	microsoftEvents?: CalendarEvent[];
}

export interface CalendarState {
	events: CalendarEvent[];
	selectedEventId: string | null;
	selectedDate: string;
	isGoogleConnected: boolean;
	isMicrosoftConnected: boolean;
	isReady: boolean;
	calendarView: CalendarView;
}

const initialState: CalendarState = {
	events: [],
	selectedEventId: null,
	selectedDate: new DateTime().toStartOfDay().toISOString(),
	isGoogleConnected: false,
	isMicrosoftConnected: false,
	isReady: false,
	calendarView: 'Grid',
};

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		setIsCalendarReady: (state, action: PayloadAction<boolean>) => {
			state.isReady = action.payload;
		},
		updateCalendarEvents: (
			state,
			action: PayloadAction<UpdateCalendarEventsPayload>
		) => {
			const { googleEvents, microsoftEvents } = action.payload;
			let events = [...state.events];

			if (googleEvents && googleEvents.length > 0) {
				events = [...events, ...googleEvents];
			}
			if (microsoftEvents && microsoftEvents.length > 0) {
				events = [...events, ...microsoftEvents];
			}

			const results = orderBy(
				uniqBy(events, (x) => x.id),
				['startTime'],
				'asc'
			);
			// console.log('CALENDAR STORE RESULTS', results.length);
			state.events = results;
		},
		setSelectedEvent: (state, action: PayloadAction<string | null>) => {
			state.selectedEventId = action.payload;
		},
		setSelectedDate: (state, action: PayloadAction<string>) => {
			state.selectedDate = action.payload;
		},
		clearMicrosoftState: (state) => {
			// console.log('CLEAR MICROSOFT STATE');
			state.events = state.events.filter(
				(x) => x.provider !== 'microsoft'
			);
			state.selectedEventId = null;
		},
		clearGoogleState: (state) => {
			// console.log('CLEAR GOOGLE STATE');
			state.events = state.events.filter((x) => x.provider !== 'google');
			state.selectedEventId = null;
		},
		clearCalendarState: (state) => {
			state.selectedEventId = null;
			state.selectedDate = new DateTime().toStartOfDay().toISOString();
			state.events = [];
		},
		setGoogleConnected: (state, action: PayloadAction<boolean>) => {
			state.isGoogleConnected = action.payload;
			if (!state.isGoogleConnected) {
				state.events = state.events.filter(
					(x) => x.provider !== 'google'
				);
			}
		},
		setMicrosoftConnected: (state, action: PayloadAction<boolean>) => {
			state.isMicrosoftConnected = action.payload;
			if (!state.isMicrosoftConnected) {
				state.events = state.events.filter(
					(x) => x.provider !== 'microsoft'
				);
			}
		},
		setCalendarView: (state, action: PayloadAction<CalendarView>) => {
			state.calendarView = action.payload;
		},
	},
});

export const {
	setIsCalendarReady,
	updateCalendarEvents,
	setSelectedEvent,
	clearCalendarState,
	clearGoogleState,
	clearMicrosoftState,
	setSelectedDate,
	setGoogleConnected,
	setMicrosoftConnected,
	setCalendarView,
} = calendarSlice.actions;

export const selectIsCalendarReady = (state: RootState) =>
	state.calendar.isReady;

export const selectAllCalendarEvents = (state: RootState) =>
	state.calendar.events;

export const selectSelectedCalendarDate = (state: RootState) =>
	state.calendar.selectedDate;

export const selectSelectedCalendarEvent = (state: RootState) =>
	flatten(Object.values(state.calendar.events)).find(
		(x) => x.id === state.calendar.selectedEventId
	);

export const selectIsGoogleConnected = (state: RootState) =>
	state.calendar.isGoogleConnected;

export const selectIsMicrosoftConnected = (state: RootState) =>
	state.calendar.isMicrosoftConnected;

export const selectCalendarView = (state: RootState) =>
	state.calendar.calendarView;

export default calendarSlice.reducer;
