/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from '../DateTime';

// Lib
import {
	CalendarEvent,
	EventParticipant,
	ConferenceProviderSetting,
	EventMapper,
} from '../types';
import { cleanHtml, parseAllUrls, matchProvider } from '../utils';

const getDateTime = (value?: string) => {
	if (!value) {
		return null;
	}
	return new Date(`${value.split('.')[0]}Z`);
};

const getAllDayDateTime = (value: string | undefined, endOfDay: boolean) => {
	if (!value) {
		return null;
	}
	const first = new Date(value).toISOString();
	const startTime = new DateTime().toStartOfDay().toISOString().split('T')[1];
	const endTime = new DateTime().toEndOfDay().toISOString().split('T')[1];
	const time = endOfDay ? endTime : startTime;
	return new Date(`${first.split('T')[0]}T${time}`);
};

export default class MicrosoftCalendarEventMapper implements EventMapper {
	providers: ConferenceProviderSetting[];

	constructor(providers: ConferenceProviderSetting[]) {
		this.providers = providers;
		this.map = this.map.bind(this);
	}

	map(event: any): CalendarEvent {
		const participants: EventParticipant[] =
			event.attendees && event.attendees.length > 0
				? event.attendees
						.filter(
							(x: any) =>
								!!x.emailAddress &&
								(!!x.emailAddress?.address ||
									!!x.emailAddress?.name)
						)
						.map((x: any) => ({
							id: uuidv4(),
							name: x.emailAddress.name,
							email: x.emailAddress.address,
							status: x.status?.response?.toLowerCase(),
						}))
				: [];

		const startOfToday = new DateTime().toStartOfDay().toDate();
		const endOfToday = new DateTime().toEndOfDay().toDate();

		const start =
			(event.isAllDay
				? getAllDayDateTime(event.start?.dateTime, false)
				: getDateTime(event.start?.dateTime)) || startOfToday;
		const end =
			(event.isAllDay
				? getAllDayDateTime(event.end?.dateTime, true)
				: getDateTime(event.end?.dateTime)) || endOfToday;

		// TODO: Deprecated
		const date = new DateTime(start).toStartOfDay().toISOString();

		// isAllDay = isAllDayEvent || spansWholeDay
		// renderStartTime = spansWholeDay ? 00:00 : event.start
		// renderEndTime = spansWholeDay ? 23:59 : event.end

		const startTime = start ? start.getTime() : -1;
		const endTime = end ? end.getTime() : -1;

		const { id } = event;

		const result: CalendarEvent = {
			id: `microsoft_${id}_${
				event.isAllDay === true
			}_${startTime}_${endTime}`,
			name: event.subject,
			description:
				event.body && event.body.content
					? event.body.contentType === 'html'
						? cleanHtml(event.body.content)
						: event.bodyPreview
					: event.bodyPreview,
			location: event.location?.displayName,

			isAllDay: event.isAllDay === true,
			date,
			startTime: start ? start.getTime() : 0,
			endTime: end ? end.getTime() : 0,
			// duration: start && end ? getDuration(start, end) : 24,
			duration:
				start && end ? DateTime.getMinutesBetweenDates(start, end) : 24,
			calendarUrl: event.webLink,

			participants,
			provider: 'microsoft',
			conferenceUrl: event.onlineMeetingUrl || '',
			conferenceName: event.onlineMeetingUrl ? 'Microsoft' : 'Other',
			image: '',
			urls: [],
		};

		const urls = parseAllUrls([
			result.name,
			result.location,
			result.description,
			event.onlineMeetingUrl,
			...(event.locations?.map((x: any) => x.displayName) || []),
		]);

		const provider = matchProvider(this.providers, urls);
		if (provider && provider.conferenceName) {
			result.conferenceName = provider.conferenceName;
			result.conferenceUrl = provider.conferenceUrl;
			result.urls = provider.urls;
			result.image = provider.image;
		}

		return result as CalendarEvent;
	}
}
