import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import type { RootState } from '.';
import { Meeting } from '../lib/types';
import { orderBy, uniqBy } from 'lodash';

export interface State {
	meetings: Meeting[];
	selected?: number;
	personalMeeting: Meeting;
	enableLobby: boolean;
	scheduledMeetingButtonIndex?: number;
	isReady: boolean;
}

const initialState: State = {
	meetings: [],
	personalMeeting: {
		id: -1,
		dt: new Date().toISOString(),
		favorite: false,
		pin: '',
		room: '',
		topic: '',
		type: 'Personal',
		url: '',
		server: '',
	},
	enableLobby: false,
	isReady: false,
};

export const slice = createSlice({
	name: 'meetings',
	initialState,
	reducers: {
		setIsMeetingsReady: (state, action: PayloadAction<boolean>) => {
			state.isReady = action.payload;
		},
		setMeetings: (state, action: PayloadAction<Meeting[]>) => {
			const items = uniqBy(
				[...state.meetings, ...action.payload],
				(x) => x.id
			);
			state.meetings = orderBy(items, ['dt'], ['desc']);
			state.isReady = true;
		},
		clearMeetings: (state) => {
			state.meetings = [];
		},
		updateMeeting: (state, action: PayloadAction<Meeting>) => {
			const index = state.meetings.findIndex(
				(x) => x.id === action.payload.id
			);
			if (index !== -1) {
				state.meetings.splice(index, 1, {
					...state.meetings[index],
					...action.payload,
				});
			}
		},
		setSelectedMeetingId: (
			state,
			action: PayloadAction<number | undefined>
		) => {
			state.selected = action.payload;
		},
		setPersonalMeeting: (state, action: PayloadAction<Meeting>) => {
			state.personalMeeting = action.payload;
		},
		setLobbyEnabled: (state, action: PayloadAction<boolean>) => {
			state.enableLobby = action.payload;
		},
		setScheduledMeetingButtonIndex: (
			state,
			action: PayloadAction<number | undefined>
		) => {
			state.scheduledMeetingButtonIndex = action.payload;
		},
	},
});

export const {
	setIsMeetingsReady,
	setMeetings,
	updateMeeting,
	clearMeetings,
	setSelectedMeetingId,
	setPersonalMeeting,
	setLobbyEnabled,
	setScheduledMeetingButtonIndex,
} = slice.actions;

export const selectMeetings = (state: RootState) => state.meetings.meetings;

export const selectIsMeetingsReady = (state: RootState) =>
	state.meetings.isReady;

export const selectSelectedMeetingId = (state: RootState) =>
	state.meetings.selected;

export const selectPersonalMeeting = (state: RootState) =>
	state.meetings.personalMeeting;

export const selectLobbyEnabled = (state: RootState) =>
	state.meetings.enableLobby;

export const selectScheduleMeetingButtonIndex = (state: RootState) =>
	state.meetings.scheduledMeetingButtonIndex;

export default slice.reducer;
