import * as React from 'react';
import { useAppTheme } from '../../contexts/ThemeContext';
import { DarkModeGutter } from '../../lib/Colors';

// Lib
import { DayView } from './DayView';
import { AllDayEvents } from './AllDayEvents';
import { CalendarHeader } from './CalendarHeader';
import { useCalendarContext } from '../../contexts/CalendarContext';

export const CalendarView = () => {
	const { isDarkMode } = useAppTheme();
	const { onRefresh } = useCalendarContext();

	const refresh = React.useCallback(() => {
		onRefresh();
	}, [onRefresh]);

	React.useEffect(() => {
		refresh();
		const interval = setInterval(refresh, 60000);
		return () => clearInterval(interval);
	}, [refresh]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				backgroundColor: '#ffffff',
			}}
		>
			<CalendarHeader />
			<AllDayEvents />
			<div
				data-component="day-view"
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					position: 'relative',
					height: '100%',
					width: '100%',
					overflowY: 'auto',
					// paddingTop: '16px',
					// paddingBottom: '64px',
					backgroundColor: isDarkMode ? DarkModeGutter : '#f6f6f6',
				}}
			>
				<DayView />
			</div>
		</div>
	);
};
