import * as React from 'react';
import { Meeting } from '../lib/types';
import { useAppDispatch } from '../store';
import { setMeetings, updateMeeting } from '../store/meetings';

// Lib
import { useAppContext } from './AppContext';

export interface MeetingsContextValue {
	onRefresh: () => void;
	refreshing: boolean;
	toggleMeetingFavorite: (meeting: Meeting) => void;
}

const initialPreferences: MeetingsContextValue = {
	toggleMeetingFavorite: () => {},
	onRefresh: () => {},
	refreshing: false,
};

export const MeetingsContext =
	React.createContext<MeetingsContextValue>(initialPreferences);

export interface Props {
	children: React.ReactNode;
}

export const MeetingsContextProvider = ({ children }: Props) => {
	const { api, user, isOnline } = useAppContext();
	const dispatch = useAppDispatch();
	const [refreshing, setRefreshing] = React.useState(false);
	const userSub = user?.sub;

	const toggleMeetingFavorite = React.useCallback(
		async (meeting: Meeting) => {
			if (userSub) {
				const updated = {
					...meeting,
					favorite: meeting.favorite === true ? false : true,
				};

				try {
					if (updated.favorite === true) {
						await api.user.addMeetingFavorite(userSub, updated.url);
					} else {
						await api.user.removeMeetingFavorite(
							userSub,
							updated.url
						);
					}
					dispatch(updateMeeting(updated));
				} catch (error) {
					console.error(error);
				}
			}
		},
		[api.user, dispatch, userSub]
	);

	const refresh = React.useCallback(
		async (authId: string | undefined) => {
			if (authId && isOnline) {
				try {
					const results = await api.user.getMeetings(authId);
					dispatch(setMeetings(results));
				} catch (error) {
					console.error(error);
				}
			}
		},
		[dispatch, api.user, isOnline]
	);

	const onRefresh = React.useCallback(async () => {
		if (userSub) {
			setRefreshing(true);
			try {
				await refresh(userSub);
			} catch (error) {
				console.error(error);
			} finally {
				setRefreshing(false);
			}
		}
	}, [refresh, userSub]);

	React.useEffect(() => {
		if (userSub) {
			refresh(userSub);
			const interval = setInterval(() => {
				refresh(userSub);
			}, 60000);
			return () => clearInterval(interval);
		} else {
			dispatch(setMeetings([]));
		}
	}, [dispatch, userSub, refresh]);

	const value: MeetingsContextValue = {
		toggleMeetingFavorite,
		onRefresh,
		refreshing,
	};

	return (
		<MeetingsContext.Provider value={value}>
			{children}
		</MeetingsContext.Provider>
	);
};

// TODO: Not Used
export const useMeetingsContext = (): MeetingsContextValue => {
	return React.useContext(MeetingsContext);
};
