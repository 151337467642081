import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type Props = {
	title: string;
	text: string;
	onClose: () => void;
	onContinue: () => void;
};

export const ConfirmationDialog = ({
	title,
	text,
	onClose,
	onContinue,
}: Props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog
			fullScreen={fullScreen}
			open
			onClose={onClose}
			aria-labelledby="confirmation-dialog-title"
		>
			<DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={onContinue} autoFocus>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
};
