import React, { useContext, useCallback } from 'react';
import { useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import useMediaQuery from '@mui/material/useMediaQuery';
import UAParser from 'ua-parser-js';

// Lib
import pkg from '../../package.json';
import { api } from '../api';
import awsconfig from '../aws-exports';

import { ApplicationContextData } from '../lib';
import { useAppDispatch, useAppSelector } from '../store';
import {
	selectCurrentUser,
	selectPrivateDevice,
	setUserLoggedIn,
	setUserLoggedOut,
} from '../store/user';
import { ApplicationEvent } from '../lib/ApplicationEvent';
import { logoutIFrame } from '../lib/connect';
import { UserDeviceArchitecture } from '../lib/common';

Amplify.configure(awsconfig);

const initialValue: ApplicationContextData = {
	api,
	user: null,
	isOnline: true,
	version: pkg.version,
	signout: () => {},
	signin: () => {},
	isWide: false,
	ucpWindowRef: null,
	setUcpWindowRef: () => {},
};

type AsyncResponse = () => Promise<{
	username?: string;
	attributes?: { sub?: string };
}>;

export const ApplicationContext =
	React.createContext<ApplicationContextData>(initialValue);

export interface AppContextProps {
	children: React.ReactNode;
}

const captureUnload = async (event: BeforeUnloadEvent) => {
	logoutIFrame();
	ApplicationEvent.Send('disconnect-calendars', {});
	Auth.signOut();

	event.preventDefault();
	return (event.returnValue = '');
};

export const AppContext = (props: AppContextProps) => {
	const { children } = props;
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);
	const privateDevice = useAppSelector(selectPrivateDevice);
	const [isOnline, setIsOnline] = React.useState(true);
	const isWide = useMediaQuery('(min-width:885px)');
	const [ucpWindowRef, setUcpWindowRef] = React.useState<Window | null>(null);

	const getUser = useCallback(
		async (method: AsyncResponse) => {
			let username: string;
			let authId: string;
			try {
				const response = await method();
				// console.log('User Response', response);
				if (
					response &&
					response.username &&
					response.attributes &&
					response.attributes.sub
				) {
					const res = await Auth.currentSession();
					const token = await res.getIdToken();
					const groups = token.payload['cognito:groups'] || [];

					username = response.username;
					authId = response.attributes.sub;
					console.log('User', response);
					dispatch(
						setUserLoggedIn({ sub: authId, username, groups })
					);
				}
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (user) {
			const parser = new UAParser();
			const data = parser.getResult();
			try {
				api.user.addDeviceUsage(user.sub, {
					auth_id: user.sub,
					version: pkg.version,
					platform: 'web',
					channel: window.location.href.includes(':3000')
						? 'alpha'
						: window.location.href.includes('beta.')
						? 'beta'
						: 'production',
					arch:
						(data.cpu.architecture as UserDeviceArchitecture) ||
						'unknown',
					ua: data.ua,
					data,
				});
			} catch (error) {
				console.error(error);
				// Do nothing
			}
		}
	}, [user]);

	useEffect(() => {
		getUser(() => Auth.currentAuthenticatedUser()).catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signin = async (userId: string, password: string) => {
		const uname = userId?.toLowerCase().trim();
		if (!uname) throw new Error('Invalid username');
		try {
			await getUser(() => Auth.signIn(uname, password));
		} catch (error) {
			throw new Error('Invalid username or password');
		}
	};

	const signout = React.useCallback(async () => {
		if (privateDevice !== true) {
			ApplicationEvent.Send('logout-connect', {});
			ApplicationEvent.Send('disconnect-calendars', {});
		}
		await Auth.signOut();
		dispatch(setUserLoggedOut());
	}, [dispatch, privateDevice]);

	const updateOnlineStatus = useCallback(() => {
		if (window && navigator) {
			setIsOnline(navigator.onLine === true);
		}
	}, []);

	useEffect(() => {
		if (window && navigator) {
			window.addEventListener('online', updateOnlineStatus);
			return () =>
				window.removeEventListener('online', updateOnlineStatus);
		}
	}, [updateOnlineStatus]);

	useEffect(() => {
		if (window && navigator) {
			window.addEventListener('offline', updateOnlineStatus);
			return () =>
				window.removeEventListener('offline', updateOnlineStatus);
		}
	}, [updateOnlineStatus]);

	useEffect(() => {
		if (user && privateDevice !== true) {
			console.warn('User is on a public device');
			window.addEventListener('beforeunload', captureUnload);
			return () => {
				window.removeEventListener('beforeunload', captureUnload);
			};
		}
	}, [user, privateDevice]);

	return (
		<ApplicationContext.Provider
			value={{
				api,
				user,
				signout,
				signin,
				isOnline,
				version: pkg.version,
				isWide,
				ucpWindowRef,
				setUcpWindowRef,
			}}
		>
			{children}
		</ApplicationContext.Provider>
	);
};

export const useAppContext = (): ApplicationContextData => {
	return useContext(ApplicationContext);
};
