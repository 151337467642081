import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Components
import { NoCalendars } from './calendar/NoCalendars';
import { CalendarEventDetail } from './calendar/CalendarEventDetail';
import { useCalendarContext } from '../contexts/CalendarContext';
import { useAppContext } from '../contexts/AppContext';
import { selectSelectedCalendarEvent, useAppSelector } from '../store';
import { Loading } from './common/Loading';
import { CalendarView } from './calendar/CalendarView';

export const Calendar = () => {
	const theme = useTheme();
	const { isWide } = useAppContext();
	const selectedCalendarEventId = useAppSelector(selectSelectedCalendarEvent);

	const { isGoogleConnected, isMicrosoftConnected, isCalendarReady } =
		useCalendarContext();
	const hasCalendars = isGoogleConnected || isMicrosoftConnected;

	if (!isCalendarReady) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					position: 'relative',
					height: '100%',
					backgroundColor: '#fff',
					borderRadius: theme.spacing(1),
				}}
			>
				<Loading />
			</Box>
		);
	}

	if (!hasCalendars) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					position: 'relative',
					height: '100%',
					backgroundColor: '#fff',
					borderRadius: theme.spacing(1),
				}}
			>
				<NoCalendars />
			</Box>
		);
	}

	if (!isWide && selectedCalendarEventId) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					position: 'relative',
					height: '100%',
					backgroundColor: '#fff',
					borderRadius: theme.spacing(1),
				}}
			>
				<CalendarEventDetail showBackButton={true} />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				position: 'relative',
				height: '100%',
				backgroundColor: '#fff',
				borderRadius: theme.spacing(1),
			}}
		>
			<Grid
				container
				direction="row"
				wrap="nowrap"
				style={{ flexGrow: 1, height: '100%' }}
			>
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						borderRadius: 1,
						flexGrow: 1,
						width: '40%',
					}}
				>
					<CalendarView />
				</Grid>
				{isWide && (
					<Grid
						item
						sx={{
							position: 'relative',
							height: '100%',
							width: '8px',
							backgroundColor: '#f6f6f6',
							display: 'flex',
						}}
					>
						&nbsp;
					</Grid>
				)}
				{isWide && (
					<Grid
						item
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							position: 'relative',
							borderRadius: theme.spacing(1),
							width: '60%',
						}}
					>
						<CalendarEventDetail showBackButton={false} />
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
