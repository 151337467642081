import React from 'react';
import AccentCloudPhone from '../components/AccentCloudPhone';

export const SnackContext = React.createContext<null>(null);

export interface SnackContextProps {
	children: React.ReactNode;
}

export const JustAddAWebPageItsEasyProvider = (props: SnackContextProps) => {
	const { children } = props;

    if (global?.window?.location?.pathname === '/accentcloudphone') {
        return <AccentCloudPhone />
    }

	return (
        <>
            {children}
        </>
    );
};
