import * as React from 'react';
import { createMeetingInfo } from '../lib/createMeetingInfo';
import { defaultJitsiServer, UserConfiguration } from '../lib/types';
import { getPersonalMeetingRoom } from '../lib/utils';
import {
	selectPersonalMeeting,
	setPersonalMeeting,
	useAppDispatch,
	useAppSelector,
} from '../store';

// Lib
import { useAppContext } from './AppContext';

export const PersonalMeetingStorageKey = 'personal-meeting';

export interface UserProfileContextValue {
	userConfiguration: UserConfiguration | undefined;
	isReady: boolean;
}

const initialValue: UserProfileContextValue = {
	userConfiguration: undefined,
	isReady: false,
};

export const UserProfileContext =
	React.createContext<UserProfileContextValue>(initialValue);

export interface Props {
	children: React.ReactNode;
}

export const UserProfileContextProvider = ({ children }: Props) => {
	const { api, user, isOnline } = useAppContext();
	const dispatch = useAppDispatch();
	const personalMeeting = useAppSelector(selectPersonalMeeting);
	const [userConfiguration, setUserConfiguration] =
		React.useState<UserConfiguration>();
	const [isReady, setIsReady] = React.useState(false);

	const userSub = user?.sub;

	const loadUserData = React.useCallback(
		async (authId: string | undefined) => {
			if (authId && isOnline) {
				try {
					const results = await api.user.getUserConfiguration(authId);
					setUserConfiguration({ ...results, authId });
				} catch (err) {
					console.error(err);
				}
			}
		},
		[api.user, isOnline]
	);

	const createPersonalMeeting = React.useCallback(
		async (config: UserConfiguration) => {
			if (userSub) {
				try {
					const videoServer =
						config.videoServer || defaultJitsiServer;
					const room = getPersonalMeetingRoom(
						config.tenant,
						config.ext
					);
					const meeting = await createMeetingInfo(
						userSub,
						videoServer,
						room
					);
					dispatch(setPersonalMeeting(meeting));
				} catch (err) {
					console.error(err);
				}
			}
		},
		[dispatch, userSub]
	);

	React.useEffect(() => {
		if (userSub) {
			loadUserData(userSub);
		} else {
			setUserConfiguration(undefined);
		}
	}, [userSub, loadUserData]);

	React.useEffect(() => {
		if (userConfiguration && isOnline) {
			createPersonalMeeting(userConfiguration);
		}
	}, [userConfiguration, createPersonalMeeting, isOnline]);

	React.useEffect(() => {
		if (userSub && userConfiguration && personalMeeting) {
			setIsReady(true);
		} else {
			setIsReady(false);
		}
	}, [userConfiguration, personalMeeting, userSub]);

	const value: UserProfileContextValue = {
		userConfiguration,
		isReady,
	};

	return (
		<UserProfileContext.Provider value={value}>
			{children}
		</UserProfileContext.Provider>
	);
};

export const useUserProfileContext = (): UserProfileContextValue => {
	return React.useContext(UserProfileContext);
};
