/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import DOMPurify from 'dompurify';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import {
	mdiClockOutline,
	mdiAccountMultipleOutline,
	mdiMapMarker,
	mdiAccount,
	mdiLinkVariant,
	mdiChevronLeft,
	mdiClockTimeTwelveOutline,
	mdiCalendarStart,
	mdiCalendarEnd,
} from '@mdi/js';

// Components
import { NothingHere } from '../common/NothingHere';
import { ToolbarCaption } from '../common/ToolbarCaption';

// Lib
import {
	useAppDispatch,
	useAppSelector,
	selectSelectedCalendarEvent,
	setSelectedEvent,
} from '../../store';
import { truncate } from '../../lib/utils';
import { useLinkingContext } from '../../contexts/LinkingContext';
import { useConferenceContext } from '../../contexts/ConferenceContext';
import { DateTime } from '../../lib/DateTime';
import { Colors } from '../../lib/Colors';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
	// set all elements owning target to target=_blank
	if ('target' in node) {
		node.setAttribute('target', '_blank');
		// prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
		node.setAttribute('rel', '_blank noreferrer');
	}
	// set non-HTML/MathML links to xlink:show=new
	if (
		!node.hasAttribute('target') &&
		(node.hasAttribute('xlink:href') || node.hasAttribute('href'))
	) {
		node.setAttribute('xlink:show', 'new');
	}
});

type Props = {
	showBackButton: boolean;
};

export const CalendarEventDetail = ({ showBackButton }: Props) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const event = useAppSelector(selectSelectedCalendarEvent);
	const { openUrl } = useLinkingContext();
	const { meetingInProgress } = useConferenceContext();

	if (!event) return null;

	const handleOpenCalendar = () => {
		if (event && event.calendarUrl) {
			window.open(event.calendarUrl);
		}
	};

	const handleCloseDetails = () => {
		dispatch(setSelectedEvent(null));
	};

	const handleOpenLink = (url: string | null | undefined) => {
		if (!url) return;
		openUrl(url) ||
			window.open(encodeURI(`https://www.google.com/search?q=${url}`));
	};

	const start = DateTime.fromEPOCHMilliseconds(
		event.startTime
	).toFormattedShortDateTimeMonthFirst();
	const end = DateTime.fromEPOCHMilliseconds(
		event.endTime
	).toFormattedShortDateTimeMonthFirst();
	const isMultiDay =
		DateTime.getMinutesBetweenDates(
			new Date(event.startTime),
			new Date(event.endTime)
		) >
		24 * 60;

	const urls = event?.conferenceUrl
		? event?.urls?.filter((x) => x !== event.conferenceUrl) || []
		: event?.urls || [];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				backgroundColor: '#ffffff',
			}}
		>
			<Toolbar
				variant="dense"
				sx={{
					borderBottom: '1px solid rgba(0,0,0,0.1)',
					pl: 1,
				}}
			>
				{showBackButton && (
					<Tooltip title="Close Details">
						<IconButton
							size="small"
							edge="start"
							aria-label="Close Details"
							onClick={handleCloseDetails}
						>
							<Icon path={mdiChevronLeft} size={1} />
						</IconButton>
					</Tooltip>
				)}
				<ToolbarCaption>Event Details</ToolbarCaption>
			</Toolbar>
			{!event && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						position: 'relative',
						height: '100%',
						overflow: 'hidden',
						backgroundColor: '#ffffff',
					}}
				>
					<NothingHere text="Select an event to view more details" />
				</Box>
			)}
			{!!event && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						position: 'relative',
						height: '100%',
						overflowY: 'auto',
						padding: theme.spacing(2),
					}}
				>
					<h3 style={{ padding: theme.spacing(0, 2) }}>
						{event.name}
					</h3>
					<List sx={{ paddingBottom: theme.spacing(4) }}>
						{(isMultiDay || event.isAllDay) && (
							<ListItem>
								<ListItemIcon>
									<Icon
										path={
											isMultiDay
												? mdiClockTimeTwelveOutline
												: mdiClockOutline
										}
										size={1}
									/>
								</ListItemIcon>
								<ListItemText
									primary={
										isMultiDay ? 'Multi-Day' : 'All Day'
									}
								/>
							</ListItem>
						)}
						<ListItem>
							<ListItemIcon>
								<Icon path={mdiCalendarStart} size={1} />
							</ListItemIcon>
							<ListItemText primary={start} />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<Icon path={mdiCalendarEnd} size={1} />
							</ListItemIcon>
							<ListItemText primary={end} />
						</ListItem>
						{event.participants.length > 0 && (
							<ListItem>
								<ListItemIcon>
									<Icon
										path={mdiAccountMultipleOutline}
										size={1}
									/>
								</ListItemIcon>
								<Box
									sx={{
										display: 'flex',
										flexFlow: 'row wrap',
										padding: theme.spacing(1),
										marginBottom: theme.spacing(0.5),
										backgroundColor:
											'rgba(255,255,255,0.5)',
										borderRadius: theme.spacing(1),
										width: '100%',
									}}
								>
									{event.participants.map((item) => (
										<Tooltip
											title={item.status || 'Unknown'}
											key={item.id}
										>
											<Chip
												avatar={
													<Icon
														path={mdiAccount}
														size={0.75}
													/>
												}
												label={item.name || item.email}
												onClick={() => {}}
												sx={{
													margin: theme.spacing(1),
													backgroundColor:
														item.status ===
														'accepted'
															? '#ea9b0a'
															: 'inherit',
													color:
														item.status ===
														'accepted'
															? 'white'
															: 'inherit',
													'& .MuiChip-avatar': {
														color:
															item.status ===
															'accepted'
																? 'white'
																: 'inherit',
													},
													'&.MuiChip-clickable:hover':
														{
															backgroundColor:
																item.status ===
																'accepted'
																	? '#ea9b0a'
																	: 'inherit',
															color:
																item.status ===
																'accepted'
																	? 'white'
																	: 'inherit',
														},
													'&.MuiChip-clickable:focus':
														{
															backgroundColor:
																item.status ===
																'accepted'
																	? '#ea9b0a'
																	: 'inherit',
															color:
																item.status ===
																'accepted'
																	? 'white'
																	: 'inherit',
														},
												}}
											/>
										</Tooltip>
									))}
								</Box>
							</ListItem>
						)}
						{!!event.location && (
							<ListItem
								button
								onClick={() => handleOpenLink(event.location)}
							>
								<ListItemIcon>
									<Icon path={mdiMapMarker} size={1} />
								</ListItemIcon>
								<ListItemText
									primary="Location"
									secondary={event.location}
								/>
							</ListItem>
						)}
						{!!event.conferenceUrl && (
							<ListItem
								button
								disabled={meetingInProgress}
								onClick={() =>
									handleOpenLink(event.conferenceUrl)
								}
							>
								<ListItemIcon>
									<Icon path={mdiLinkVariant} size={1} />
								</ListItemIcon>
								<ListItemText
									primary={event.conferenceUrl}
									secondary="Conference Link"
									style={{
										textDecoration: 'underline',
										color: 'blue',
									}}
								/>
							</ListItem>
						)}
						{event && event.calendarUrl && (
							<ListItem button onClick={handleOpenCalendar}>
								<ListItemIcon>
									<Icon path={mdiLinkVariant} size={1} />
								</ListItemIcon>
								<ListItemText
									primary="Calendar Link"
									secondary={`Open this event in ${
										event.provider === 'google'
											? 'Google'
											: 'Microsoft'
									}`}
									style={{
										textDecoration: 'underline',
										color: Colors.blue700,
									}}
								/>
							</ListItem>
						)}
					</List>
					<span style={{ paddingBottom: theme.spacing(4) }} />
					{!!event.description && (
						<>
							<Toolbar
								variant="dense"
								sx={{
									borderBottom: '1px solid rgba(0,0,0,0.1)',
									pl: 1,
								}}
							>
								<ToolbarCaption>
									Event Description
								</ToolbarCaption>
							</Toolbar>
							<Box
								sx={{ padding: theme.spacing(3) }}
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(
										event.description
									),
								}}
							/>
						</>
					)}
					{urls.length > 0 && (
						<>
							<Toolbar
								variant="dense"
								sx={{
									borderBottom: '1px solid rgba(0,0,0,0.1)',
									pl: 1,
								}}
							>
								<ToolbarCaption>Other Links</ToolbarCaption>
							</Toolbar>
							<List dense>
								{urls.map((url) => (
									<ListItem
										key={url}
										button
										onClick={() => handleOpenLink(url)}
									>
										<ListItemIcon
											style={{ minWidth: '34px' }}
										>
											<Icon
												path={mdiLinkVariant}
												size={1}
											/>
										</ListItemIcon>
										<ListItemText
											primary={truncate(url, 50)}
											style={{
												textDecoration: 'underline',
												color: 'blue',
											}}
										/>
									</ListItem>
								))}
							</List>
						</>
					)}
				</Box>
			)}
		</Box>
	);
};
