import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

// Icons
import Icon from '@mdi/react';
import { mdiGoogle, mdiMicrosoft, mdiPlusCircleOutline } from '@mdi/js';

// Lib
import { useCalendarContext } from '../../contexts/CalendarContext';
import { Colors } from '../../lib/Colors';
import { useTheme } from '@mui/material';

type Props = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onClick: (provider: string) => void;
};

export const AddEventMenu = ({ open, onClick, onOpenChange }: Props) => {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] =
		React.useState<HTMLButtonElement | null>(null);
	const { isGoogleConnected, isMicrosoftConnected } = useCalendarContext();
	const hasCalendarsConnected = isGoogleConnected || isMicrosoftConnected;

	const handleClick = (provider: string) => {
		setAnchorEl(null);
		onClick(provider);
	};

	React.useEffect(() => {
		onOpenChange(anchorEl !== null);
	}, [anchorEl, onOpenChange]);

	if (!hasCalendarsConnected) {
		return null;
	}

	return (
		<div>
			<Tooltip title="Add Event">
				<Button
					aria-label="Add"
					aria-controls="fab-menu"
					aria-haspopup="true"
					onClick={(e) => setAnchorEl(e.currentTarget)}
					size="small"
					variant="text"
					style={{
						marginLeft: 6,
						textTransform: 'none',
						fontSize: theme.typography.body2.fontSize,
						fontWeight: 500,
						color: Colors.blue800,
					}}
				>
					<Icon
						path={mdiPlusCircleOutline}
						size={1}
						style={{ marginRight: 4 }}
					/>
					Add Event
				</Button>
			</Tooltip>
			<Menu
				id="fab-menu"
				open={anchorEl !== null && open}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
				keepMounted
				elevation={3}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				{!!isGoogleConnected && (
					<MenuItem onClick={() => handleClick('google')}>
						<ListItemIcon>
							<Icon path={mdiGoogle} size={1} />
						</ListItemIcon>
						<ListItemText>Google Event</ListItemText>
					</MenuItem>
				)}
				{!!isMicrosoftConnected && (
					<MenuItem onClick={() => handleClick('google')}>
						<ListItemIcon>
							<Icon path={mdiMicrosoft} size={1} />
						</ListItemIcon>
						<ListItemText>Microsoft Event</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};
