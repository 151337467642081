/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiClientInterface } from './client';

export default class CommonApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async healthCheck(): Promise<any> {
		return this.client.get('/');
	}

	public async gatewayTest(): Promise<any> {
		return this.client.get('/gateway');
	}

	public async authUser(): Promise<any> {
		return this.client.get('/auth/user');
	}

	public async signJwt(seed: string, payload: any): Promise<any> {
		const response = await this.client.post<
			{ seed: string; payload: any },
			{ token: string }
		>('/sign', { seed, payload });
		return response?.result;
	}

	public async throw(status: number): Promise<any> {
		return this.client.get(`/error/${status}`);
	}
}
