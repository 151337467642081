import React, { useContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange, blue } from '@mui/material/colors';

const defaultTheme = createTheme({
	palette: {
		primary: orange,
		secondary: blue,
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				rounded: true,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
			},
		},
		MuiToolbar: {
			defaultProps: {
				variant: 'dense',
			},
			styleOverrides: {
				root: {
					minHeight: '48px',
				},
				dense: {
					minHeight: '48px',
				},
			},
		},
	},
});

export interface ThemeContextData {
	isDarkMode: boolean;
	toggleDarkMode: () => void;
}

const initialContextState: ThemeContextData = {
	isDarkMode: false,
	toggleDarkMode: () => {},
};

export const ThemeContext =
	React.createContext<ThemeContextData>(initialContextState);

export interface Props {
	children: React.ReactNode;
}

export const ThemeContextProvider = (props: Props) => {
	const { children } = props;
	const [isDarkMode, setIsDarkMode] = useState(false);

	const toggleDarkMode = () => {
		setIsDarkMode((s) => !s);
	};

	return (
		<ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
			<ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useAppTheme = (): ThemeContextData => {
	return useContext(ThemeContext);
};
