import { useState, useEffect } from 'react';
import { DateTime } from '../../lib/DateTime';
import { CalendarEvent } from '../../lib/types';
import { useAppSelector } from '../../store';
import { selectAllCalendarEvents } from '../../store/calendar';

const getSelectedEvents = (
	date: string,
	events: CalendarEvent[]
): CalendarEvent[] => {
	// console.log(`getSelectedEvents ${date}`);
	const start = new DateTime(date).toStartOfDay().toDate().getTime();
	const end = new DateTime(date).toEndOfDay().toDate().getTime();
	return events.filter(
		(x) =>
			x.isAllDay !== true &&
			// Regular Events
			((x.startTime > start && x.endTime < end) ||
				// Multi-day events that start on selected date
				(x.startTime > start && x.startTime < end && x.endTime > end) ||
				// Multi-day events that end on selected date
				(x.startTime < start && x.endTime > start && x.endTime < end))
	);
};

export default function useEvents(selectedDate: string) {
	const [events, setEvents] = useState<CalendarEvent[]>([]);
	const allEvents = useAppSelector(selectAllCalendarEvents);

	useEffect(() => {
		// console.log(`useEvents ${selectedDate}`);
		const data = getSelectedEvents(selectedDate, allEvents);
		setEvents(() => data);
	}, [selectedDate, allEvents]);

	return events;
}
