import { ApiClientResponse } from './types';

export const parseErrorStatus = (error: Error): number => {
	const status = 500;
	const message = error?.message || 'Unknown Error';
	if (message.includes('400')) return 400;
	if (message.includes('401')) return 401;
	if (message.includes('403')) return 403;
	if (message.includes('404')) return 404;
	if (message.includes('500')) return 500;
	return status;
};

/*
 * Handles the AXIOS error response
 */
export class ApiClientResponseError extends Error {
	public message: string;

	public method: string;

	public path: string;

	public request: any;

	public status: number;

	public headers: any;

	constructor(response: any) {
		super();
		Object.setPrototypeOf(this, new.target.prototype);
		if (Error.captureStackTrace) Error.captureStackTrace(this);
		this.name = 'ApiClientResponseError';
		this.message =
			response?.data?.message || response?.statusText || 'Unknown Error';
		this.status = response?.data?.status || response?.status || 500;
		this.method = response?.config?.method || 'ANY';
		this.path = response?.config?.url;
		this.request = response?.config?.data;
		this.headers = response?.headers;
	}
}

export const getResponseResult = <TResult>(
	response: any
): ApiClientResponse<TResult> => {
	if (!response) throw new Error('Invalid response');
	return {
		method: response.config.method,
		path: response.config.path,
		status: response.status,
		statusText: response.statusText,
		request: response.config.data,
		headers: response.config.headers,
		result: response.data as TResult,
	};
};
