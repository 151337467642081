import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

// Icons
import Icon from '@mdi/react';
import { mdiOpenInNew, mdiMonitorCellphone } from '@mdi/js';

// Components
import { IntegrationListItemGoogle } from './integrations/IntegrationListItemGoogle';
import { IntegrationListItemMicrosoft } from './integrations/IntegrationListItemMicrosoft';

// Lib
import { useAppContext } from '../contexts/AppContext';
import { useApplicationConfigurationContext } from '../contexts/ApplicationConfiguration';
import { useUserProfileContext } from '../contexts/UserProfileContext';

export const Settings = () => {
	const theme = useTheme();
	const { version, isWide } = useAppContext();
	const { userConfiguration } = useUserProfileContext();
	const { appConfig } = useApplicationConfigurationContext();

	const openCloudPortal = () => {
		try {
			if (userConfiguration?.ucpUri) {
				const uri = new URL(userConfiguration.ucpUri);
				const subdomain = uri.host.split('.')[0];
				const url = appConfig.cloudPortalUrl.replace(
					'{subdomain}',
					subdomain
				);
				window.open(url);
			}
		} catch (error) {
			// Do Nothing
		}
	};

	const openUrl = (url: string) => {
		window.open(url);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				position: 'relative',
				height: '100%',
				backgroundColor: '#fff',
				borderRadius: 1,
			}}
		>
			<Grid
				container
				direction={isWide ? 'row' : 'column'}
				wrap="nowrap"
				style={{ flexGrow: 1 }}
			>
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						borderRadius: 1,
						flexGrow: isWide ? 1 : 'initial',
					}}
				>
					<Toolbar
						variant="dense"
						sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
					>
						<span
							style={{
								flexGrow: 1,
								position: 'relative',
								fontSize: theme.typography.body2.fontSize,
								fontWeight: 500,
								color: '#eb9034',
								marginLeft: theme.spacing(-1),
							}}
						>
							Integrations
						</span>
					</Toolbar>
					<List dense>
						<IntegrationListItemMicrosoft />
						<IntegrationListItemGoogle />
						{/* <IntegrationListItemLinkedIn /> */}
					</List>
				</Grid>
				<Grid
					item
					sx={{
						position: 'relative',
						height: '100%',
						width: '8px',
						backgroundColor: '#f6f6f6',
						display: isWide ? 'flex' : 'none',
					}}
				>
					&nbsp;
				</Grid>
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						position: 'relative',
						borderRadius: theme.spacing(1),
					}}
				>
					<Toolbar
						variant="dense"
						sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
					>
						<span
							style={{
								flexGrow: 1,
								position: 'relative',
								fontSize: theme.typography.body2.fontSize,
								fontWeight: 500,
								color: '#eb9034',
								marginLeft: theme.spacing(-1),
							}}
						>
							Quick Links
						</span>
					</Toolbar>
					<List dense>
						{appConfig.aboutUsLinks.map((link) => (
							<ListItem
								button
								key={link.key}
								onClick={() => openUrl(link.value)}
							>
								<ListItemText primary={link.key} />
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => openUrl(link.value)}
									>
										<Icon path={mdiOpenInNew} size={0.75} />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
						<ListItem button onClick={() => openCloudPortal()}>
							<ListItemText primary="Cloud Portal" />
							<ListItemSecondaryAction>
								<IconButton onClick={() => openCloudPortal()}>
									<Icon
										path={mdiMonitorCellphone}
										size={0.75}
									/>
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
					<span style={{ flexGrow: 1 }} />
					<Divider />
					<span
						style={{
							fontSize: theme.typography.body2.fontSize,
							padding: theme.spacing(3, 4, 1, 4),
							color: 'rgba(0, 0, 0, 0.8)',
							textAlign: 'center',
						}}
					>
						Version: {version}
					</span>
					<span
						style={{
							fontSize: theme.typography.body2.fontSize,
							padding: theme.spacing(1, 4, 3, 4),
							color: 'rgba(0, 0, 0, 0.8)',
							textAlign: 'center',
						}}
					>
						Copyright © {new Date().getFullYear()}{' '}
						{appConfig.copyright}
					</span>
				</Grid>
			</Grid>
		</Box>
	);
};
