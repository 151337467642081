import React from 'react';
import Box from '@mui/material/Box';

// Lib
import { UI_BACKGROUNDCOLOR } from '../lib';
import { useAppContext } from '../contexts/AppContext';
import { Button } from '@mui/material';

export const Profile = () => {
	const app = useAppContext();

	return (
		<Box
			sx={{
				backgroundColor: UI_BACKGROUNDCOLOR,
				p: 2,
				flexGrow: 1,
				display: 'flex',
			}}
		>
			<p>
				<Button
					variant="contained"
					color="primary"
					onClick={app.signout}
				>
					Sign Out
				</Button>
			</p>
		</Box>
	);
};
