import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UAParser from 'ua-parser-js';

// Icons
import {
	mdiAccountCircle,
	mdiCalendarMonthOutline,
	mdiDotsHorizontalCircleOutline,
	mdiPhone,
	mdiVideo,
} from '@mdi/js';
import Icon from '@mdi/react';

// Components
import { ConfirmationDialog } from '../common/ConfirmationDialog';
import { Time } from '../common/Time';
import { ToolbarButton } from './ToolbarButton';

import { useAppContext } from '../../contexts/AppContext';
import { useUserProfileContext } from '../../contexts/UserProfileContext';
import { formatPhoneNumber } from '../../lib/utils';

const parser = new UAParser();
const uaParserResult = parser.getResult();

export const Toolbar = () => {
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const [logoutConfirmationOpen, setLogoutConfirmationOpen] =
		React.useState(false);
	const [leavePageConfirmationOpen] =
		React.useState(false);
	const { signout, isWide, ucpWindowRef, setUcpWindowRef } = useAppContext();
	const { userConfiguration } = useUserProfileContext();

	const ext = userConfiguration?.ext ? ` x${userConfiguration.ext}` : '';
	const did = userConfiguration?.did
		? formatPhoneNumber(userConfiguration.did)
		: '';

	const logout = () => {
		if (userConfiguration?.ucpUri) {
			const iframe = document.getElementById('ucp') as HTMLIFrameElement;
			if (iframe) {
				iframe.src = `${userConfiguration.ucpUri}/logout`;
				setTimeout(signout, 500);
			} else {
				signout();
			}
		} else {
			signout();
		}
	};

	const openCommunication = () => {
		console.log('Browser Result', uaParserResult);
		if (
			(uaParserResult.browser.name?.toLowerCase().includes('safari') ||
				uaParserResult.os.name?.toLowerCase().includes('ios')) &&
			userConfiguration?.ucpUri
		) {
			if (ucpWindowRef) {
				ucpWindowRef.focus();
			} else {
				const ref = window.open(userConfiguration.ucpUri, 'ucpConnect');
				setUcpWindowRef(ref);
			}
		} else {
			history.push('/communication');
		}
	};

	// const handleUnload = React.useCallback((event: BeforeUnloadEvent) => {
	// 	event.preventDefault();
	// 	// setLeavePageConfirmationOpen(true);
	// 	// setTimeout(() => setLeavePageConfirmationOpen(true));
	// 	// const message =
	// 	// 	'Please be sure to logout first if you are on a public computer.  Click cancel then logout!';
	// 	// return (event.returnValue = 'o/');
	// 	return (event.returnValue =
	// 		'Please be sure to logout first if you are on a public computer.  Click cancel then logout!');
	// 	// return message;
	// }, []);

	React.useEffect(() => {
		if (ucpWindowRef) {
			const interval = setInterval(() => {
				if (ucpWindowRef?.closed) {
					setUcpWindowRef(null);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [ucpWindowRef, setUcpWindowRef]);

	// React.useEffect(() => {
	// 	if (window) {
	// 		window.addEventListener('beforeunload', handleUnload);
	// 		return () =>
	// 			window.removeEventListener('beforeunload', handleUnload);
	// 	}
	// }, [handleUnload]);

	return (
		<MuiToolbar
			sx={{ minHeight: '48px', maxHeight: '48px', height: '48px' }}
			variant="dense"
			disableGutters
		>
			<ToolbarButton
				icon={mdiAccountCircle}
				text={
					isWide
						? `${userConfiguration?.firstName} ${userConfiguration?.lastName}`
						: ''
				}
				tooltip="Logout"
				onClick={() => setLogoutConfirmationOpen(true)}
			/>
			<Box
				sx={{
					color: '#555555',
					fontSize: theme.typography.caption.fontSize,
					marginRight: 1,
				}}
			>{`${did}${ext}`}</Box>
			<span style={{ flexGrow: 1 }} />
			<ToolbarButton
				icon={mdiPhone}
				text={isWide ? 'Communication' : undefined}
				tooltip="Communication"
				onClick={openCommunication}
				selected={location.pathname.includes('/communication')}
			/>
			<ToolbarButton
				icon={mdiVideo}
				tooltip="Meetings"
				onClick={() => history.push('/meetings')}
				selected={location.pathname.includes('/meetings')}
				text={isWide ? 'Meetings' : ''}
			/>
			<ToolbarButton
				icon={mdiCalendarMonthOutline}
				text={isWide ? 'Calendar' : undefined}
				tooltip="Calendar"
				onClick={() => history.push('/calendar')}
				selected={location.pathname.includes('/calendar')}
			/>
			<IconButton size="small" onClick={() => history.push('/settings')}>
				<Icon
					path={mdiDotsHorizontalCircleOutline}
					size={1}
					color="#eb9034"
				/>
			</IconButton>
			<span style={{ width: '15px' }} />
			{isWide && <Time />}
			{logoutConfirmationOpen && (
				<ConfirmationDialog
					title="Signout"
					text="Are you sure you want to logout?"
					onClose={() => setLogoutConfirmationOpen(false)}
					onContinue={() => logout()}
				/>
			)}
			{leavePageConfirmationOpen && (
				<ConfirmationDialog
					title="Signout?"
					text="Do you want to signout as you leave?"
					onClose={() => setLogoutConfirmationOpen(false)}
					onContinue={() => logout()}
				/>
			)}
		</MuiToolbar>
	);
};
