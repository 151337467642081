import { DateTime } from '../../lib/DateTime';

export interface EventItem<T> {
	id: string;
	start: number;
	end: number;
	duration: number;
	event: T;
}

export type EventMapper<T> = (event: T) => EventItem<T>;

export interface TimelineData {
	id: string;
	start: number;
	end: number;
	slots: string[];
	column: number;
	left: number;
	top: number;
	width: number;
	height: number;
}

type TimeSlotColumns = Record<string, string[]>;

export interface TimelineOptions {
	intervalInMinutes: number;
	slotHeight: number;
	containerWidth: number;
	overlap: boolean;
}

export interface CalculatedTimelineOptions extends TimelineOptions {
	totalSlotHeight: number;
	slotLength: number;
}

const getTimeSlots = (
	slotLength: number,
	intervalInMinutes: number
): string[] => {
	const slots: string[] = [];
	const dt = new Date();
	dt.setHours(0, 0, 0, 0);
	// eslint-disable-next-line no-plusplus
	for (let index = 0; index < slotLength; index++) {
		slots.push(new DateTime(dt).toFormattedShortTime());
		dt.setMinutes(dt.getMinutes() + intervalInMinutes);
	}
	return slots;
};

const getTimeSlotColumns = (slots: string[]): TimeSlotColumns => {
	const columns: TimeSlotColumns = {};
	slots.forEach((slot) => {
		columns[slot] = [];
	});
	return columns;
};

const calculateItemTopOffset = (start: Date, totalSlotHeight: number) => {
	const now = DateTime.getTotalMinutesFromMidnight(start);
	return Math.floor((totalSlotHeight / DateTime.TotalMinutesInOneDay) * now);
};

const calculateItemHeight = (
	durationInMinutes: number,
	totalSlotHeight: number,
	slotHeight: number
): number => {
	const calc = Math.floor(
		(totalSlotHeight / DateTime.TotalMinutesInOneDay) * durationInMinutes
	);
	return calc < slotHeight ? slotHeight : calc;
};

/**
 * Calculates which time slots the data will occupy
 * @param start The start date
 * @param end The end date
 * @returns Slot Start; Slot End; All occupied slots
 */
const getItemSlots = (
	start: Date,
	end: Date,
	options: CalculatedTimelineOptions
): string[] => {
	// TEST MEETING #1: 12:12 - 12:36
	// We want this meeting to occupy the 12:00 and 12:30 slots
	// slotStart = 12:00
	// slotEnd = 12:30

	// TEST MEETING #2: 12:36 - 12:55
	// We want this meeting to occupy the 12:30 slot only
	// slotStart = 12:30
	// slotEnd = 12:30

	// TEST MEETING #3: 12:45 - 2:20
	// We want this meeting to occupy the 12:30, 1:00, 1:30, and 2:00 slots
	// slotStart = 12:30
	// slotEnd = 2:00

	let slotStartDate: DateTime;
	const startMinutes = start.getMinutes();
	if (startMinutes < options.intervalInMinutes) {
		slotStartDate = new DateTime(start).addMinutes(-startMinutes);
	} else if (startMinutes > options.intervalInMinutes) {
		slotStartDate = new DateTime(start).addMinutes(
			-(startMinutes - options.intervalInMinutes)
		);
	} else {
		slotStartDate = new DateTime(start);
	}
	const slotStart = slotStartDate.toFormattedShortTime();

	let slotEndDate: DateTime;
	const endMinutes = end.getMinutes();
	if (endMinutes === 0) {
		// We do not want the end time to carry over into the next slot
		slotEndDate = new DateTime(end).addMinutes(-options.intervalInMinutes);
	} else if (endMinutes < options.intervalInMinutes) {
		slotEndDate = new DateTime(end).addMinutes(-endMinutes);
	} else if (endMinutes > options.intervalInMinutes) {
		slotEndDate = new DateTime(end).addMinutes(
			-(endMinutes - options.intervalInMinutes)
		);
	} else {
		// We do not want the end time to carry over into the next slot
		slotEndDate = new DateTime(end).addMinutes(-options.intervalInMinutes);
	}

	// Find all slots between startSlot and endSlot
	// 12:30, 1:00, 1:30, and 2:00
	const slots: string[] = [];
	slots.push(slotStart);

	const et = slotEndDate.toDate().getTime();
	let n = slotStartDate.addMinutes(options.intervalInMinutes);
	while (n.toDate().getTime() <= et) {
		slots.push(n.toFormattedShortTime());
		n = n.addMinutes(options.intervalInMinutes);
	}

	// console.log('SLOTS', slots);
	return slots;
};

const updateColumns = (
	id: string,
	data: TimeSlotColumns,
	slots: string[]
): [TimeSlotColumns, number] => {
	const columns = { ...data };

	let max = 0;
	slots.forEach((slot) => {
		const len = data[slot].length;
		if (len > max) {
			max = len;
		}
	});
	const columnToOccupy = max + 1;

	// console.log('columnToOccupy', max);
	slots.forEach((slot) => {
		// eslint-disable-next-line no-plusplus
		for (let columnIndex = 0; columnIndex < columnToOccupy; columnIndex++) {
			while (data[slot].length < columnToOccupy) {
				data[slot].push('');
			}
			data[slot][columnToOccupy - 1] = id;
		}
		// console.log(`SLOT: ${slot}`, data[slot]);
	});

	return [columns, columnToOccupy];
};

export const renderTimelineData = <T>(
	events: T[],
	mapper: EventMapper<T>,
	options: TimelineOptions
): TimelineData[] => {
	const data: TimelineData[] = [];
	const slotLength: number = (24 * 60) / options.intervalInMinutes;
	const opts: CalculatedTimelineOptions = {
		...options,
		slotLength,
		totalSlotHeight: options.slotHeight * slotLength,
	};

	const cache: Record<string, TimelineData> = {};
	const allSlots = getTimeSlots(opts.slotLength, opts.intervalInMinutes);
	let columns: TimeSlotColumns = getTimeSlotColumns(allSlots);
	let columnToOccupy = 0;

	events.forEach((e) => {
		const event = mapper(e);

		// console.log(
		// 	`${(e as any).name} ${new Date(
		// 		event.start
		// 	).toLocaleString()} - ${new Date(event.end).toLocaleString()} - ${
		// 		event.duration
		// 	}`
		// );

		const slots = getItemSlots(
			new Date(event.start),
			new Date(event.end),
			opts
		);
		[columns, columnToOccupy] = updateColumns(event.id, columns, slots);

		const item = {
			id: event.id,
			start: event.start,
			end: event.end,
			column: columnToOccupy,
			top: calculateItemTopOffset(
				new Date(event.start),
				opts.totalSlotHeight
			),
			height: calculateItemHeight(
				event.duration,
				opts.totalSlotHeight,
				opts.slotHeight
			),
			left: 0,
			width: opts.containerWidth,
			slots,
		};

		cache[item.id] = item;
		data.push(item);
	});

	// Calculate widths
	Object.keys(columns).forEach((slot) => {
		const itemLength = columns[slot].length;
		if (itemLength > 0) {
			const columnWidth = opts.containerWidth / itemLength;
			columns[slot].forEach((id, i) => {
				if (cache[id]) {
					if (cache[id].width > columnWidth) {
						cache[id].width = columnWidth;
					}
					cache[id].left = i === 0 ? 0 : columnWidth * i + 1;
				}
			});
		}
	});

	return data;
};
