import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// Components
import { IntegrationListItemMicrosoft } from '../integrations/IntegrationListItemMicrosoft';
import { IntegrationListItemGoogle } from '../integrations/IntegrationListItemGoogle';

export const NoCalendars = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				alignItems: 'center',
				justifyContent: 'center',
				backgroundImage:
					'repeating-linear-gradient(45deg, rgba(0,0,0,0.08),transparent,rgba(0,0,0,0.02),rgba(0,0,0,0.05),rgba(0,0,0,0.01),rgba(0,0,0,0.07),rgba(0,0,0,0.05),rgba(0,0,0,0.02),rgba(0,0,0,0.04),rgba(0,0,0,0.04),rgba(0,0,0,0.02),rgba(0,0,0,0.1),rgba(0,0,0,0.07),rgba(0,0,0,0.02),rgba(0,0,0,0.07) 3px),linear-gradient(135deg, rgb(42, 160, 254),rgb(47, 73, 131))',
			}}
		>
			<Paper
				sx={{
					padding: theme.spacing(4),
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					minWidth: '500px',
					minHeight: '400px',
					borderRadius: theme.spacing(2),
				}}
			>
				<IntegrationListItemMicrosoft />
				<IntegrationListItemGoogle />
			</Paper>
		</Box>
	);
};
