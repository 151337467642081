// import sanitizeHtml from 'sanitize-html';
import { uniq } from 'lodash';
import { convert } from 'html-to-text';

import { defaultJitsiServer } from './types';
import {
	CalendarEvent,
	ConferenceProviderSetting,
	ConferenceProvider,
} from './types';

const urlMatch =
	// eslint-disable-next-line no-useless-escape
	/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&\/=]*)/gim;

export const replaceAll = (source: string, match: string, repl: string) => {
	console.log('replaceAll', match, repl);
	return source.replaceAll(match, repl);
	// return replace(source, new RegExp(match, 'g'), repl);
};

export const getAvatarImagePath = (domain: string, url?: string | null) => {
	if (!url) {
		return null;
	}
	const d = domain.endsWith('/')
		? domain.substr(0, domain.length - 1)
		: domain;
	const u = url.startsWith('/') ? url.substr(1, url.length) : url;
	return `${d}/${u}`;
};

export const formatDate = (date: Date): string => {
	let hours = date.getHours();
	const minutes = date.getMinutes();
	const amPm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	const strMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
	const strTime = `${hours}:${strMinutes} ${amPm}`;
	return `${
		date.getMonth() + 1
	}/${date.getDate()}/${date.getFullYear()} ${strTime}`;
};

// export const formatDateTime = (val?: string | null): string => {
//     if (!val) {
//         return '';
//     }
//     try {
//         const dt = new Date(val);
//         return dt.toLocaleTimeString('en-US', {
//             hour: 'numeric',
//             minute: 'numeric',
//         });
//     } catch (error) {
//         return '';
//     }
// };

// eslint-disable-next-line no-self-compare
export const dateIsValid = (date: Date) => date.getTime() === date.getTime();

export const formatDateTime = (value: any) => {
	if (!value) {
		return null;
	}
	const dt = new Date(value);
	if (!dateIsValid(dt)) {
		return null;
	}
	// 'MM/DD/YYYY hh:mm A'
	return dt.toLocaleTimeString([], {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
};

export const formatTime = (value: any) => {
	if (!value) {
		return null;
	}
	const dt = new Date(value);
	if (!dateIsValid(dt)) {
		return null;
	}
	// 09:30 AM
	return dt.toLocaleTimeString([], {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
};

// export const getUTCDateNow = () => {
//     const dt = DateTime.utc();
//     return dt.toJSDate();
// };

export const getUTCDateNow = (): Date => new Date(new Date().toUTCString());

// export const toDateTimeString = (value?: Date | null): string | null => {
//     if (!value) {
//         return null;
//     }
//     const dt = DateTime.fromJSDate(value);
//     return dt.isValid ? dt.setZone('utc').toISO() : null;
// };

export const toDateTimeString = (value?: Date | null): string | null => {
	if (!value) {
		return null;
	}
	const dt = new Date(value);
	return dateIsValid(dt) ? dt.toISOString() : null;
};

export const parseJitsiUrl = (
	roomOrUrl: string
): { server: string; room: string } => {
	if (!roomOrUrl) {
		throw new Error('Invalid meeting url');
	}

	if (!roomOrUrl.includes('/')) {
		return { room: roomOrUrl, server: defaultJitsiServer };
	}

	try {
		const uri = new URL(roomOrUrl);

		// Remove the first / after the domain
		let room = uri.pathname.replace('/', '');

		// If the path has more than one segment, we only want the first one
		if (room.includes('/')) {
			const paths = room.split('/');
			[room] = paths;
		}

		// Is it a file?
		if (room.includes('.')) {
			const paths = room.split('.');
			[room] = paths;
		}

		return { server: uri.host, room };
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getPersonalMeetingRoom = (
	tenant: string | null | undefined,
	ext: number | null | undefined
) => {
	return `${tenant || 'Guest'}-${ext || '0000'}`;
};

const parseUrls = (content?: string | null): string[] => {
	if (!content) {
		return [];
	}
	const urls = content.match(urlMatch);
	if (urls) {
		return urls
			.map((item) => decodeURIComponent(item.toLowerCase()))
			.filter((x) => !x.includes('schema.org'));
	}
	return [];
};

export const parseAllUrls = (
	items: (string | null | undefined)[]
): string[] => {
	let urls: string[] = [];
	items.forEach((item) => {
		if (item) {
			urls = urls.concat(parseUrls(item));
		}
	});
	return uniq(urls);
};

export const matchProvider = (
	conferenceProviders: ConferenceProviderSetting[],
	urls: string[]
): ConferenceProvider => {
	const providers = urls
		.map((url) => ({
			provider: conferenceProviders.find((p) =>
				p.matchers.every((m) => url.includes(m))
			),
			url,
		}))
		.filter((p) => p.provider !== undefined);

	const accent = providers.find((p) => p.provider?.name === 'Accent');
	if (accent && accent.provider) {
		return {
			conferenceName: accent.provider.name,
			conferenceUrl: accent.url,
			image: accent.provider.image,
			urls,
		};
	}

	if (providers.length > 0) {
		const p = providers[0];
		if (p.provider) {
			return {
				conferenceName: p.provider.name,
				conferenceUrl: p.url,
				image: p.provider.image,
				urls,
			};
		}
	}

	return {
		conferenceName: 'Other',
		conferenceUrl: null,
		image: null,
		urls,
	};
};

export const determineConferenceProvider = (
	conferenceProviders: ConferenceProviderSetting[],
	event: Partial<CalendarEvent>
): ConferenceProvider => {
	let urls = parseUrls(event.location);
	urls = uniq(urls.concat(parseUrls(event.description)));
	return matchProvider(conferenceProviders, urls);
};

export const cleanHtml = (value: string | null | undefined): string | null => {
	if (!value) {
		return null;
	}
	// return sanitizeHtml(replaceAll(value, '____', '').trim());
	return value;
};

export const decodeHtml = (str: string) => {
	const div = document.createElement('div') as HTMLDivElement;
	div.innerHTML = str;
	return div.innerText;
};

export const removeHtml = (value: string | null | undefined): string | null => {
	if (!value) return null;
	return convert(value);
};

export const truncate = (value: string | null | undefined, limit: number) => {
	if (!value) return '';
	return value.length > limit ? `${value.substr(0, limit)}...` : value;
};

export const formatPin = (pin: string | null | undefined): string => {
	if (!pin) return '';
	let result = '';
	for (let i = 0; i < pin.length; i += 1) {
		result += pin[i];
		if (i % 2 !== 0) {
			result += ' ';
		}
	}
	return result;
};

export const removePunctuation = (input?: string) => {
	if (!input) return '';
	const val = input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
	return val.replace(/\s{2,}/g, ' ');
};

export const formatPhoneNumber = (input?: string) => {
	if (!input) return '';
	const val = removePunctuation(input);
	const line: string[] = [];
	const prefix: string[] = [];
	const areaCode: string[] = [];
	const country: string[] = [];
	let count = 1;
	const str = val.split('').reverse();
	str.forEach((d) => {
		if (count <= 4) line.splice(0, 0, d);
		if (count > 4 && count < 8) prefix.splice(0, 0, d);
		if (count > 7 && count < 11) areaCode.splice(0, 0, d);
		if (count > 10) country.splice(0, 0, d);
		count += 1;
	});
	let phone = '';
	if (line.length) phone = line.join('');
	if (prefix.length) phone = `${prefix.join('')}-${phone}`;
	if (areaCode.length) phone = `(${areaCode.join('')}) ${phone}`;
	if (country.length) phone = `+${country.join('')} ${phone}`;
	return phone;
};

export const getJwtExpiration = (minutes: number) => {
	return Math.floor(Date.now() / 1000) + (minutes || 60) * 60;
};

export const getJwtNotBefore = () => {
	return Math.floor(Date.now() / 1000) - 60;
};