import React from 'react';
import Box from '@mui/material/Box';

export type NothingHereProps = {
	text: string;
};

export const NothingHere = (props: NothingHereProps) => {
	const { text } = props;

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				alignItems: 'center',
				justifyContent: 'center',
				padding: 2,
			}}
		>
			<span>{text}</span>
		</Box>
	);
};
