import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import { LoginSplash } from './components/auth/LoginSplash';
import { Layout } from './components/shell/Layout';
import { Profile } from './components/Profile';

// Lib
import { useAppContext } from './contexts/AppContext';
import { Meetings } from './components/Meetings';
import { Calendar } from './components/Calendar';
import { Settings } from './components/Settings';
import { Links } from './components/test/Links';
import { JitsiTest } from './components/test/JitsiTest';
import Admin from './components/admin/Admin';

export default function Routes() {
	const { user } = useAppContext();

	if (!user) {
		return <LoginSplash />;
	}

	return (
		<Layout>
			<Switch>
				<Route path="/" component={Meetings} exact />
				<Route path="/profile" component={Profile} exact />
				<Route path="/meetings" component={Meetings} exact />
				<Route path="/calendar" component={Calendar} exact />
				<Route path="/settings" component={Settings} exact />
				<Route path="/test/links" component={Links} exact />
				<Route path="/test/jwt" component={JitsiTest} exact />
				{user.groups.includes('SystemAdmin') && (
					<Route path="/admin" component={Admin} exact />
				)}
			</Switch>
		</Layout>
	);
}
