import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Iframe from 'react-iframe';
import { useMeasure } from 'react-use';

// Lib
import { useUserProfileContext } from '../contexts/UserProfileContext';
import { ApplicationEvent } from '../lib/ApplicationEvent';
import { logoutIFrame } from '../lib/connect';

type Props = {
	show: boolean;
};

export const Communication = ({ show }: Props) => {
	const theme = useTheme();
	const [ref, { width, height }] = useMeasure<HTMLDivElement>();
	const { userConfiguration } = useUserProfileContext();
	// const iframe = document.getElementById('ucp') as HTMLIFrameElement;

	const url = userConfiguration?.ucpUri;

	React.useEffect(() => {
		ApplicationEvent.Receive('logout-connect', logoutIFrame);
	}, []);

	// React.useEffect(() => {
	// 	if (iframe) {
	// 		console.log('Wiring iframe beforeunload');
	// 		window.addEventListener('beforeunload', logoutIFrame);
	// 		window.addEventListener('unload', logoutIFrame);
	// 		return () => {
	// 			window.removeEventListener('beforeunload', logoutIFrame);
	// 			window.removeEventListener('unload', logoutIFrame);
	// 		};
	// 	}
	// }, [iframe]);

	if (!url) return null;

	return (
		<Box
			ref={ref}
			sx={{
				display: show ? 'flex' : 'none',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				position: 'relative',
				height: '100%',
				backgroundColor: '#fff',
				borderRadius: theme.spacing(1),
			}}
		>
			<Iframe
				url={url}
				width={`${width}px`}
				height={`${height}px`}
				id="ucp"
				display="block"
				position="relative"
				allowFullScreen={false}
				frameBorder={0}
				allow="geolocation *; microphone *; camera *; autoplay *; display-capture *; picture-in-picture *; sync-xhr *;"
			/>
		</Box>
	);
};
