// Lib
import { CalendarEvent, CalendarClass, EventMapper } from '../types';
import { Storage } from '../storage';
import { DateTime } from '../DateTime';

const SCOPES = ['https://www.googleapis.com/auth/calendar.readonly'];

const CLIENT_ID =
	'149416264398-9b0leug5knu0uj5u589kobqivlmtskbn.apps.googleusercontent.com';

const CLIENT_SECRET = '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';

// Need to change this is use the current url or pull from config
// const REDIRECT_URI = window.location.href.includes(':3000')
// 	? 'http://localhost:3000'
// 	: 'https://app.accentvoice.com';
const REDIRECT_URI = window.location.href.includes(':3000')
	? 'http://localhost:3000'
	: `https://${window.location.hostname}`;

type CodeResponse = {
	code: string;
	scope: string;
};

type TokenResponse = {
	access_token?: string;
	expires_in?: number;
	expires_at?: number;
	token_type?: 'Bearer';
	scope?: string;
	refresh_token?: string;
	error?: string;
	error_description?: string;
};

// const getToday = () => {
// 	const now = new Date();
// 	const startOfDay = new Date(
// 		now.getFullYear(),
// 		now.getMonth(),
// 		now.getDate()
// 	);
// 	// take next day date and reduce for one millisecond
// 	const endOfDay = new Date(
// 		new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
// 	);
// 	return [startOfDay.toISOString(), endOfDay.toISOString()];
// };

export default class GoogleCalendar implements CalendarClass {
	private storeKey: string;

	private token: TokenResponse | null;

	private code: CodeResponse | null;

	private mapper: EventMapper;

	private calendarInialized: boolean;

	private calendar: typeof gapi.client.calendar | null;

	private client: google.accounts.oauth2.CodeClient;

	constructor(name: string, mapper: EventMapper) {
		this.storeKey = `google-auth-cache-${name}`;
		this.code = null;
		this.token = null;
		this.mapper = mapper;
		this.calendar = null;
		this.calendarInialized = false;

		this.initialize = this.initialize.bind(this);
		this.getToken = this.getToken.bind(this);
		this.refresh = this.refresh.bind(this);
		this.clearToken = this.clearToken.bind(this);
		this.setToken = this.setToken.bind(this);
		this.connect = this.connect.bind(this);
		this.disconnect = this.disconnect.bind(this);
		this.isConnected = this.isConnected.bind(this);
		this.getEvents = this.getEvents.bind(this);

		// console.log('GOOGLE: constructor()');

		this.client = google.accounts.oauth2.initCodeClient({
			client_id: CLIENT_ID,
			scope: SCOPES.join(' '),
			ux_mode: 'popup',
			callback: (response: any) => {
				// console.log('Google Token Response', response);
				if (response.code && response.scope) {
					this.code = response as CodeResponse;
					this.getToken();
				} else {
					this.code = null;
				}
			},
		});

		Storage.get<TokenResponse | null>(this.storeKey, null).then(
			this.setToken
		);

		this.initialize();
	}

	private async initialize() {
		// console.log('GOOGLE: initialize()');
		gapi.load('client', async () => {
			await gapi.client.load('calendar', 'v3');
			this.calendar = gapi.client.calendar;
			this.calendarInialized = true;
			// console.log('GOOGLE: calendar: ', this.calendar);
		});
	}

	private async getToken() {
		if (this.code) {
			try {
				const endpoint = `https://oauth2.googleapis.com/token?code=${this.code.code}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&grant_type=authorization_code&redirect_uri=${REDIRECT_URI}`;
				const response = await fetch(endpoint, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				});
				const data = (await response.json()) as TokenResponse;

				if (!data.error) {
					this.setToken(data);
					return;
				}
				return data;
			} catch (error) {
				console.error(error);
			}
		}
		this.clearToken();
	}

	private setToken(token?: TokenResponse | null) {
		// console.log('GOOGLE: setToken()', token);
		if (!token) {
			this.clearToken();
			return;
		}

		const dt = new Date();
		let expiresAt = token?.expires_at || 0;
		if (!token?.expires_at) {
			dt.setTime(dt.getTime() + (token?.expires_in || 0) * 1000);
			expiresAt = dt.getTime();
		}

		this.token = {
			...token,
			expires_at: expiresAt,
		};

		// console.log('GOOGLE: setToken() -> Calculated Token', this.token);
		Storage.set(this.storeKey, this.token);
	}

	private clearToken() {
		// console.log('GOOGLE: clearToken()');
		this.token = null;
		Storage.remove(this.storeKey);
	}

	async connect(): Promise<boolean> {
		this.client.requestCode();
		return true;
	}

	async disconnect(): Promise<boolean> {
		// console.log('GOOGLE: disconnect()');
		this.clearToken();
		return true;
	}

	async refresh(): Promise<boolean> {
		if (!this.calendarInialized) {
			return false;
		}

		if (this.token?.refresh_token) {
			// console.log('GOOGLE: refresh()');
			try {
				const endpoint = `https://oauth2.googleapis.com/token?refresh_token=${this.token.refresh_token}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&grant_type=refresh_token`;
				// console.log('GOOGLE: calling endpoint: ', endpoint);

				const response = await fetch(endpoint, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				});
				const data = (await response.json()) as TokenResponse;
				console.log('GOOGLE: refresh Response', data);

				if (!data.error) {
					this.setToken(data);
					return true;
				}
			} catch (error) {
				console.error(error);
				// this.clearToken();
			}
		}

		return false;
	}

	async isConnected(): Promise<boolean> {
		if (!this.calendarInialized) {
			return false;
		}

		const dt = new Date();
		const expiresAt = this.token?.expires_at || dt.getTime() - 60 * 1000;
		const isExpired = dt.getTime() > expiresAt;

		// console.log(
		// 	`GOOGLE isConnected() -> Token Expires At (${
		// 		this.token?.expires_at
		// 	}); Now (${dt.getTime()}); isExpired = ${isExpired}`
		// );

		if (this.token?.expires_at) {
			const ed = new Date();
			ed.setTime(this.token.expires_at);
			console.log(`GOOGLE Token expires at ${ed.toLocaleString()}`);
		}

		if (this.token?.access_token && !isExpired) {
			return true;
		}

		return this.refresh();
	}

	async getEvents(start: Date, end: Date): Promise<CalendarEvent[]> {
		console.log(
			`google.getEvents(${start.toISOString()}, ${end.toISOString()})`
		);
		try {
			await this.refresh();
			if (this.token?.access_token && this.calendar) {
				const timeMin = new DateTime(start)
					.toStartOfDay()
					.toISOString();
				const timeMax = new DateTime(end).toEndOfDay().toISOString();

				// console.log('google.getEvents.request', timeMin, timeMax);
				const response = await this.calendar.events.list({
					calendarId: 'primary',
					oauth_token: this.token.access_token,
					timeMin,
					timeMax,
					singleEvents: true,
					orderBy: 'startTime',
					showDeleted: false,
					// timeZone: 'UTC',
				});
				if (response && response.result && response.result.items) {
					const items = response.result.items.filter(
						(x) => x.status !== 'cancelled'
					);
					console.log('GOOGLE EVENTS', items);
					return items.map((item) => this.mapper.map(item));
				}
			}
			return [];
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	// async getTodaysEvents(): Promise<CalendarEvent[]> {
	// 	if (!this.calendarInialized) {
	// 		return [];
	// 	}

	// 	// console.log('GOOGLE getTodaysEvents()');

	// 	try {
	// 		if ((await this.isConnected()) && this.calendar && this.token) {
	// 			const [timeMin, timeMax] = getToday();
	// 			const response = await this.calendar.events.list({
	// 				calendarId: 'primary',
	// 				oauth_token: this.token.access_token,
	// 				timeMin,
	// 				timeMax,
	// 				singleEvents: true,
	// 				orderBy: 'startTime',
	// 				showDeleted: false,
	// 				// timeZone: 'UTC',
	// 			});
	// 			if (response && response.result && response.result.items) {
	// 				const items = response.result.items.filter(
	// 					(x) => x.status !== 'cancelled'
	// 				);
	// 				return items.map((item) => this.mapper.map(item));
	// 			}
	// 			// TODO: Need to look for error in response
	// 		}
	// 		return [];
	// 	} catch (error) {
	// 		console.error(error);
	// 		if ((error as any).status === 401) {
	// 			this.clearToken();
	// 		}
	// 		return [];
	// 	}
	// }
}
