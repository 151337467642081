import * as React from 'react';

import { CalendarEvent } from '../../lib/types';
import { Colors } from '../../lib/Colors';
import { FlexRow } from '../common/FlexRow';
import { useAppTheme } from '../../contexts/ThemeContext';
import { Box, useTheme } from '@mui/material';

export type Props = {
	item: CalendarEvent;
	onSelectEvent: () => void;
};

export const AllDayEventItem = ({ item, onSelectEvent }: Props) => {
	const { isDarkMode } = useAppTheme();
	const theme = useTheme();

	return (
		<Box
			onClick={() => onSelectEvent()}
			onKeyDown={() => onSelectEvent()}
			role="button"
			tabIndex={0}
			sx={{
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
					cursor: 'pointer',
				},
				padding: 0,
			}}
		>
			<div
				style={{
					paddingLeft: '8px',
					paddingBottom: '4px',
					borderBottomWidth: '1px',
					borderBottomColor: isDarkMode
						? Colors.grey800
						: Colors.grey400,
				}}
			>
				<FlexRow
					nowrap
					sx={{
						alignItems: 'center',
						paddingTop: '4px',
						paddingBottom: '4px',
					}}
				>
					<span
						style={{
							paddingLeft: 6,
							color: isDarkMode ? Colors.grey300 : Colors.grey900,
							textTransform: 'uppercase',
							fontSize: 12,
						}}
					>
						All Day:
					</span>
					<span
						style={{
							overflow: 'hidden',
							padding: 8,
							color: isDarkMode ? Colors.grey300 : Colors.black,
						}}
					>
						{item.name}
					</span>
				</FlexRow>
			</div>
		</Box>
	);
};
