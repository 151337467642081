import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { SnackbarProvider } from 'notistack';

// Icons
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export type SnackContextData = {
	setSnackMessage: (message: string) => void;
};

const initialValue: SnackContextData = {
	setSnackMessage: () => {},
};

export const SnackContext = React.createContext<SnackContextData>(initialValue);

export interface SnackContextProps {
	children: React.ReactNode;
}

export const SnackContextProvider = (props: SnackContextProps) => {
	const { children } = props;
	const stackRef = React.useRef<SnackbarProvider | null>(null);

	const snackbarAction = React.useCallback((key: string) => {
		return (
			<IconButton
				color="inherit"
				onClick={() => stackRef.current?.closeSnackbar(key)}
			>
				<Icon path={mdiClose} size={1} />
			</IconButton>
		);
	}, []);

	return (
		<SnackbarProvider
			ref={stackRef}
			maxSnack={3}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			preventDuplicate
			action={snackbarAction}
		>
			{children}
		</SnackbarProvider>
	);
};

export const useSnackContext = (): SnackContextData => {
	return useContext(SnackContext);
};
