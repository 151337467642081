import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// Icons
import Icon from '@mdi/react';
import {
	mdiEyeOutline,
	mdiEyeOffOutline,
	mdiCheckboxBlankOutline,
	mdiCheckboxMarked,
} from '@mdi/js';

// Components
import { Loading } from '../../components/common/Loading';

// Lib
import { useAppDispatch, useAppSelector } from '../../store';
import {
	selectLastLoginUsername,
	selectPrivateDevice,
	setRememberMe,
} from '../../store/user';

export type LoginFormProps = {
	signin: (username: string, password: string) => void;
};

export const LoginForm = ({ signin }: LoginFormProps) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const rememberMe = useAppSelector(selectPrivateDevice);
	const lastUsername = useAppSelector(selectLastLoginUsername);
	const [username, setUsername] = useState(lastUsername || '');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [loggingIn, setLoggingIn] = useState(false);
	const canLogin = !!username && !!password;

	useEffect(() => {
		if (!username) {
			setUsername(lastUsername);
		}
	}, [lastUsername, username]);

	const handleLogin = async () => {
		setLoggingIn(true);
		setTimeout(() => {
			signin(username, password);
		});
	};

	const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (canLogin && e.key === 'Enter') {
			handleLogin();
		}
	};

	if (loggingIn === true) {
		return <Loading />;
	}

	return (
		<Grid
			container
			spacing={2}
			direction="column"
			justifyContent="center"
			alignItems="center"
			alignContent="center"
		>
			<Grid
				item
				style={{
					width: '100%',
				}}
			>
				<FormControl fullWidth>
					<InputLabel htmlFor="username">Username</InputLabel>
					<Input
						id="username"
						value={username}
						onChange={(e) =>
							setUsername(e.target.value?.toLowerCase() || '')
						}
						onFocus={(e) => e.target.select()}
						onKeyPress={handleOnKeyPress}
						sx={{ mx: 1.5 }}
					/>
				</FormControl>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<FormControl fullWidth>
					<InputLabel htmlFor="password">Password</InputLabel>
					<Input
						id="password"
						autoFocus={!!lastUsername}
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => setPassword(e.target.value || '')}
						onFocus={(e) => e.target.select()}
						onKeyPress={handleOnKeyPress}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									size="small"
									aria-label="toggle password visibility"
									onClick={() =>
										setShowPassword(!showPassword)
									}
									onMouseDown={(e) => e.preventDefault()}
								>
									<Icon
										path={
											showPassword
												? mdiEyeOutline
												: mdiEyeOffOutline
										}
										size={1}
									/>
								</IconButton>
							</InputAdornment>
						}
						sx={{ mx: 1.5 }}
					/>
				</FormControl>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<List sx={{ width: '100%' }} disablePadding>
					<Tooltip title="Leave unchecked on public devices to ensure that your data stays protected">
						<ListItem color="secondary" disableGutters>
							<ListItemButton
								onClick={() =>
									dispatch(setRememberMe(!rememberMe))
								}
							>
								<ListItemIcon
									color="secondary"
									sx={{ minWidth: '40px' }}
								>
									<Icon
										path={
											rememberMe
												? mdiCheckboxMarked
												: mdiCheckboxBlankOutline
										}
										size={1}
										color={
											rememberMe
												? theme.palette.secondary.main
												: undefined
										}
									/>
								</ListItemIcon>
								<ListItemText primary="Remember Me" />
							</ListItemButton>
						</ListItem>
					</Tooltip>
				</List>
			</Grid>
			<Grid item sx={{ mt: 3 }}>
				<Button
					variant="contained"
					color="secondary"
					disabled={!canLogin}
					onClick={handleLogin}
				>
					Login
				</Button>
			</Grid>
		</Grid>
	);
};
