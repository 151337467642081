import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = () => {
	return (
		<Backdrop
			open={true}
			sx={{
				zIndex: 1001,
				color: '#fff',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				height: '100%',
				position: 'relative',
				backgroundColor: 'transparent',
			}}
		>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};
