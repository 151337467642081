import React from 'react';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

// Icons
import Icon from '@mdi/react';
import { mdiLinkVariantOff } from '@mdi/js';

// Lib
import { useApplicationConfigurationContext } from '../../contexts/ApplicationConfiguration';
import { useCalendarContext } from '../../contexts/CalendarContext';

export const IntegrationListItemGoogle = () => {
	const { appConfig } = useApplicationConfigurationContext();
	const {
		isGoogleConnected,
		connectGoogle,
		disconnectGoogle,
		isCalendarReady,
	} = useCalendarContext();
	const provider = appConfig.integrationProviders.find(
		(x) => x.name === 'Google'
	);

	if (!isCalendarReady) return null;

	return (
		<>
			{provider && !isGoogleConnected && (
				<ListItem button onClick={connectGoogle} sx={{ my: 1 }}>
					<ListItemText
						primary="Sign in to Google"
						secondary="Connect your Calendar"
					/>
					<img src={provider.signinImage} alt="Sign in to Google" />
				</ListItem>
			)}
			{!!isGoogleConnected && (
				<ListItem button onClick={disconnectGoogle}>
					<ListItemText
						primary="Sign out of Google"
						secondary="Disconnect your Google Calendar"
					/>
					<ListItemSecondaryAction>
						<IconButton size="small" onClick={disconnectGoogle}>
							<Icon path={mdiLinkVariantOff} size={1} />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			)}
		</>
	);
};
