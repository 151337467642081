import { take } from 'lodash';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';

import { EventParticipant } from '../../lib/types';
import { removePunctuation } from '../../lib/utils';
import { Colors } from '../../lib/Colors';

type Props = {
	item: EventParticipant;
};

const getColor = (status?: string | null) => {
	switch (status) {
		case 'accepted':
			return Colors.green800;
		case 'tentative':
			return Colors.orange300;
		case 'declined':
			return Colors.grey200;
		default:
			return Colors.grey400;
	}
};

const getLabel = (value?: string | null) => {
	if (!value) {
		return '?';
	}

	let v = '';
	if (value.includes('@')) {
		v = value.split('@')?.[0] || '';
		v = removePunctuation(v);
	} else {
		v = removePunctuation(value) || '';
	}

	return take(v.split(' '), 2)
		.filter((x) => !!x)
		.map((x) => removePunctuation(x[0] || '').toUpperCase())
		.join('');
};

export const AgendaListItemAvatar = ({ item }: Props) => {
	const label = getLabel(item.name || item.email);
	return (
		<Avatar
			data-component="avatar-list-item"
			alt={label}
			style={{
				backgroundColor: getColor(item.status),
				marginLeft: 2,
				marginRight: 2,
				width: '28px',
				height: '28px',
				fontSize: '12px',
			}}
		>
			{label}
		</Avatar>
	);
};
