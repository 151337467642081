import React, { useContext, useCallback } from 'react';

// Lib
import { getAvatarImagePath, parseJitsiUrl } from '../lib/utils';
import { useAppContext } from './AppContext';
import {
	selectLobbyEnabled,
	selectPersonalMeeting,
	useAppSelector,
} from '../store';

export type ConferenceContextData = {
	startMeeting: (url: string) => Promise<void>;
	meetingInProgress: boolean;
};

const initialValue: ConferenceContextData = {
	startMeeting: () => {
		return Promise.resolve();
	},
	meetingInProgress: false,
};

export const ConferenceContext =
	React.createContext<ConferenceContextData>(initialValue);

export interface AppContextProps {
	children: React.ReactNode;
}

export const ConferenceContextProvider = (props: AppContextProps) => {
	const { children } = props;
	const { api, user } = useAppContext();
	const lobbyEnabled = useAppSelector(selectLobbyEnabled);
	const personalMeeting = useAppSelector(selectPersonalMeeting);
	const [meetingRef, setMeetingRef] = React.useState<Window | null>(null);
	const authId = user?.sub;

	const startMeeting = useCallback(
		async (url: string): Promise<void> => {
			if (!authId) {
				return;
			}

			// Make sure lobby is only used on own meeting
			const canEnableLobby = url
				.toLowerCase()
				.endsWith(personalMeeting.room);

			const flags = {
				'live-streaming.enabled': false,
				'lobby-mode.enabled': canEnableLobby && lobbyEnabled === true,
				'calendar.enabled': false,
				'welcomepage.enabled': false,
				'ios.recording.enabled': false,
				'ios.screensharing.enabled': false,
			};

			const { server, room } = parseJitsiUrl(url);

			const info = await api.user.joinMeeting(
				authId,
				room,
				server,
				flags
			);

			const serverUrl = `https://${info.meeting.server}`;

			const userInfo = {
				displayName: `${info.user.firstName} ${info.user.lastName}`,
				email: info.user.email,
				avatar: info.user.imagePath
					? getAvatarImagePath(info.user.imagePath) || undefined
					: undefined,
			};

			let ref: Window | null = null;
			if (info.token) {
				ref = window.open(
					encodeURI(
						`${serverUrl}/${info.meeting.room}?jwt=${info.token}`
					),
					'_blank',
					'popup=yes'
				);
			} else {
				ref = window.open(
					`${serverUrl}/${info.meeting.room}#userInfo.displayName="${
						userInfo.displayName
					}"&userInfo.email="${userInfo.email}"&userInfo.avatar="${
						userInfo.avatar || ''
					}"`,
					'_blank',
					'popup=yes'
				);
			}
			setMeetingRef(ref);
		},
		[api.user, authId, lobbyEnabled, personalMeeting]
	);

	React.useEffect(() => {
		if (meetingRef) {
			const interval = setInterval(() => {
				if (meetingRef.closed) {
					setMeetingRef(null);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [meetingRef]);

	return (
		<ConferenceContext.Provider
			value={{ startMeeting, meetingInProgress: !!meetingRef }}
		>
			{children}
		</ConferenceContext.Provider>
	);
};

export const useConferenceContext = (): ConferenceContextData => {
	return useContext(ConferenceContext);
};
