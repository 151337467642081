const isBeta = false; //window.location.href.includes(':3000');

const awsmobile = {
	aws_project_region: 'us-east-2',
	aws_cognito_identity_pool_id: isBeta ? 'us-east-2:2d7f0dc5-67bc-4ab6-aa51-6f967b63f91a' :
		'us-east-2:1a5185b6-e480-403c-9742-1fb61525877f',
	aws_cognito_region: 'us-east-2',
	aws_user_pools_id: isBeta ? 'us-east-2_J9NwXrbAZ' : 'us-east-2_tsA4r5WHK',
	aws_user_pools_web_client_id: isBeta ? '3uo03t71g6bdj1fifbfl3443j3' : '7udvu07d9qof3m4qg3t4g909vr',
	oauth: {},
	aws_cognito_username_attributes: [],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: '6',
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
	aws_cloud_logic_custom: [
		{
			name: 'api',
			endpoint: isBeta ? 'https://ljsunq3j8a.execute-api.us-east-2.amazonaws.com/prod' : 'https://s8xhsc5n3f.execute-api.us-east-2.amazonaws.com/prod',
			region: 'us-east-2',
		},
		{
			name: 'localhost',
			endpoint: 'http://localhost:8080',
			region: 'us-east-2',
		},
	],
	aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
	aws_cognito_login_mechanism: ['PREFERRED_USERNAME'],
};

export default awsmobile;
