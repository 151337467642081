import { DateTime } from '../../lib/DateTime';
import { CalendarEvent } from '../../lib/types';

export const AgendaGridGutterWidth = 50;
export const AgendaGridRowHeight = 32;
export const AgendaGridTotalRowHeight = AgendaGridRowHeight * 48;

export const TextFieldIconButtonSize = 24;
export const ListItemIconButtonSize = 24;
export const ToolbarIconSize = 28;
export const ToolbarButtonSize = 36;

export const TextFieldMinWidth = 200;
export const TextFieldMode = 'outlined';
export const TextFieldDense = true;
export const TextFieldPlaceholderColor = 'rgba(0,0,0,0.5)';

export const getMultiDay = (
	event: CalendarEvent,
	selectedDate: string
): [boolean, boolean, boolean] => {
	if (event.isAllDay === true) {
		return [false, false, false];
	}
	const selectedStart = new DateTime(selectedDate)
		.toStartOfDay()
		.toDate()
		.getTime();
	const selectedEnd = new DateTime(selectedDate)
		.toEndOfDay()
		.toDate()
		.getTime();
	const start = new DateTime(event.startTime).toDate().getTime();
	const end = new DateTime(event.endTime).toDate().getTime();
	// console.log(`Event: ${event.name}: ${start} - ${end} ~~~ ${selectedStart} - ${selectedEnd}`);
	if (start < selectedStart && end > selectedEnd) {
		return [true, false, false];
	}
	if (start < selectedStart && end < selectedEnd) {
		return [true, false, true];
	}
	if (start > selectedStart && end > selectedEnd) {
		return [true, true, false];
	}
	return [false, false, false];
};

// export const formatDateToString = (dt: Date): string => {
//     return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDay()}`;
// };

// export const addMonths = (input: Date, months: number): Date => {
//     const output = new Date(input);
//     output.setMonth(output.getMonth() + months);
//     return output;
// };

// export const addDays = (input: Date, days: number): Date => {
//     const output = new Date(input);
//     output.setDate(output.getDate() + days);
//     return output;
// };

// export const getNumberOfDays = (input: Date): number => {
//     const nextMonth = addMonths(input, 1);
//     const lastDay = addDays(nextMonth, -1);
//     return lastDay.getDate();
// };

// export const formatTime = (time: string) => {
//     const am = time.includes('AM');
//     const s = time.split(':');
//     return `${s[0]}:${s[1]} ${am ? 'AM' : 'PM'}`;
// };

// export const formatTimeShort = (time: string) => {
//     const am = time.includes('AM');
//     const s = time.split(':');
//     return `${s[0]} ${am ? 'AM' : 'PM'}`;
// };

// export const formatTimestamp = (time: number): string => {
//     const dt = new Date(time);
//     const t = dt.toLocaleTimeString();
//     return formatTime(t);
// };

// export const getMinutesFromMidnight = () => {
//     const dt = new Date();
//     const hours = dt.getHours();
//     const mins = dt.getMinutes();
//     return hours * 60 + mins;
// };

// export const getMinutesFromDate = (dt: Date) => {
//     const hours = dt.getHours();
//     const mins = dt.getMinutes();
//     return hours * 60 + mins;
// };

// export const getMinutesBetweenDates = (start: Date, end: Date): number => {
//     const msInMinute = 60 * 1000;
//     return Math.round(Math.abs(end.getTime() - start.getTime()) / msInMinute);
// };

// export const TotalMinutesInOneDay = 60 * 24;
