import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useMeasure } from 'react-use';

// Icons
import {
	mdiChevronDown,
	mdiChevronUp,
	mdiContentCopy,
	mdiEmailMultipleOutline
} from '@mdi/js';
import Icon from '@mdi/react';

// Components
import { ScheduleMeetingButton } from './ScheduleMeetingButton';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { useConferenceContext } from '../../contexts/ConferenceContext';
import { useLinkingContext } from '../../contexts/LinkingContext';
import { useUserProfileContext } from '../../contexts/UserProfileContext';
import { getAvatarImagePath } from '../../lib/utils';
import { useMeetingActions } from './useMeetingActions';

const WIDTH = 596;

export const PersonalMeeting = () => {
	const theme = useTheme();
	const { isWide } = useAppContext();
	const { meetingInProgress } = useConferenceContext();
	const [ref, { width }] = useMeasure<HTMLDivElement>();
	const [collapsed, setCollapsed] = React.useState(false);

	const {
		formatPin,
		personalMeetingRoom,
		copyInviteLink,
		copyMeetingInvite,
	} = useMeetingActions();
	const { openUrl } = useLinkingContext();

	const { userConfiguration } = useUserProfileContext();
	const isWrapped = width < WIDTH;

	const avatarUrl = userConfiguration
		? getAvatarImagePath(
			userConfiguration.ucpAdminUri,
			userConfiguration.imagePath
		)
		: undefined;

	const handleStartMeeting = async () => {
		openUrl(personalMeetingRoom.url);
	};

	const handleCopyMeetingInvite = () => {
		copyMeetingInvite(personalMeetingRoom);
	};

	const handleCopyInvite = () => {
		copyInviteLink(personalMeetingRoom);
	};

	return (
		<Grid
			container
			direction="column"
			sx={{
				backgroundColor: '#ffffff',
				borderTopRightRadius: theme.spacing(1),
				marginBottom: theme.spacing(1),
			}}
			ref={ref}
		>
			<Grid item>
				<Toolbar
					variant="dense"
					style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
				>
					<Box
						sx={{
							position: 'relative',
							fontSize: theme.typography.body2.fontSize,
							fontWeight: 500,
							color: '#eb9034',
							marginLeft: theme.spacing(-1),
							flexGrow: 1,
						}}
					>
						My Personal Meeting
					</Box>
					<Tooltip title={collapsed ? 'Expand' : 'Collapse'}>
						<IconButton
							size="small"
							edge="start"
							aria-label={collapsed ? 'Expand' : 'Collapse'}
							onClick={() => setCollapsed(!collapsed)}
						>
							<Icon
								path={collapsed ? mdiChevronDown : mdiChevronUp}
								size={1}
							/>
						</IconButton>
					</Tooltip>
				</Toolbar>
			</Grid>
			<Grid
				container
				direction="row"
				sx={{
					flexDirection: !isWide ? 'column' : 'row',
					alignItems: !isWide ? 'center' : 'flex-start',
					justifyContent: 'center',
				}}
			>
				{!collapsed && (
					<Grid
						item
						sx={{
							padding: collapsed
								? theme.spacing(4, 0, 0, 0)
								: theme.spacing(5, 0, 0, 0),
							minWidth: collapsed ? '100px' : '180px',
							maxWidth: collapsed ? '100px' : '230px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'center',
							flexGrow: 1,
						}}
					>
						<Avatar
							variant="circular"
							sizes="2"
							alt={`${userConfiguration?.firstName} ${userConfiguration?.lastName}`}
							sx={{
								width: collapsed
									? theme.spacing(4)
									: theme.spacing(10),
								height: collapsed
									? theme.spacing(4)
									: theme.spacing(10),
							}}
							src={avatarUrl || undefined}
						/>
					</Grid>
				)}
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'column',
						padding: theme.spacing(5.25, 2, 2, 2),
						flexGrow: 1,
					}}
				>
					<Grid
						container
						direction="column"
						spacing={2}
						sx={{
							alignItems: isWrapped
								? 'center'
								: isWide
									? 'flex-start'
									: 'center',
							justifyContent: isWrapped
								? 'center'
								: isWide
									? 'flex-start'
									: 'center',
							// alignItems: 'center',
							// justifyContent: 'center',
						}}
					>
						<Grid
							container
							direction="row"
							wrap="wrap"
							spacing={2}
							sx={{
								alignItems: isWrapped
									? 'center'
									: isWide
										? 'flex-start'
										: 'center',
								justifyContent: isWrapped
									? 'center'
									: isWide
										? 'flex-start'
										: 'center',
								// alignItems: 'center',
								// justifyContent: 'center',
								pl: theme.spacing(2.25),
							}}
						>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleStartMeeting}
									disabled={meetingInProgress}
								>
									Start Meeting
								</Button>
							</Grid>
							<Grid item>
								<ScheduleMeetingButton
									meeting={personalMeetingRoom}
								/>
							</Grid>
						</Grid>
						{/* {!collapsed && (
							<Grid item>
								<Grid
									container
									direction="row"
									wrap="wrap"
									spacing={2}
									sx={{ paddingTop: theme.spacing(1) }}
								>
									<Grid item>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														checked={lobbyEnabled}
														onChange={() =>
															enableLobby(
																!lobbyEnabled
															)
														}
														name="enableLobby"
														color="secondary"
													/>
												}
												label="Enable Lobby"
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Grid>
						)} */}
						<Grid item>
							<Grid
								container
								direction="row"
								wrap="wrap"
								spacing={2}
								sx={{
									paddingTop: theme.spacing(1),
									alignItems: isWrapped
										? 'center'
										: isWide
											? 'flex-start'
											: 'center',
									justifyContent: isWrapped
										? 'center'
										: isWide
											? 'flex-start'
											: 'center',
								}}
							>
								<Grid item>
									<Button
										variant="text"
										color="secondary"
										startIcon={
											<Icon
												path={mdiEmailMultipleOutline}
												size={0.75}
											/>
										}
										onClick={handleCopyMeetingInvite}
										style={{ textTransform: 'none' }}
									>
										Copy Meeting Invite
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="text"
										color="secondary"
										startIcon={
											<Icon
												path={mdiContentCopy}
												size={0.75}
											/>
										}
										onClick={handleCopyInvite}
										style={{ textTransform: 'none' }}
									>
										Copy Meeting Link
									</Button>
								</Grid>
							</Grid>
						</Grid>
						{!collapsed && (
							<Grid
								item
								sx={{
									fontSize: theme.typography.body2.fontSize,
								}}
							>
								Meeting Dial In: +1-614-362-6580
							</Grid>
						)}
						{!collapsed && (
							<Grid
								item
								sx={{
									fontSize: theme.typography.body2.fontSize,
								}}
							>
								PIN:{' '}
								{personalMeetingRoom
									? `${formatPin(personalMeetingRoom.pin)}#`
									: ''}
							</Grid>
						)}
						{!collapsed && <Grid item>&nbsp;</Grid>}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
