/* eslint-disable react/require-default-props */
import { truncate } from 'lodash';
import * as React from 'react';
import deepmerge from 'deepmerge';
import { Theme, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

// Components
import { AgendaListItemAvatarList } from './AgendaListItemAvatarList';
import { FlexRow } from '../common/FlexRow';

// Lib
import { DateTime } from '../../lib/DateTime';
import { CalendarEvent } from '../../lib/types';
import { Colors } from '../../lib/Colors';
import { getMultiDay } from './dateutils';
import { useAppTheme } from '../../contexts/ThemeContext';
import { removeHtml, decodeHtml } from '../../lib/utils';
import { useConferenceContext } from '../../contexts/ConferenceContext';
import { useLinkingContext } from '../../contexts/LinkingContext';

type ViewMode = 'standalone' | 'card';

type StyleMode = {
	card: React.CSSProperties;
	times: React.CSSProperties;
	title: React.CSSProperties;
	multiDay: React.CSSProperties;
};

export type Props = {
	mode: ViewMode;
	item: CalendarEvent;
	scroll: boolean;
	onSelectEvent: () => void;
	selectedDate?: string;
};

const getTimeString = (event: CalendarEvent, selectedDate?: string): string => {
	if (event.isAllDay) {
		return 'All Day';
	}
	if (selectedDate) {
		const [isMultiDay, showStartDate, showEndDate] = getMultiDay(
			event,
			selectedDate
		);
		if (isMultiDay && showStartDate) {
			return `${DateTime.fromEPOCHMilliseconds(
				event.startTime
			).toFormattedShortTime()}`;
		}
		if (isMultiDay && showEndDate) {
			return `ENDS - ${DateTime.fromEPOCHMilliseconds(
				event.endTime
			).toFormattedShortTime()}`;
		}
	}
	return `${DateTime.fromEPOCHMilliseconds(
		event.startTime
	).toFormattedShortTime()} - ${DateTime.fromEPOCHMilliseconds(
		event.endTime
	).toFormattedShortTime()}`;
};

const Margin = 6;

const getDefaultStyles = (
	theme: Theme,
	mode: ViewMode,
	isDarkMode: boolean
): StyleMode => ({
	card: {
		marginLeft: mode === 'card' ? Margin * 2 : 0,
		marginRight: mode === 'card' ? Margin * 2 : 0,
		marginTop: mode === 'card' ? 0 : 0,
		marginBottom: 14,
		backgroundColor: isDarkMode ? Colors.black : Colors.grey100,
		borderRadius: 8,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderRightWidth: 1,
		borderLeftWidth: 2,
		borderStyle: 'solid',
		borderColor: isDarkMode ? Colors.grey200 : Colors.grey400,
	},
	times: {
		color: Colors.grey600,
		overflow: 'hidden',
		position: 'relative',
		// top: 8,
		fontWeight: 'bold',
	},
	title: {
		fontWeight: 'bold',
		overflow: 'hidden',
		paddingBottom: 8,
	},
	multiDay: {
		paddingTop: 8,
		fontWeight: 'normal',
		color: isDarkMode ? Colors.grey500 : Colors.grey600,
	},
});

const getPastStyles = (
	theme: Theme,
	mode: ViewMode,
	isDarkMode: boolean
): StyleMode => ({
	card: {
		backgroundColor: isDarkMode ? Colors.black : Colors.white,
		borderLeftWidth: mode === 'card' ? 2 : 1,
		borderLeftColor: isDarkMode ? Colors.grey800 : Colors.grey400,
	},
	times: {},
	title: {},
	multiDay: {},
});

const getFutureStyles = (
	theme: Theme,
	mode: ViewMode,
	isDarkMode: boolean
): StyleMode => ({
	card: {
		borderColor: isDarkMode ? Colors.grey700 : Colors.grey500,
		backgroundColor: isDarkMode ? Colors.grey900 : Colors.white,
		borderLeftWidth: 2,
		borderLeftColor: Colors.orange800,
	},
	times: {
		// color: Colors.grey500,
	},
	title: {},
	multiDay: {},
});

const getInProgressStyles = (
	theme: Theme,
	mode: ViewMode,
	isDarkMode: boolean
): StyleMode => ({
	card: {
		borderColor: Colors.blue800,
		backgroundColor: isDarkMode ? Colors.grey900 : Colors.white,
		borderLeftWidth: 3,
		borderLeftColor: Colors.blue800,
	},
	times: {
		color: isDarkMode ? Colors.white : Colors.black,
		fontWeight: 'bold',
	},
	title: {
		color: isDarkMode ? Colors.white : Colors.blue800,
	},
	multiDay: {},
});

export const AgendaListItem = ({
	item,
	onSelectEvent,
	mode,
	scroll,
	selectedDate,
}: Props) => {
	const theme = useTheme();
	const { isDarkMode } = useAppTheme();
	const viewRef = React.useRef<HTMLDivElement | null>(null);
	const now = new Date();
	const { openUrl } = useLinkingContext();
	const { meetingInProgress } = useConferenceContext();

	const handleOpenLink = (url: string | null | undefined) => {
		if (!url) return;
		openUrl(url);
	};

	const status =
		item.isAllDay !== true &&
		DateTime.isInRange(
			now,
			new Date(item.startTime),
			new Date(item.endTime)
		);
	const [isMultiDay] = selectedDate
		? getMultiDay(item, selectedDate)
		: [false];

	let statusStyle: StyleMode = getDefaultStyles(theme, mode, isDarkMode);
	switch (status) {
		case 'before':
			statusStyle = deepmerge(
				getDefaultStyles(theme, mode, isDarkMode),
				getPastStyles(theme, mode, isDarkMode)
			);
			break;

		case 'after':
			statusStyle = deepmerge(
				getDefaultStyles(theme, mode, isDarkMode),
				getFutureStyles(theme, mode, isDarkMode)
			);
			break;

		case 'inrange':
			statusStyle = deepmerge(
				getDefaultStyles(theme, mode, isDarkMode),
				getInProgressStyles(theme, mode, isDarkMode)
			);
			break;

		default:
			break;
	}

	const inProgress = status === 'inrange';

	if (scroll && viewRef.current) {
		viewRef.current.scrollIntoView({ behavior: 'smooth' });
	}

	const description = removeHtml(decodeHtml(item?.description || ''));

	return (
		<div data-component="list-view-item" style={statusStyle.card}>
			<Box
				style={{ cursor: 'pointer' }}
				onKeyDown={() => onSelectEvent()}
				onClick={() => onSelectEvent()}
				role="button"
				tabIndex={0}
				sx={{
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				}}
			>
				<div
					style={{
						flexGrow: 1,
						padding: theme.spacing(1, 1, 1, 3),
					}}
				>
					{inProgress && (
						<div
							style={{
								top: isMultiDay ? 85 : 61,
								position: 'relative',
								left: -27,
								width: 0,
								height: 0,
								backgroundColor: 'transparent',
								borderStyle: 'solid',
								borderLeftWidth: 8,
								borderRightWidth: 8,
								borderBottomWidth: 10,
								borderTopWidth: 0,
								borderLeftColor: 'transparent',
								borderRightColor: 'transparent',
								borderBottomColor: Colors.blue800,
								transform: 'rotate(90deg)',
							}}
						/>
					)}
					{isMultiDay && (
						<span style={statusStyle.multiDay}>Multi-Day</span>
					)}
					<FlexRow
						nowrap
						sx={{ alignItems: 'center', paddingBottom: '18px' }}
					>
						<span style={statusStyle.times}>
							{getTimeString(item, selectedDate)}
						</span>
						<div
							ref={viewRef}
							style={{
								position: 'relative',
								flex: 1,
								top: 0,
								right: 6,
							}}
						>
							<AgendaListItemAvatarList
								items={item.participants}
							/>
						</div>
					</FlexRow>
					<FlexRow>
						<span
							style={{
								...statusStyle.title,
							}}
						>
							{item.name}
						</span>
					</FlexRow>
					{!!item.description && (
						<FlexRow>
							<span
								style={{
									overflow: 'hidden',
									flexWrap: 'nowrap',
									color: Colors.grey600,
									paddingLeft: 18,
									paddingBottom: 10,
								}}
							>
								{truncate(description || '', {
									length: 40,
								})}
							</span>
						</FlexRow>
					)}
					{item.conferenceUrl && item.image && (
						<List>
							<Tooltip title="Join Meeting">
								<ListItem
									disabled={meetingInProgress}
									onClick={() =>
										item.conferenceUrl &&
										handleOpenLink(item.conferenceUrl)
									}
									sx={{
										'&:hover': {
											backgroundColor:
												theme.palette.action.hover,
										},
									}}
									style={{ borderRadius: theme.spacing(1) }}
								>
									<ListItemText
										primary={truncate(item.conferenceUrl, {
											length: 40,
										})}
										primaryTypographyProps={{
											style: {
												color: Colors.blue800,
											},
										}}
									/>
									{item.conferenceUrl && item.image && (
										<ListItemSecondaryAction
											style={{ right: '8px' }}
										>
											<IconButton
												size="small"
												edge="end"
												disabled={meetingInProgress}
												onClick={() =>
													item.conferenceUrl &&
													handleOpenLink(
														item.conferenceUrl
													)
												}
											>
												<img
													alt=""
													src={item.image}
													width="26"
													height="26"
												/>
											</IconButton>
										</ListItemSecondaryAction>
									)}
								</ListItem>
							</Tooltip>
						</List>
					)}
				</div>
			</Box>
		</div>
	);
};
