export const defaultJitsiServer = 'meet.accentvoice.com';

export interface EventMapper {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	map: (event: any) => CalendarEvent;
}

export const StorageKeys = {
	AwsAuth: 'accent-auth-aws',
	MicrosoftAuth: 'accent-auth-microsoft',
	GoogleAuth: 'accent-auth-google',
	LinkedInAuth: 'accent-auth-linkedin',
	DeviceConfig: 'accent-config-device',
	AppConfig: 'accent-config-appcached',
};

export interface AppLink {
	protocol: string;
	scope: string;
	action: string;
	data: string;
}

export interface WebUser {
	username: string;
	sub: string;
	groups: string[];
}

export type ConfigSetting = {
	key: string;
	value: string;
};

export type AppAssetKeys = 'loginBackgroundImage' | 'loginLogoImage';
export type AppEmailInviteKeys = 'subject' | 'body';
export type AppExpressionKeys = 'url' | 'urlNoProtocol' | 'email';
export type AppSetting<T extends string> = Record<T, string>;

export type IntegrationProviderSetting = {
	name: string;
	signinImage: string;
	logoImage: string;
};

export type ConferenceProviderSetting = {
	name: string;
	image: string;
	matchers: string[];
};

export interface AwsGatewayApiConfig {
	name: string;
	endpoint: string;
}

export interface AwsAmplifyConfig {
	region: string;
	cognitoIdentityPoolId: string;
	userPoolsId: string;
	userPoolsWebClientId: string;
	apis: AwsGatewayApiConfig[];
}

export interface AppStateConfig {
	aws: AwsAmplifyConfig;
	copyright: string;
	cloudPortalUrl: string;
	aboutUsLinks: ConfigSetting[];
	helpMenuLinks: ConfigSetting[];
	assets: AppSetting<AppAssetKeys>;
	conferenceProviders: ConferenceProviderSetting[];
	integrationProviders: IntegrationProviderSetting[];
	invite: AppSetting<AppEmailInviteKeys>;
	expressions: AppSetting<AppExpressionKeys>;
	meetingServers: ConfigSetting[];
}

export interface UserConfiguration {
	userId: number;
	authId: string;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	ucpUri: string;
	videoServer: string;
	ucpAdminUri: string;
	imagePath: string | null;
	tenant: string;
	ext: number;
	did: string;
	room: string;
	pin: string;
}

export type MeetingType = 'Personal' | 'Channel' | 'Room' | 'Public';

export interface Meeting {
	id: number;
	url: string;
	room: string;
	dt: string;
	pin: string;
	favorite: boolean;
	topic: string;
	type: MeetingType;
	server?: string;
}

export interface UCPProvider {
	server: string;
	user: string;
	pass: string;
}

export interface JoinConferenceRequest {
	authId: string;
	server: string;
	room: string;
	flags: Record<string, boolean>;
}

export interface CalendarClass {
	isConnected: () => Promise<boolean>;
	connect: () => Promise<boolean>;
	disconnect: () => Promise<boolean>;
	getEvents: (start: Date, end: Date) => Promise<CalendarEvent[]>;
}

export interface EventMapper {
	map: (event: any) => CalendarEvent;
}

export interface ConferenceInfo {
	meeting: Meeting;
	token: string | null;
	user: UserConfiguration;
}

export interface ConferenceProvider {
	conferenceName: string;
	image: string | null;
	conferenceUrl: string | null;
	urls: string[];
}

export type EventParticipant = {
	id: string;
	name?: string | null;
	email?: string | null;
	status?: string | null;
};

export interface CalendarEvent extends ConferenceProvider {
	id: string;
	name?: string | null;
	description?: string | null;
	location?: string | null;
	startTime: number;
	endTime: number;
	duration: number;

	date: string;
	isAllDay: boolean;
	calendarUrl?: string | null;
	participants: EventParticipant[];
	provider: string;
}

export interface LaunchMeetingConfig {
	server: string;
	room: string;
	lobbyEnabled: boolean;
	displayName: string;
	email: string;
	muteAudio: boolean;
	muteVideo: boolean;
}

export interface PreMeetingConfig {
	onClose: () => void;
	launch: (config: LaunchMeetingConfig) => void;
	server: string;
	room: string;
	lobbyEnabled?: boolean;
	displayName?: string;
	email?: string;
	isModerator: boolean;
}

export interface JitsiMeetUserInfo {
	displayName?: string;
	email?: string;
	avatar?: string;
}
export interface JitsiMeetConferenceOptions {
	room: string;
	serverUrl?: string;
	userInfo?: JitsiMeetUserInfo;
	token?: string;
	subject?: string;
	audioOnly?: boolean;
	audioMuted?: boolean;
	videoMuted?: boolean;
	featureFlags?: {
		[key: string]: boolean;
	};
}

export const defaultAppConfig: AppStateConfig = {
	copyright: 'Accent Communication Services, Inc. All rights reserved.',
	aws: {
		region: 'us-east-2',
		cognitoIdentityPoolId: 'us-east-2:1a5185b6-e480-403c-9742-1fb61525877f',
		userPoolsId: 'us-east-2_tsA4r5WHK',
		userPoolsWebClientId: '7udvu07d9qof3m4qg3t4g909vr',
		apis: [
			{
				name: 'api',
				endpoint:
					'https://s8xhsc5n3f.execute-api.us-east-2.amazonaws.com/prod',
			},
		],
	},
	cloudPortalUrl: 'https://{subdomain}.accentservices.com/',
	aboutUsLinks: [
		{
			key: 'Online User Guide',
			value: 'https://guides.accentvoice.com/desktop-user-guide/',
		},
		{
			key: 'Privacy Policy',
			value: 'https://www.accentvoice.com/privacy-policy',
		},
		{
			key: 'Visit Us',
			value: 'https://www.accentvoice.com/',
		},
	],
	helpMenuLinks: [
		{
			key: 'Visit Us',
			value: 'https://accentvoice.com/',
		},
		{
			key: 'User Guide',
			value: 'https://guides.accentvoice.com/desktop-user-guide/',
		},
	],
	assets: {
		loginBackgroundImage:
			'https://meet-cdn.voiceonecloud.com/AccentDesktopBackdrop.png',
		loginLogoImage: 'https://meet-cdn.voiceonecloud.com/accent.png',
	},
	conferenceProviders: [
		{
			name: 'Google',
			image: 'https://meet-cdn.voiceonecloud.com/google.png',
			matchers: ['meet.google.com'],
		},
		{
			name: 'Microsoft',
			image: 'https://meet-cdn.voiceonecloud.com/microsoftteams.png',
			matchers: ['teams.microsoft.com', '/meetup-join'],
		},
		{
			name: 'Zoom',
			image: 'https://meet-cdn.voiceonecloud.com/zoom.png',
			matchers: ['zoom.us'],
		},
		{
			name: 'WebEx',
			image: 'https://meet-cdn.voiceonecloud.com/webex.png',
			matchers: ['webex.com/meet'],
		},
		{
			name: 'GoToMeeting',
			image: 'https://meet-cdn.voiceonecloud.com/gotomeeting.png',
			matchers: ['gotomeeting.com'],
		},
		{
			name: 'JoinMe',
			image: 'https://meet-cdn.voiceonecloud.com/joinme.png',
			matchers: ['join.me'],
		},
		{
			name: 'Jitsi',
			image: 'https://meet-cdn.voiceonecloud.com/jitsi.png',
			matchers: ['meet.jit.si'],
		},
		{
			name: 'Blue Jeans',
			image: 'https://meet-cdn.voiceonecloud.com/bluejeans.png',
			matchers: ['bluejeans.com'],
		},
		{
			name: 'Apple Facetime',
			image: 'https://meet-cdn.voiceonecloud.com/apple.png',
			matchers: ['facetime.apple.com'],
		},
		{
			name: 'Accent',
			image: 'https://meet-cdn.voiceonecloud.com/camera.png',
			matchers: ['accentvoice.com'],
		},
	],
	integrationProviders: [
		{
			name: 'Google',
			logoImage: 'https://meet-cdn.voiceonecloud.com/google.png',
			signinImage:
				'https://meet-cdn.voiceonecloud.com/btn_google_signin_dark_normal_web.svg',
		},
		{
			name: 'Microsoft',
			logoImage: 'https://meet-cdn.voiceonecloud.com/microsoftteams.png',
			signinImage:
				'https://meet-cdn.voiceonecloud.com/ms-symbollockup_signin_light.svg',
		},
		{
			name: 'LinkedIn',
			logoImage: 'https://meet-cdn.voiceonecloud.com/linkedin.png',
			signinImage:
				'https://meet-cdn.voiceonecloud.com/linkedin-small-default.png',
		},
	],
	invite: {
		subject: 'Accent Video Meeting with {firstname} {lastname}',
		body: '{firstname} {lastname} has invited you to an Accent Video Meeting:%0d%0a\n    %0d%0a\n    Meeting Link: {link}%0d%0a\n    Dial-In (Optional): (614) 362-6580%0d%0a\n    Meeting PIN: {meetingPin}%23%0d%0a\n    Mobile One-Click: 614-362-6580,{rawMeetingPin}%23%0d%0a\n    %0d%0a',
	},
	meetingServers: [
		{
			key: 'default',
			value: 'meet.accentvoice.com',
		},
		{
			key: 'dev1-meet',
			value: 'dev1-meet.accentvoice.com',
		},
	],
	expressions: {
		url: '/https?://(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/gi',
		urlNoProtocol:
			'/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/gi',
		email: '/^([\\w\\.\\-_]+)?\\w+@[\\w-_]+(\\.\\w+){1,}$/gi',
	},
};
