import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Components
import { PersonalMeeting } from './meetings/PersonalMeeting';
import { MeetingList } from './meetings/MeetingList';
import { MeetingDetail } from './meetings/MeetingDetail';
import { NoMeetings } from './meetings/NoMeetings';
import {
	selectMeetings,
	selectSelectedMeetingId,
	useAppSelector,
} from '../store';
import { useAppContext } from '../contexts/AppContext';

export const Meetings = () => {
	const theme = useTheme();
	const { isWide } = useAppContext();
	const history = useAppSelector(selectMeetings);
	const selectedMeetingId = useAppSelector(selectSelectedMeetingId);

	if (history.length === 0) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					position: 'relative',
					height: '100%',
					backgroundColor: '#fff',
					borderRadius: theme.spacing(1),
				}}
			>
				<NoMeetings />
			</Box>
		);
	}

	if (!isWide && !!selectedMeetingId) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					position: 'relative',
					height: '100%',
					backgroundColor: '#fff',
					borderRadius: theme.spacing(1),
				}}
			>
				<MeetingDetail showBackButton={true} />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				position: 'relative',
				height: '100%',
				backgroundColor: '#fff',
				borderRadius: theme.spacing(1),
			}}
		>
			<Grid
				container
				direction="row"
				wrap="nowrap"
				style={{ flexGrow: 1, height: '100%' }}
			>
				<Grid
					item
					sx={{
						display: 'flex',
						flexDirection: 'column',
						position: 'relative',
						borderRadius: 1,
						flexGrow: 1,
						width: '40%',
					}}
				>
					<PersonalMeeting />
					<MeetingList />
				</Grid>
				{isWide && (
					<Grid
						item
						sx={{
							position: 'relative',
							height: '100%',
							width: '8px',
							backgroundColor: '#f6f6f6',
							display: 'flex',
						}}
					>
						&nbsp;
					</Grid>
				)}
				{isWide && (
					<Grid
						item
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							position: 'relative',
							borderRadius: theme.spacing(1),
							width: '60%',
							height: '100%',
						}}
					>
						<MeetingDetail showBackButton={false} />
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
