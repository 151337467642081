import React, { useContext } from 'react';
import { useApplicationConfigurationContext } from './ApplicationConfiguration';
import { useConferenceContext } from './ConferenceContext';

export type LinkingContextData = {
	openUrl: (url?: string | null) => boolean;
};

const initialValue: LinkingContextData = {
	openUrl: () => false,
};

export const LinkingContext =
	React.createContext<LinkingContextData>(initialValue);

export interface Props {
	children: React.ReactNode;
}

export const LinkingContextProvider = ({ children }: Props) => {
	const { isMeetingLink } = useApplicationConfigurationContext();
	const { startMeeting } = useConferenceContext();

	const openUrl = React.useCallback(
		(url?: string | null) => {
			if (url) {
				if (isMeetingLink(url)) {
					startMeeting(url);
					return true;
				} else {
					window.open(url);
					return true;
				}
			}
			return false;
		},
		[isMeetingLink, startMeeting]
	);

	return (
		<LinkingContext.Provider value={{ openUrl }}>
			{children}
		</LinkingContext.Provider>
	);
};

export const useLinkingContext = (): LinkingContextData => {
	return useContext(LinkingContext);
};
