import {
	PublicClientApplication,
	AuthenticationResult,
	Configuration,
	LogLevel,
	AccountInfo,
} from '@azure/msal-browser';

// const CLIENT_ID = '49c766ad-3ce1-4ed1-8628-609332127d13';
const CLIENT_ID = '212dd699-c7ac-40cf-8f5e-1e3d0badc909';
const AUTHORITY = 'https://login.microsoftonline.com/common';

const REQUEST_SCOPES = [
	'openid',
	'profile',
	'User.Read',
	'Calendars.Read',
	'Calendars.Read.Shared',
];

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL Node configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-node/docs/configuration.md
 */
const MSAL_CONFIG: Configuration = {
	auth: {
		clientId: CLIENT_ID,
		authority: AUTHORITY,
		// redirectUri: REDIRECT_URI,
	},
	cache: {
		cacheLocation: 'localStorage',
	},
	system: {
		loggerOptions: {
			loggerCallback(_loglevel: unknown, message: string) {
				console.log(message);
			},
			piiLoggingEnabled: false,
			logLevel: LogLevel.Warning,
		},
	},
};

export class MicrosoftAuthProvider {
	private name: string;
	private myMSALObj: PublicClientApplication;
	public account: AccountInfo | null;

	constructor(name: string) {
		this.name = name;
		this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
		this.account = null;
	}

	// TODO: Add account chooser code
	public getAccount(): AccountInfo | null {
		// const currentAccounts = this.myMSALObj.getAllAccounts();

		// if (currentAccounts === null) {
		// 	return null;
		// }

		// if (currentAccounts.length > 1) {
		// 	this.account = currentAccounts[0];
		// } else if (currentAccounts.length === 1) {
		// 	this.account = currentAccounts[0];
		// }
		this.account = this.myMSALObj.getActiveAccount();
		return this.account;
	}

	public async login(): Promise<AccountInfo | null> {
		try {
			const response: AuthenticationResult =
				await this.myMSALObj.loginPopup({
					scopes: REQUEST_SCOPES,
				});

			if (response !== null) {
				this.account = response.account;
				this.myMSALObj.setActiveAccount(this.account);
			} else {
				this.account = this.getAccount();
			}

			return this.account;
		} catch (error) {
			this.logout();
			return null;
		}
	}

	public async logout(): Promise<void> {
		this.myMSALObj.setActiveAccount(null);
		return;

		// await this.myMSALObj.logoutPopup({
		// 	account: this.account || undefined,
		// });
	}

	public async getToken(): Promise<string | null> {
		if (!this.account) return null;
		try {
			const response = await this.myMSALObj.acquireTokenSilent({
				account: this.account,
				scopes: REQUEST_SCOPES,
				forceRefresh: true,
			});
			return response.accessToken;
		} catch (error) {
			console.error(error);
			return null;
		}
	}
}
