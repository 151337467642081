import { api } from '../api';
import { WebUser } from './user';

export * from './user';
export * from './icons';
export * from './validation';

export const UI_BACKGROUNDCOLOR = '#f6f6f6';

export type ApplicationContextData = {
	api: typeof api;
	user: WebUser | null;
	signout: () => void;
	signin: (username: string, password: string) => void;
	isOnline: boolean;
	version: string;
	isWide: boolean;
	ucpWindowRef: Window | null;
	setUcpWindowRef: (ref: Window | null) => void;
};
