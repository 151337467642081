import * as React from 'react';

// Lib
import { Storage } from '../lib/storage';
import {
	selectLastLoginUsername,
	selectPrivateDevice,
	setLastUsername,
	setRememberMe,
	useAppDispatch,
	useAppSelector,
} from '../store';

export const PreferencesStorageKey = 'accent-preferences';
export type ThemeOption = 'Light' | 'Dark' | 'System';

export interface Preferences {
	theme: ThemeOption;
	lastUsername?: string;
	privateDevice?: boolean;
}

export interface PreferencesContextValue {
	preferences: Preferences;
	setTheme: (option: ThemeOption) => void;
}

const initialPreferences: PreferencesContextValue = {
	preferences: {
		theme: 'System',
	},
	setTheme: () => {},
};

export const PreferencesContext =
	React.createContext<PreferencesContextValue>(initialPreferences);

export interface Props {
	children: React.ReactNode;
}

export const PreferencesContextProvider = ({ children }: Props) => {
	const dispatch = useAppDispatch();
	const lastUsername = useAppSelector(selectLastLoginUsername);
	const privateDevice = useAppSelector(selectPrivateDevice);

	const [preferences, setPreferences] = React.useState<Preferences>(
		initialPreferences.preferences
	);

	const loadPreferences = React.useCallback(async () => {
		const prefs = await Storage.get(
			PreferencesStorageKey,
			initialPreferences.preferences
		);
		setPreferences(prefs);
		dispatch(setRememberMe(prefs.privateDevice === true));
		if (prefs.lastUsername) dispatch(setLastUsername(prefs.lastUsername));
	}, [dispatch]);

	React.useEffect(() => {
		loadPreferences();
	}, [loadPreferences]);

	React.useEffect(() => {
		Storage.set(PreferencesStorageKey, preferences);
	}, [preferences]);

	React.useEffect(() => {
		setPreferences((s) => ({ ...s, lastUsername }));
	}, [lastUsername]);

	React.useEffect(() => {
		setPreferences((s) => ({
			...s,
			privateDevice: privateDevice === true,
		}));
	}, [privateDevice]);

	const setTheme = (option: ThemeOption) => {
		setPreferences((s) => ({ ...s, theme: option }));
	};

	const value: PreferencesContextValue = {
		preferences,
		setTheme,
	};

	return (
		<PreferencesContext.Provider value={value}>
			{children}
		</PreferencesContext.Provider>
	);
};

export const usePreferencesContext = (): PreferencesContextValue => {
	return React.useContext(PreferencesContext);
};
