import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import userReducer from './user';
import meetingsReducer from './meetings';
import calendarReducer from './calendar';

export * from './user';
export * from './meetings';
export * from './calendar';

// Store
export const store = configureStore({
	reducer: {
		user: userReducer,
		meetings: meetingsReducer,
		calendar: calendarReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
