/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import {
	mdiLinkVariant,
	mdiCalendarMonthOutline,
	mdiLockOutline,
	mdiPhone,
	mdiChevronLeft,
} from '@mdi/js';

// Components
import { NothingHere } from '../common/NothingHere';

// Lib
import { useAppDispatch, setSelectedMeetingId } from '../../store';
import { useMeetingActions } from './useMeetingActions';
import { useLinkingContext } from '../../contexts/LinkingContext';
import { useConferenceContext } from '../../contexts/ConferenceContext';

type Props = {
	showBackButton: boolean;
};

export const MeetingDetail = (props: Props) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { openUrl } = useLinkingContext();
	const { meetingInProgress } = useConferenceContext();
	const { showBackButton } = props;

	const {
		formatPin,
		formatDate,
		selectedMeeting,
		copyInviteLink,
		personalMeetingRoom,
	} = useMeetingActions();

	const isMyMeeting = personalMeetingRoom.url === selectedMeeting?.url;

	const handleCloseDetails = () => {
		dispatch(setSelectedMeetingId(undefined));
	};

	const handleStartMeeting = async () => {
		if (selectedMeeting) {
			openUrl(selectedMeeting.url);
		}
	};

	const handleCopyMeetingLink = async () => {
		if (selectedMeeting) {
			copyInviteLink(selectedMeeting);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				backgroundColor: '#ffffff',
			}}
		>
			<Toolbar
				variant="dense"
				sx={{
					borderBottom: '1px solid rgba(0,0,0,0.1)',
					pl: 1,
				}}
			>
				{showBackButton && (
					<Tooltip title="Close Details">
						<IconButton
							size="small"
							edge="start"
							aria-label="Close Details"
							onClick={handleCloseDetails}
						>
							<Icon path={mdiChevronLeft} size={1} />
						</IconButton>
					</Tooltip>
				)}
				<Box
					sx={{
						position: 'relative',
						fontSize: theme.typography.body2.fontSize,
						fontWeight: 500,
						color: '#eb9034',
						marginLeft: theme.spacing(1),
						flexGrow: 1,
					}}
				>
					Meeting Detail
				</Box>
			</Toolbar>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					position: 'relative',
					height: '100%',
					overflowY: 'auto',
					padding: theme.spacing(2),
				}}
			>
				{!selectedMeeting && (
					<NothingHere text="Select a meeting to view more details" />
				)}
				{!!selectedMeeting && (
					<>
						<h3 style={{ padding: theme.spacing(0, 2) }}>
							{selectedMeeting.room}
						</h3>
						<List sx={{ paddingBottom: theme.spacing(1) }}>
							<ListItem
								button
								onClick={() =>
									window.open(`tel:1-614-362-6580`)
								}
							>
								<ListItemIcon>
									<Icon path={mdiPhone} size={1} />
								</ListItemIcon>
								<ListItemText
									primary="+1-614-362-6580"
									secondary="Meeting Dial In"
								/>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<Icon path={mdiLockOutline} size={1} />
								</ListItemIcon>
								<ListItemText
									primary={`${formatPin(
										selectedMeeting.pin
									)}#`}
									secondary="Meeting PIN"
								/>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<Icon
										path={mdiCalendarMonthOutline}
										size={1}
									/>
								</ListItemIcon>
								<ListItemText
									primary={formatDate(
										new Date(selectedMeeting.dt)
									)}
									secondary="Last Joined"
								/>
							</ListItem>
							<ListItem button onClick={handleCopyMeetingLink}>
								<ListItemIcon>
									<Icon path={mdiLinkVariant} size={1} />
								</ListItemIcon>
								<ListItemText
									primary={selectedMeeting.url}
									secondary="Conference Link"
								/>
							</ListItem>
						</List>
						<span style={{ paddingBottom: theme.spacing(1) }} />

						<Grid
							container
							direction="row"
							alignContent="center"
							justifyContent="flex-start"
							wrap="wrap"
							spacing={2}
							sx={{ padding: theme.spacing(2, 2, 4, 4) }}
						>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleStartMeeting}
									disabled={meetingInProgress}
								>
									{isMyMeeting
										? 'Start Meeting'
										: 'Join Meeting'}
								</Button>
							</Grid>
						</Grid>
					</>
				)}
			</Box>
		</Box>
	);
};
