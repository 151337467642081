
import { ConfigSetting, Meeting, MeetingHistory, UCPProvider, UserConfiguration, UserDevice } from '../lib/common';
import { ConferenceInfo, JoinConferenceRequest } from '../lib/types';

import { ApiClientInterface } from './client';

export default class UserApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.addDeviceUsage = this.addDeviceUsage.bind(this);
		this.addSetting = this.addSetting.bind(this);
		this.updateSetting = this.updateSetting.bind(this);
		this.removeSetting = this.removeSetting.bind(this);
		this.getSettings = this.getSettings.bind(this);
		this.getUserConfiguration = this.getUserConfiguration.bind(this);
		this.getUserUCPAuth = this.getUserUCPAuth.bind(this);
		this.syncUsers = this.syncUsers.bind(this);
		this.addMeeting = this.addMeeting.bind(this);
		this.updateMeeting = this.updateMeeting.bind(this);
		this.removeMeeting = this.removeMeeting.bind(this);
		this.getMeetings = this.getMeetings.bind(this);
		this.removeMeetingFavorite = this.removeMeetingFavorite.bind(this);
		this.addMeetingFavorite = this.addMeetingFavorite.bind(this);
	}

	public async addDeviceUsage(
		authId: string,
		device: Partial<UserDevice>
	): Promise<void> {
		await this.client.post<Partial<UserDevice>, void>(
			`/v2/user/${authId}/device`,
			device
		);
	}

	public async addSetting(
		authId: string,
		setting: ConfigSetting
	): Promise<ConfigSetting | null> {
		const response = await this.client.post<ConfigSetting, ConfigSetting>(
			`/user/${authId}/setting`,
			setting
		);
		return response?.result || null;
	}

	public async updateSetting(
		authId: string,
		setting: ConfigSetting
	): Promise<ConfigSetting | null> {
		const response = await this.client.put<ConfigSetting, ConfigSetting>(
			`/user/${authId}/setting/${setting.key}`,
			setting
		);
		return response?.result || null;
	}

	public async removeSetting(
		authId: string,
		key: string
	): Promise<ConfigSetting | null> {
		const response = await this.client.del<ConfigSetting>(
			`/user/${authId}/setting/${key}`
		);
		return response?.result || null;
	}

	public async getSettings(
		authId: string,
		keys: string[]
	): Promise<ConfigSetting[]> {
		const response = await this.client.get<ConfigSetting[]>(
			`/user/${authId}/setting?keys=${keys.join(',')}`
		);
		return response?.result || [];
	}

	public async getUserConfiguration(
		authId: string
	): Promise<UserConfiguration> {
		const response = await this.client.get<UserConfiguration>(
			`/user/${authId}/configuration`
		);
		return response.result;
	}

	public async getUserUCPAuth(authId: string): Promise<UCPProvider | null> {
		const response = await this.client.get<UCPProvider>(
			`/user/${authId}/ucp`
		);
		return response?.result || null;
	}

	public async syncUsers(): Promise<any | null> {
		const response = await this.client.get<any>(`/sync/users`);
		return response?.result || null;
	}

	// Meetings
	public async addMeeting(
		authId: string,
		meeting: Meeting
	): Promise<Meeting | null> {
		const response = await this.client.post<Meeting, Meeting>(
			`/user/${authId}/meeting`,
			meeting
		);
		return response?.result || null;
	}

	public async updateMeeting(
		authId: string,
		meeting: Meeting
	): Promise<Meeting | null> {
		const response = await this.client.put<Meeting, Meeting>(
			`/user/${authId}/meeting/${meeting.id}`,
			meeting
		);
		return response?.result || null;
	}

	public async removeMeeting(
		authId: string,
		id: number
	): Promise<Meeting | null> {
		const response = await this.client.del<Meeting>(
			`/user/${authId}/meeting/${id}`
		);
		return response?.result || null;
	}

	public async getMeetings(
		authId: string,
		limit: number = 20,
		offset: number = 0
	): Promise<Meeting[]> {
		const response = await this.client.get<MeetingHistory[]>(
			`/v2/user/${authId}/meeting?limit=${limit}&offset=${offset}`
		);
		return response?.result || [];
	}

	// Meeting Favorites
	public async addMeetingFavorite(
		authId: string,
		url: string
	): Promise<void> {
		await this.client.post<{ url: string }, void>(
			`/user/${authId}/meetingfavorite`,
			{ url }
		);
	}

	public async removeMeetingFavorite(
		authId: string,
		url: string
	): Promise<void> {
		await this.client.del<void>(
			`/user/${authId}/meetingfavorite/${encodeURIComponent(url)}`
		);
	}

	public async joinMeeting(
		authId: string,
		room: string,
		server: string,
		flags: Record<string, boolean>
	): Promise<ConferenceInfo> {
		const response = await this.client.post<
			JoinConferenceRequest,
			ConferenceInfo
		>(`/user/${authId}/conference/join`, { authId, room, server, flags });
		return response?.result || null;
	}

	public async getMeetingRoomPin(
		authId: string,
		room: string
	): Promise<string> {
		const response = await this.client.get<string>(
			`/user/${authId}/meetingpin/${room}`
		);
		return response.result;
	}
}
