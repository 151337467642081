import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import '@aws-amplify/ui-react/styles.css';

// Components
import Routes from './Routes';
import { AppContext } from './contexts/AppContext';

// Lib
import { store } from './store';
import { UserProfileContextProvider } from './contexts/UserProfileContext';
import { PreferencesContextProvider } from './contexts/PreferencesContext';
import { SnackContextProvider } from './contexts/SnackContext';
import { ApplicationConfigurationContextProvider } from './contexts/ApplicationConfiguration';
import { MeetingsContextProvider } from './contexts/MeetingsContext';
import { CalendarContextProvider } from './contexts/CalendarContext';
import { LinkingContextProvider } from './contexts/LinkingContext';
import { ConferenceContextProvider } from './contexts/ConferenceContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { JustAddAWebPageItsEasyProvider } from './contexts/JustAddAWebPageItsEasy';

const inputGlobalStyles = <GlobalStyles styles={{}} />;

ReactDOM.render(
	<React.StrictMode>
		<CssBaseline />
		{inputGlobalStyles}
		<ReduxProvider store={store}>
			<JustAddAWebPageItsEasyProvider>
				<AppContext>
					<UserProfileContextProvider>
						<PreferencesContextProvider>
							<ThemeContextProvider>
								<SnackContextProvider>
									<ApplicationConfigurationContextProvider>
										<ConferenceContextProvider>
											<LinkingContextProvider>
												<MeetingsContextProvider>
													<CalendarContextProvider>
														<AmplifyProvider>
															<Router>
																<Routes />
															</Router>
														</AmplifyProvider>
													</CalendarContextProvider>
												</MeetingsContextProvider>
											</LinkingContextProvider>
										</ConferenceContextProvider>
									</ApplicationConfigurationContextProvider>
								</SnackContextProvider>
							</ThemeContextProvider>
						</PreferencesContextProvider>
					</UserProfileContextProvider>
				</AppContext>
			</JustAddAWebPageItsEasyProvider>
		</ReduxProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
