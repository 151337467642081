import { useSnackbar } from 'notistack';

// Lib
import { Meeting } from '../../lib/types';
import { useAppContext } from '../../contexts/AppContext';
import { useUserProfileContext } from '../../contexts/UserProfileContext';
import { useApplicationConfigurationContext } from '../../contexts/ApplicationConfiguration';
import { formatDate, replaceAll, formatPin } from '../../lib/utils';
import {
	useAppDispatch,
	useAppSelector,
	setMeetings,
	selectMeetings,
	setSelectedMeetingId,
	selectSelectedMeetingId,
	selectPersonalMeeting,
	selectLobbyEnabled,
	setLobbyEnabled,
	setScheduledMeetingButtonIndex,
} from '../../store';
import { useCallback } from 'react';

const formatGoogleDate = (date: Date) => {
	const yyyy = date.getUTCFullYear();
	const mm = String(date.getUTCMonth() + 1).padStart(2, '0');
	const dd = String(date.getUTCDate()).padStart(2, '0');
	const hh = String(date.getUTCHours()).padStart(2, '0');
	const m = String(date.getUTCMinutes()).padStart(2, '0');
	return `${yyyy}${mm}${dd}T${hh}${m}00Z`;
};

export const useMeetingActions = () => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();
	const { api, user } = useAppContext();
	const { appConfig } = useApplicationConfigurationContext();
	const { userConfiguration } = useUserProfileContext();
	const history = useAppSelector(selectMeetings);
	const selectedMeetingId = useAppSelector(selectSelectedMeetingId);
	const personalMeetingRoom = useAppSelector(selectPersonalMeeting);
	const lobbyEnabled = useAppSelector(selectLobbyEnabled);

	const authId = user ? user.sub : undefined;

	const selectMeeting = (meeting: Meeting) => {
		dispatch(setSelectedMeetingId(meeting.id));
	};

	const enableLobby = (enabled: boolean) =>
		dispatch(setLobbyEnabled(enabled));

	const refreshMeetings = async () => {
		if (authId) {
			try {
				const meetings = await api.user.getMeetings(authId, 10, 0);
				dispatch(setMeetings(meetings));
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		}
	};

	const loadMoreMeetings = useCallback(async () => {
		if (authId) {
			try {
				const meetings = await api.user.getMeetings(
					authId,
					10,
					history.length
				);
				dispatch(setMeetings(meetings));
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		}
	}, [api.user, authId, dispatch, history.length]);

	const addMeeting = async (meeting: Meeting) => {
		if (authId) {
			try {
				await api.user.addMeeting(authId, meeting);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				throw error;
			} finally {
				await refreshMeetings();
			}
		}
	};

	const toggleMeetingFavorite = async (meeting: Meeting) => {
		if (authId) {
			const fn = meeting.favorite
				? api.user.removeMeetingFavorite
				: api.user.addMeetingFavorite;

			try {
				await fn(authId, meeting.url);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			} finally {
				await refreshMeetings();
			}
		}
	};

	const copyInviteLink = (meeting: Meeting) => {
		const { url } = meeting;
		global.navigator.clipboard.writeText(url);
		enqueueSnackbar('Meeting Link copied', { variant: 'success' });
	};

	const getInviteBody = (meeting: Meeting) => {
		let result = appConfig.invite.body;
		console.log('getInviteBody', userConfiguration, result);
		result = replaceAll(result, '{link}', `${meeting.url}`);
		result = replaceAll(
			result,
			'{meetingPin}',
			`${formatPin(meeting.pin)}`
		);
		result = replaceAll(result, '{rawMeetingPin}', `${meeting.pin}`);
		result = replaceAll(
			result,
			'{firstname}',
			userConfiguration?.firstName || ''
		);
		result = replaceAll(
			result,
			'{lastname}',
			userConfiguration?.lastName || ''
		);
		return result;
	};

	const getInviteSubject = () => {
		let result = appConfig.invite.subject;
		result = replaceAll(
			result,
			'{firstname}',
			userConfiguration?.firstName || ''
		);
		result = replaceAll(
			result,
			'{lastname}',
			userConfiguration?.lastName || ''
		);
		return result;
	};

	const copyMeetingInvite = (meeting: Meeting) => {
		let result = getInviteBody(meeting);
		result = replaceAll(result, '%0d%0a', '');
		result = replaceAll(result, '%23', '#');
		global.navigator.clipboard.writeText(result);
		enqueueSnackbar('Meeting Invite copied', { variant: 'success' });
	};

	// https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220112T180000Z%2F20220112T200000Z&details=Learn%20all%20about%20the%20rules%20of%20the%20Motorway%20and%20how%20to%20access%20the%20fast%20lane.%0A%0Ahttps%3A%2F%2Fen.wikipedia.org%2Fwiki%2FGridlock_%28Doctor_Who%29&location=New%20Earth&text=Welcome%20to%20the%20Motorway
	const openGoogleCalendarEvent = (
		meeting: Meeting,
		start?: Date,
		end?: Date
	) => {
		const subject = getInviteSubject();
		const body = getInviteBody(meeting);
		const location = meeting.url;
		// const start = ''; // 2022-01-12T18:00:00+00:00
		// const end = ''; // 2022-01-12T20:00:00+00:00
		// const inviteUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}/${end}&details=${body}&location=${location}&text=${subject}`;
		let inviteUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&location=${location}&text=${subject}&details=${body}`;

		if (start && end) {
			// YYYYMMDDThhmmssZ
			// 20221107T211400Z
			inviteUrl = `${inviteUrl}&dates=${formatGoogleDate(
				start
			)}/${formatGoogleDate(end)}`;
		}

		window.open(inviteUrl);
		dispatch(setScheduledMeetingButtonIndex(1));
	};

	// https://outlook.office.com/calendar/0/deeplink/compose?body=Learn%20all%20about%20the%20rules%20of%20the%20Motorway%20and%20how%20to%20access%20the%20fast%20lane.%0A%0Ahttps%3A%2F%2Fen.wikipedia.org%2Fwiki%2FGridlock_%28Doctor_Who%29&enddt=2022-01-12T20%3A00%3A00%2B00%3A00&location=New%20Earth&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-01-12T18%3A00%3A00%2B00%3A00&subject=Welcome%20to%20the%20Motorway
	const openMicrosoftCalendarEvent = (
		meeting: Meeting,
		start?: Date,
		end?: Date
	) => {
		const subject = getInviteSubject();
		const body = getInviteBody(meeting);
		const location = meeting.url;
		// const start = ''; // 20220112T180000Z
		// const end = ''; // 20220112T200000Z
		// const inviteUrl = `https://outlook.office.com/calendar/0/deeplink/compose?body=${body}&enddt=${end}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${start}&subject=${subject}`;
		let inviteUrl = `https://outlook.office.com/calendar/0/deeplink/compose?location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=${subject}&body=${body}`;
		inviteUrl = replaceAll(inviteUrl, '\n', '<br />');

		if (start && end) {
			// YYYY-MM-DDTHH:mm:SSZ
			inviteUrl = `${inviteUrl}&startdt=${start.toISOString()}`;
			inviteUrl = `${inviteUrl}&enddt=${end.toISOString()}`;
		}

		window.open(inviteUrl);
	};

	return {
		selectedMeetingId,
		selectedMeeting: history.find((x) => x.id === selectedMeetingId),
		history,
		personalMeetingRoom,
		lobbyEnabled,
		enableLobby,
		selectMeeting,
		formatDate,
		formatPin,
		copyInviteLink,
		copyMeetingInvite,
		openGoogleCalendarEvent,
		openMicrosoftCalendarEvent,
		toggleMeetingFavorite,
		addMeeting,
		refreshMeetings,
		loadMoreMeetings,
	};
};
