export class Storage {
	static async get<T>(key: string, defaultValue: T) {
		if (window.localStorage) {
			try {
				const value = window.localStorage.getItem(key);
				if (value !== null) {
					return JSON.parse(value) as T;
				}
				return defaultValue;
			} catch (e) {
				throw e;
			}
		} else {
			return defaultValue;
		}
	}

	static async set(key: string, obj: object) {
		if (window.localStorage) {
			try {
				const jsonValue = JSON.stringify(obj);
				window.localStorage.setItem(key, jsonValue);
			} catch (e) {
				throw e;
			}
		}
	}

	static async remove(key: string) {
		if (window.localStorage) {
			try {
				window.localStorage.removeItem(key);
			} catch (e) {
				throw e;
			}
		}
	}
}
