import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

// Icons
import Icon from '@mdi/react';
import {
	mdiStar,
	mdiStarOutline,
	mdiCheckboxBlankOutline,
	mdiCheckboxMarkedOutline,
	mdiAccountGroupOutline,
} from '@mdi/js';

// Lib
import { Meeting } from '../../lib/types';
import { formatDate } from '../../lib/utils';
import { Avatar } from '@mui/material';
import { MeetingHistory } from '../../lib/common';

const cleanMeetingRoomName = (value: string) => {
	let room = value;

	try {
		if (value.includes('http:')) {
			const uri = new URL(value);
			room = uri.pathname.replaceAll('/', '');
		}
		if (value.includes('&')) {
			const s = value.split('&');
			if (s && s.length > 0) {
				room = s[0];
			}
		}
	} catch (error) {
		return room;
	}
	return room;
};

export type MeetingListItemProps = {
	meeting: MeetingHistory;
	showCheckbox?: boolean;
	selected: boolean;
	onClick: (meeting: Meeting) => void;
	onFavoriteClick: (meeting: Meeting) => void;
};

export const MeetingListItem = (props: MeetingListItemProps) => {
	const {
		meeting,
		showCheckbox = false,
		selected,
		onClick,
		onFavoriteClick,
	} = props;
	const theme = useTheme();

	return (
		<ListItem
			alignItems="center"
			button
			onClick={() => onClick(meeting)}
			selected={selected}
		>
			{showCheckbox && (
				<ListItemIcon style={{ minWidth: '34px' }}>
					<Icon
						path={
							selected
								? mdiCheckboxMarkedOutline
								: mdiCheckboxBlankOutline
						}
						size={1}
					/>
				</ListItemIcon>
			)}
			{!showCheckbox && !!meeting.avatar && (
				<ListItemIcon style={{ minWidth: '38px' }}>
					<Avatar
						alt=""
						sx={{ width: 28, height: 28 }}
						src={meeting.avatar}
					/>
				</ListItemIcon>
			)}
			{!showCheckbox && !meeting.avatar && (
				<ListItemIcon style={{ minWidth: '38px' }}>
					<Icon
						path={mdiAccountGroupOutline}
						size={1.25}
						color="#000000"
					/>
				</ListItemIcon>
			)}
			<ListItemText
				primary={
					meeting.topic ||
					cleanMeetingRoomName(meeting.room?.toLowerCase()) ||
					''
				}
				secondary={formatDate(new Date(meeting.dt))}
			/>
			{!showCheckbox && (
				<ListItemSecondaryAction>
					<IconButton onClick={() => onFavoriteClick(meeting)}>
						<Icon
							path={meeting.favorite ? mdiStar : mdiStarOutline}
							size={1}
							color={
								meeting.favorite === true
									? theme.palette.secondary.main
									: 'unset'
							}
						/>
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};
