import * as React from 'react';
import { orderBy } from 'lodash';
import { useTheme } from '@mui/material';

// Components
import { AgendaListItem } from './AgendaListItem';

// Lib
import { useAppDispatch, useAppSelector } from '../../store';
import {
	selectSelectedCalendarDate,
	setSelectedEvent,
} from '../../store/calendar';
import { DateTime } from '../../lib/DateTime';
import { Colors } from '../../lib/Colors';
import useEvents from './useEvents';
import { useAppTheme } from '../../contexts/ThemeContext';

type Props = {
	show: boolean;
	onNewEvent: () => void;
};

export const AgendaListView = ({ show, onNewEvent }: Props) => {
	const [scrollId, setScrollId] = React.useState<string | null>(null);
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { isDarkMode } = useAppTheme();
	const selectedDate = useAppSelector(selectSelectedCalendarDate);
	const events = useEvents(selectedDate);

	const handleSelectEvent = (id: string) => {
		dispatch(setSelectedEvent(id));
	};

	React.useEffect(() => {
		const t = setTimeout(() => {
			let id: string | null = null;
			let inRangeId: string | null = null;
			const now = new Date();
			orderBy(events, ['startTime'], ['desc']).forEach((x) => {
				const status = DateTime.isInRange(
					now,
					new Date(x.startTime),
					new Date(x.endTime)
				);
				if (status === 'inrange') {
					inRangeId = x.id;
				}
				if (status === 'after') {
					id = x.id;
				}
			});
			setScrollId(`${inRangeId || id}`);
		});
		return () => clearTimeout(t);
	}, [events]);

	if (!show) {
		return null;
	}

	return (
		<div
			data-component="list-view"
			style={{
				flex: 1,
				backgroundColor: isDarkMode ? Colors.black : Colors.white,
				position: 'relative',
				height: '100%',
			}}
		>
			<div style={{ paddingTop: 12 }} />
			{events.map((item) => (
				<AgendaListItem
					key={`${item.provider}_${item.id}`}
					item={item}
					onSelectEvent={() => handleSelectEvent(item.id)}
					mode="card"
					scroll={item.id === scrollId}
					selectedDate={selectedDate}
				/>
			))}
			{events.length === 0 && (
				<div
					style={{
						marginLeft: 12,
						marginRight: 12,
						marginTop: 12,
						marginBottom: 14,
					}}
				>
					<div
						onClick={onNewEvent}
						onKeyDown={onNewEvent}
						role="button"
						tabIndex={0}
						style={{ cursor: 'pointer' }}
					>
						<div
							style={{
								textAlign: 'center',
								alignItems: 'center',
								flexGrow: 1,
								padding: theme.spacing(1, 1, 1, 3),
							}}
						>
							<p>
								No events for{' '}
								{new DateTime(
									selectedDate
								).toFormattedShortDateMonthFirst()}
							</p>
						</div>
					</div>
				</div>
			)}
			{!!events.length && <div style={{ paddingBottom: 100 }} />}
		</div>
	);
};
