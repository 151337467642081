import React from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

// Components
import { Toolbar } from './Toolbar';
import { useUserProfileContext } from '../../contexts/UserProfileContext';
import {
	selectIsCalendarReady,
	selectIsMeetingsReady,
	useAppSelector,
} from '../../store';
import { Loading } from '../common/Loading';
import { Communication } from '../Communication';

export interface LayoutProps {
	children: React.ReactNode;
}

export const Layout = (props: LayoutProps) => {
	const { children } = props;
	const { isReady } = useUserProfileContext();
	const location = useLocation();
	const isMeetingsReady = useAppSelector(selectIsMeetingsReady);
	const isCalendarReady = useAppSelector(selectIsCalendarReady);

	if (!isReady || !isMeetingsReady || !isCalendarReady) {
		return (
			<>
				<Toolbar />
				<Box
					sx={{
						position: 'absolute',
						top: '48px',
						left: 0,
						right: 0,
						bottom: 0,
						height: `calc(100vh - 48px)`,
						overflow: 'hidden',
					}}
				>
					<Loading />
				</Box>
			</>
		);
	}

	return (
		<>
			<Toolbar />
			<Box
				sx={{
					position: 'absolute',
					top: '48px',
					left: 0,
					right: 0,
					bottom: 0,
					height: `calc(100vh - 48px)`,
					overflow: 'hidden',
				}}
			>
				<Communication show={location.pathname === '/communication'} />
				{children}
			</Box>
		</>
	);
};
