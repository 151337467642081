export interface AuthUser {
	sub: string;
	username?: string;
	groups?: string[];
}

export interface User {
	authId: string;
}

export type UserDevicePlatform = 'web' | 'ios' | 'android' | 'windows' | 'mac';
export type UserDeviceArchitecture =
	| 'arm'
	| 'arm64'
	| 'ia32'
	| 'x64'
	| 'unknown';
export type UserDeviceBuild = 'exe' | 'msi' | 'dmg' | 'unknown';
export type UserDeviceChannel = 'alpha' | 'beta' | 'production';

export interface UserDevice {
	id: number;
	auth_id: string;
	version: string;
	platform: UserDevicePlatform;
	channel: UserDeviceChannel;
	arch: UserDeviceArchitecture;
	build?: UserDeviceBuild;
	ua?: string;
	data?: any;
	ts: string;
}

export interface UserConfiguration {
	userId: number;
	authId: string;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	ucpUri: string;
	videoServer: string;
	ucpAdminUri: string;
	imagePath: string | null;
	tenant: string;
	ext: number;
	did: string;
	room: string;
	pin: string;
}

// Jitsi
export interface InitializationProps {
	server: string;
	room: string;
	email: string;
	name: string;
	lobbyEnabled: boolean;
}

export interface JitsiContextData {
	initializeConference: (options: InitializationProps) => void;
	leaveConference: () => void;
	elementRef: any | undefined;
	// elementRef:
	//   | React.MutableRefObject<HTMLDivElement | undefined | null>
	//   | undefined;
}

export interface JitsiConfig {
	server: string;
	room: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	element: any;
	displayName: string;
	displayEmail: string;
}

export interface JitsiConfigOptions {
	apiLogLevels?: string[];
}

export interface JitsiInterfaceConfigOptions {
	SHOW_BRAND_WATERMARK: boolean;
	SHOW_WATERMARK_FOR_GUESTS: boolean;
	SHOW_JITSI_WATERMARK: boolean;
	BRAND_WATERMARK_LINK: string;
	DEFAULT_WELCOME_PAGE_LOGO_URL: string;
	JITSI_WATERMARK_LINK: string;
	VERTICAL_FILMSTRIP: boolean;
}

export interface JitsiMeetAPIOptions {
	roomName?: string;
	width?: number | string;
	height?: number | string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	parentNode?: any;
	configOverwrite?: JitsiConfigOptions;
	interfaceConfigOverwrite?: JitsiInterfaceConfigOptions;
	noSSL?: boolean;
	jwt?: string;
	onload?: () => void;
	// eslint-disable-next-line @typescript-eslint/ban-types
	invites?: {};
	devices?: {
		audioInput?: string;
		audioOutput?: string;
		videoInput?: string;
	};
	userInfo?: {
		email?: string;
		displayName?: string;
	};
}

export type JitsiMeetAPI = {
	new (domain: string, options: JitsiMeetAPIOptions): JitsiMeetAPI;
	dispose: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addEventListener: (name: string, callback: (args?: any) => void) => void;
	removeListener: (name: string, callback: (args?: any) => void) => void;
	executeCommand: (name: string, value?: unknown) => void;
	getIFrame: () => HTMLIFrameElement;
};

export type DownloadData = {
	id: string;
	status: string;
	name: string;
	location: string;
	total: number;
	received: number;
	percent: number;
	downloadedAt: string;
};

export const StorageKeys = {
	AwsAuth: 'accent-auth-aws',
	MicrosoftAuth: 'accent-auth-microsoft',
	GoogleAuth: 'accent-auth-google',
	LinkedInAuth: 'accent-auth-linkedin',
	DeviceConfig: 'accent-config-device',
	AppConfig: 'accent-config-appcached',
};

export const AppEvents = {
	ProtocolData: 'app-protocol-data',
	UpdateAvailable: 'app-update-available',
	OpenInsertGif: 'accent-open-insertgif',
	OpenCommunications: 'accent-open-communications',
	OpenMeetings: 'accent-open-meetings',
	OpenCalendar: 'accent-open-calendar',
	OpenFiles: 'accent-open-files',
	ReportDownloadProgress: 'accent-report-download-progress',
	OpenExternalUrl: 'accent-open-externalurl',
	SetConnectStatus: 'accent-connect-status',
	GoogleSignInSuccess: 'accent-google-signin-success',
	GoogleSignInFailed: 'accent-google-signin-failed',
	GoogleEventsRefreshed: 'accent-google-events-refresh',
	GoogleSignedOut: 'accent-google-signout',
	MicrosoftSignInSuccess: 'accent-microsoft-signin-success',
	MicrosoftSignInFailed: 'accent-microsoft-signin-failed',
	MicrosoftEventsRefreshed: 'accent-microsoft-events-refresh',
	MicrosoftSignedOut: 'accent-microsoft-signout',
	LinkedInSignInSuccess: 'accent-linkedin-signin-success',
	LinkedInSignInFailed: 'accent-linkedin-signin-failed',
	LinkedInSignedOut: 'accent-linkedin-signout',
};

export const ConnectEvents = {
	PasteIntoConnect: 'connect-paste',
	ResizeConnect: 'connect-resize',
	ShowConnect: 'connect-show',
	HideConnect: 'connect-hide',
	ReloadConnectServer: 'connect-reload',
	LoginToConnect: 'connect-login',
	LogoutOfConnect: 'connect-logout',
	ReConnect: 'connect-reconnect',
};

export const ElectronEvents = {
	InstallUpdate: 'app-install-update',
	Quit: 'app-quit',
	SaveState: 'app-state-set',
	DeviceReady: 'app-ready',
	UserConfigLoaded: 'app-userconfig-loaded',
	CopyToClipboard: 'accent-clipboard-copy',
	OpenUrl: 'accent-open-url',
	OpenFile: 'accent-open-file',
	OpenFolder: 'accent-open-folder',
	HardResetApp: 'app-hardreset',
	RefreshApp: 'app-refresh',
	SignInToGoogle: 'electron-google-signin',
	SignOutOfGoogle: 'electron-google-signout',
	SignInToMicrosoft: 'electron-microsoft-signin',
	SignOutOfMicrosoft: 'electron-microsoft-signout',
	SignInToLinkedIn: 'electron-linkedin-signin',
	SignOutOfLinkedIn: 'electron-linkedin-signout',
	RefreshGoogleEvents: 'electron-google-refresh',
	RefreshMicrosoftEvents: 'electron-microsoft-refresh',
} as const;

export type SelectedEvent = {
	eventId: string;
	provider: string;
};

export interface ConferenceProvider {
	conferenceName: string;
	image: string | null;
	conferenceUrl: string | null;
	urls: string[];
}

export interface CalendarClass {
	connect: () => Promise<boolean>;
	disconnect: () => Promise<void>;
	getTodaysEvents: () => Promise<CalendarEvent[]>;
}

export interface CalendarEvent extends ConferenceProvider {
	id: string;
	name?: string | null;
	description?: string | null;
	location?: string | null;
	start?: string | null;
	startTime: number;
	end?: string | null;
	endTime: number;
	calendarUrl?: string | null;
	isAllDay: boolean;
	participants: EventParticipant[];
	provider: string;
}

export type EventParticipant = {
	id: string;
	name?: string | null;
	email?: string | null;
	status?: string | null;
};

export type ConnectUser = {
	username: string;
	password: string;
	serverUrl: string;
};

export type ConnectStatus =
	| 'idle'
	| 'busy'
	| 'loggedin'
	| 'loggedout'
	| 'error';

export type ResizeData = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export type ConfigSetting = {
	key: string;
	value: string;
};

export type AppAssetKeys = 'loginBackgroundImage' | 'loginLogoImage';
export type AppEmailInviteKeys = 'subject' | 'body';
export type AppExpressionKeys = 'url' | 'urlNoProtocol' | 'email';
export type AppSetting<T extends string> = Record<T, string>;

export type IntegrationProviderSetting = {
	name: string;
	signinImage: string;
	logoImage: string;
};

export type ConferenceProviderSetting = {
	name: string;
	image: string;
	matchers: string[];
};

export interface AppStateConfig {
	copyright: string;
	cloudPortalUrl: string;
	aboutUsLinks: ConfigSetting[];
	helpMenuLinks: ConfigSetting[];
	assets: AppSetting<AppAssetKeys>;
	conferenceProviders: ConferenceProviderSetting[];
	integrationProviders: IntegrationProviderSetting[];
	invite: AppSetting<AppEmailInviteKeys>;
	expressions: AppSetting<AppExpressionKeys>;
	meetingServers: ConfigSetting[];
}

export interface SplitWidth {
	meetings: number;
	calendar: number;
}

export interface DeviceStateConfig {
	deviceId: string;
	platform: string;
	version: string;
	lastUsername: string;
	downloadHistory: DownloadData[];
	widths: SplitWidth;
	toc: boolean;
	scheduleMeetingButtonIndex: number;
	useLobby: boolean;
}

export interface UserConfiguration {
	userId: number;
	authId: string;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	ucpUri: string;
	videoServer: string;
	ucpAdminUri: string;
	imagePath: string | null;
	tenant: string;
	ext: number;
	did: string;
	room: string;
	pin: string;
}

export type DataHandler = (data: unknown) => void;

export type ElectronContextBridge = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	jitsiMeetElectronUtils: any;
	on: (channel: string, func: DataHandler) => void;
	send: (channel: string, data?: unknown) => void;
	save: <T>(storageKey: string, data: T) => void;
	get: <T>(storageKey: string, defaultData: T) => T;

	LoginToConnect: (user: string, pass: string, server: string) => void;
	LogoutOfConnect: () => void;
};

export type MeetingType = 'Personal' | 'Channel' | 'Room' | 'Public';

export interface Meeting {
	id: number;
	url: string;
	room: string;
	dt: string;
	pin: string;
	favorite: boolean;
	topic: string;
	type: MeetingType;
	server?: string;
}

export interface MeetingHistory extends Meeting {
	tenant?: string;
	ext?: string;
	first_name?: string;
	last_name?: string;
	email?: string;
	did?: string;
	avatar?: string;
}

export interface UCPProvider {
	server: string;
	user: string;
	pass: string;
}

export interface ConferenceInfo {
	meeting: Meeting;
	token: string | null;
	user: UserConfiguration;
}

export interface JoinConferenceRequest {
	authId: string;
	server: string;
	room: string;
	flags: JitsiFeatureFlags;
	subject?: string;
}

// https://developer.8x8.com/jaas/docs/api-keys-jwt
export interface JitsiFeatureFlags {
	livestreaming: string;
	recording: string;
	transcription: string;
	'sip-inbound-call': string;
	'sip-outbound-call': string;
	'inbound-call': string;
	'outbound-call': string;
}

export const defaultJitsiFeatureFlags: JitsiFeatureFlags = {
	livestreaming: 'false',
	recording: 'false',
	transcription: 'false',
	'sip-inbound-call': 'false',
	'sip-outbound-call': 'false',
	'inbound-call': 'false',
	'outbound-call': 'false',
};

// https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/flags/constants.ts
// Updated 2022-10-06
export interface JitsiMobileFeatureFlags {
	'add-people.enabled'?: boolean;
	'audio-focus.disabled'?: boolean;
	'audio-mute.enabled'?: boolean;
	'audio-only.enabled'?: boolean;
	'calendar.enabled'?: boolean;
	'call-integration.enabled'?: boolean;
	'car-mode.enabled'?: boolean;
	'close-captions.enabled'?: boolean;
	'conference-timer.enabled'?: boolean;
	'chat.enabled'?: boolean;
	'filmstrip.enabled'?: boolean;
	'fullscreen.enabled'?: boolean;
	'help.enabled'?: boolean;
	'invite.enabled'?: boolean;
	'ios.recording.enabled'?: boolean;
	'ios.screensharing.enabled'?: boolean;
	'android.screensharing.enabled'?: boolean;
	'speakerstats.enabled'?: boolean;
	'kick-out.enabled'?: boolean;
	'live-streaming.enabled'?: boolean;
	'lobby-mode.enabled'?: boolean;
	'meeting-name.enabled'?: boolean;
	'meeting-password.enabled'?: boolean;
	'notifications.enabled'?: boolean;
	'overflow-menu.enabled'?: boolean;
	'pip.enabled'?: boolean;
	'prejoinpage.enabled'?: boolean;
	'raise-hand.enabled'?: boolean;
	'reactions.enabled'?: boolean;
	'recording.enabled'?: boolean;
	'replace.participant'?: boolean;
	resolution?: boolean;
	'security-options.enabled'?: boolean;
	'server-url-change.enabled'?: boolean;
	'tile-view.enabled'?: boolean;
	'toolbox.alwaysVisible'?: boolean;
	'toolbox.enabled'?: boolean;
	'video-mute.enabled'?: boolean;
	'video-share.enabled'?: boolean;
	'welcomepage.enabled'?: boolean;
}

// Updated 2022-10-06
// https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/flags/constants.ts
export const AllJitsiMobileFeatureFlags = [
	{
		flag: 'add-people.enabled',
		description:
			'Flag indicating if add-people functionality should be enabled. Default: enabled (true).',
	},
	{
		flag: 'audio-focus.disabled',
		description:
			'Flag indicating if the SDK should not require the audio focus. Used by apps that do not use Jitsi audio.Default: disabled (false).',
	},
	{
		flag: 'audio-mute.enabled',
		description:
			'Flag indicating if the audio mute button should be displayed.Default: enabled (true).',
	},
	{
		flag: 'audio-only.enabled',
		description:
			'Flag indicating that the Audio only button in the overflow menu is enabled.Default: enabled (true).',
	},
	{
		flag: 'calendar.enabled',
		description:
			'Flag indicating if calendar integration should be enabled.Default: enabled (true) on Android, auto-detected on iOS.',
	},
	{
		flag: 'call-integration.enabled',
		description:
			'Flag indicating if call integration (CallKit on iOS, ConnectionService on Android)should be enabled.Default: enabled (true).',
	},
	{
		flag: 'car-mode.enabled',
		description:
			'Flag indicating if car mode should be enabled.Default: enabled (true).',
	},
	{
		flag: 'close-captions.enabled',
		description:
			'Flag indicating if close captions should be enabled.Default: enabled (true).',
	},
	{
		flag: 'conference-timer.enabled',
		description:
			'Flag indicating if conference timer should be enabled.Default: enabled (true).',
	},
	{
		flag: 'chat.enabled',
		description:
			'Flag indicating if chat should be enabled.Default: enabled (true).',
	},
	{
		flag: 'filmstrip.enabled',
		description:
			'Flag indicating if the filmstrip should be enabled.Default: enabled (true).',
	},
	{
		flag: 'fullscreen.enabled',
		description:
			'Flag indicating if fullscreen (immersive) mode should be enabled.Default: enabled (true).',
	},
	{
		flag: 'help.enabled',
		description:
			'Flag indicating if the Help button should be enabled.Default: enabled (true).',
	},
	{
		flag: 'invite.enabled',
		description:
			'Flag indicating if invite functionality should be enabled.Default: enabled (true).',
	},
	{
		flag: 'ios.recording.enabled',
		description:
			'Flag indicating if recording should be enabled in iOS.Default: disabled (false).',
	},
	{
		flag: 'ios.screensharing.enabled',
		description:
			'Flag indicating if screen sharing should be enabled in iOS.Default: disabled (false).',
	},
	{
		flag: 'android.screensharing.enabled',
		description:
			'Flag indicating if screen sharing should be enabled in android.Default: enabled (true).',
	},
	{
		flag: 'speakerstats.enabled',
		description:
			'Flag indicating if speaker statistics should be enabled.Default: enabled (true).',
	},
	{
		flag: 'kick-out.enabled',
		description:
			'Flag indicating if kickout is enabled.Default: enabled (true).',
	},
	{
		flag: 'live-streaming.enabled',
		description:
			'Flag indicating if live-streaming should be enabled.Default: auto-detected.',
	},
	{
		flag: 'lobby-mode.enabled',
		description:
			'Flag indicating if lobby mode button should be enabled.Default: enabled.',
	},
	{
		flag: 'meeting-name.enabled',
		description:
			'Flag indicating if displaying the meeting name should be enabled.Default: enabled (true).',
	},
	{
		flag: 'meeting-password.enabled',
		description:
			'Flag indicating if the meeting password button should be enabled.Note that this flag just decides on the button, if a meeting has a passwordset, the password dialog will still show up.Default: enabled (true).',
	},
	{
		flag: 'notifications.enabled',
		description:
			'Flag indicating if the notifications should be enabled.Default: enabled (true).',
	},
	{
		flag: 'overflow-menu.enabled',
		description:
			'Flag indicating if the audio overflow menu button should be displayed.Default: enabled (true).',
	},
	{
		flag: 'pip.enabled',
		description:
			'Flag indicating if Picture-in-Picture should be enabled.Default: auto-detected.',
	},
	{
		flag: 'prejoinpage.enabled',
		description:
			'Flag indicating if the prejoin page should be enabled.Default: enabled (true).',
	},
	{
		flag: 'raise-hand.enabled',
		description:
			'Flag indicating if raise hand feature should be enabled.Default: enabled.',
	},
	{
		flag: 'reactions.enabled',
		description:
			'Flag indicating if the reactions feature should be enabled.Default: enabled (true).',
	},
	{
		flag: 'recording.enabled',
		description:
			'Flag indicating if recording should be enabled.Default: auto-detected.',
	},
	{
		flag: 'replace.participant',
		description:
			'Flag indicating if the user should join the conference with the replaceParticipant functionality.Default: (false).',
	},
	{
		flag: 'resolution',
		description:
			'Flag indicating the local and (maximum) remote video resolution. Overridesthe server configuration.Default: (unset).',
	},
	{
		flag: 'security-options.enabled',
		description:
			'Flag indicating if the security options button should be enabled.Default: enabled (true).',
	},
	{
		flag: 'server-url-change.enabled',
		description:
			'Flag indicating if server URL change is enabled.Default: enabled (true).',
	},
	{
		flag: 'tile-view.enabled',
		description:
			'Flag indicating if tile view feature should be enabled.Default: enabled.',
	},
	{
		flag: 'toolbox.alwaysVisible',
		description:
			'Flag indicating if the toolbox should be always be visibleDefault: disabled (false).',
	},
	{
		flag: 'toolbox.enabled',
		description:
			'Flag indicating if the toolbox should be enabledDefault: enabled.',
	},
	{
		flag: 'video-mute.enabled',
		description:
			'Flag indicating if the video mute button should be displayed.Default: enabled (true).',
	},
	{
		flag: 'video-share.enabled',
		description:
			'Flag indicating if the video share button should be enabledDefault: enabled (true).',
	},
	{
		flag: 'welcomepage.enabled',
		description:
			'Flag indicating if the welcome page should be enabled.Default: disabled (false).',
	},
];

// https://jitsi.github.io/handbook/docs/user-guide/user-guide-advanced/
// Updated 2022-10-06
export interface JitsiConfigUrlOptions {
	'config.disableInviteFunctions': boolean;
	'config.minParticipants': number;
	'config.prejoinPageEnabled': boolean;
	'config.defaultLanguage': string;
	'config.disableThirdPartyRequests': boolean;
	'config.enableDisplayNameInStats': boolean;
	'config.enableEmailInStats': boolean;
	'config.enableInsecureRoomNameWarning': boolean;
	'config.desktopSharingFrameRate.min': number;
	'config.desktopSharingFrameRate.max': number;
	'config.startWithVideoMuted': boolean;
	'config.disableAudioLevels': boolean;
	'config.disableRemoteMute': boolean;
	'config.startWithAudioMuted': boolean;
	'config.startSilent': boolean;
}

export const AllJitsiConfigUrlOptions = [
	{
		flag: 'config.disableInviteFunctions',
		description: 'disable invite function of the app',
		value: true,
	},
	{
		flag: 'config.minParticipants',
		description:
			'override the minimum number of participants before starting a call',
		value: 2,
	},
	{
		flag: 'config.prejoinPageEnabled',
		description:
			'show an intermediate page before joining to allow for adjustment of devices',
		value: true,
	},
	{
		flag: 'config.defaultLanguage',
		description: 'change the UI default language',
		value: 'en',
	},
	{
		flag: 'config.disableThirdPartyRequests',
		description:
			'generate avatars locally and disable callstats integration',
		value: true,
	},
	{
		flag: 'config.enableDisplayNameInStats',
		description: 'send display names of participants to callstats',
		value: true,
	},
	{
		flag: 'config.enableEmailInStats',
		description:
			'send email (if available) to callstats and other analytics',
		value: true,
	},
	{
		flag: 'config.enableInsecureRoomNameWarning',
		description: 'show a warning label if the room name is deemed insecure',
		value: true,
	},
	{
		flag: 'config.desktopSharingFrameRate.min',
		description: 'override the minimum framerate for desktop sharing',
		value: 5,
	},
	{
		flag: 'config.desktopSharingFrameRate.max',
		description: 'override the maximum framerate for desktop sharing',
		value: 5,
	},
	{
		flag: 'config.startWithVideoMuted',
		description: 'disable video when joining',
		value: true,
	},
	{
		flag: 'config.disableAudioLevels',
		description:
			'disable audio statistics polling (thereby perhaps improving performance)',
		value: true,
	},
	{
		flag: 'config.disableRemoteMute',
		description: 'disable all muting operations of remote participants',
		value: true,
	},
	{
		flag: 'config.startWithAudioMuted',
		description: 'turn off audio input when joining',
		value: true,
	},
	{
		flag: 'config.startSilent',
		description: 'mute audio input and output',
		value: true,
	},
];

export interface TokenPayload {
	context: {
		user: {
			id: string;
			avatar: string;
			name: string;
			email: string;
		};
		group?: string;
		features?: JitsiFeatureFlags;
	};
	features?: JitsiFeatureFlags;
	moderator: boolean | string;
	aud: string;
	iss: string;
	sub: string;
	room: string;
	exp: number;
	nbf: number;
}

declare global {
	interface Window {
		JitsiMeetExternalAPI: JitsiMeetAPI | undefined;
	}
}