import { Button } from '@mui/material';
import * as React from 'react';
import UAParser from 'ua-parser-js';

const parser = new UAParser();
const uaParserResult = parser.getResult();

const Text = ({ children }: { children: React.ReactNode }) => {
    return (
        <div style={{
            position: 'relative',
            color: '#fff',
            fontWeight: 600,
            lineHeight: '26px',
            margin: 'auto',
            textAlign: 'center',
            paddingLeft: '50px',
            paddingRight: '50px',
        }}>
            {children}
        </div>
    )
}

export default function AccentCloudPhone() {
    const isApple = uaParserResult.browser.name?.toLowerCase().includes('safari') ||
        uaParserResult.os.name?.toLowerCase().includes('ios');

    // const [showDownload, setShowDownload] = React.useState(false);
    // const [url, setUrl] = React.useState('about:blank');

    const handleOpen = (url: string) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
    }

    return (
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: '100vw',
                height: '100vh',
                background: 'url(bgoverlay.png) no-repeat center center scroll',
                backgroundSize: 'cover',
                backgroundBlendMode: 'overlay',
                backgroundColor: '#333333',
            }}
        >
            <div
                style={{
                    marginTop: '100px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    position: 'relative',
                    height: '100px',
                    backgroundImage: 'url(watermark.png)',
                    width: '280px',
                    maxWidth: '280px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            >
                &nbsp;
            </div>
            <Text>
                Video Conferencing and online meetings on any device.
            </Text>
            {true && (
                <div
                    style={{
                        position: 'relative',
                        margin: 'auto',
                        textAlign: 'center',
                        paddingTop: '30px',
                    }}
                >
                    <Button variant="contained" size="large" onClick={() => handleOpen('accentvoice://open')}>
                        Launch Accent Meetings
                    </Button>
                </div>
            )}
            <div style={{ paddingTop: '50px' }}>
                <Text>OR</Text>
            </div>
            {true && (
                <section
                    style={{
                        paddingTop: '30px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {isApple && (
                        <a href="https://apps.apple.com/us/app/voiceone-meetings/id1530940619" target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
                            <img
                                src="app-store.png"
                                alt="Download for iOS"
                                style={{ maxWidth: '200px' }}
                            />
                        </a>
                    )}
                    {!isApple && (
                        <a href="https://play.google.com/store/apps/details?id=com.accentvoice.meetings" target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
                            <img
                                src="play-store.png"
                                alt="Download for Android"
                                style={{ maxWidth: '200px' }}
                            />
                        </a>
                    )}
                </section>
            )}
        </div>
    );
}