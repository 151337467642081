import { useState, useEffect } from 'react';
import { DateTime } from '../../lib/DateTime';
import { CalendarEvent } from '../../lib/types';
import { useAppSelector } from '../../store';
import { selectAllCalendarEvents } from '../../store/calendar';

const getSelectedAllDayEvents = (
	date: string,
	events: CalendarEvent[]
): CalendarEvent[] => {
	const start = new DateTime(date).toStartOfDay().toDate().getTime();
	const end = new DateTime(date).toEndOfDay().toDate().getTime();
	return events.filter(
		(x) =>
			// Actual All Day Event
			(x.startTime === start && x.endTime === end) ||
			// Multi-day
			(x.startTime < start && x.endTime > end) ||
			// Start of multi-day
			(x.startTime === start && x.endTime > end) ||
			// End of multi-day
			(x.startTime < start && x.endTime === end)
	);
};

export default function useAllDayEvents(selectedDate: string) {
	const [events, setEvents] = useState<CalendarEvent[]>([]);
	const allEvents = useAppSelector(selectAllCalendarEvents);

	useEffect(() => {
		const data = getSelectedAllDayEvents(selectedDate, allEvents);
		setEvents(data);
	}, [selectedDate, allEvents]);

	return events;
}
